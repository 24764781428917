@import 'OK/styles/util';

@include forLightTheme() {
  .toggle {
    background-color: $brandColor;

    &.checked {
      background-color: $navigationColorFocus;
    }
  }
}

@include forDarkTheme() {
  .toggle {
    background-color: $brandColor;

    &.checked {
      background-color: $navigationColorFocusDark;
    }
  }
}
