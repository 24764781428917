@import 'OK/styles/util';

.cancelButton {
  margin: 0;
}

.labelSearchSuggestionSubtitle {
  @include fontMono();
}

.labelSearchSuggestions {
  left: 0;
  position: absolute;
  top: $inputHeight - $contentMarginXs;
  width: 100%;
}

.makeLabelsButton {
  margin-bottom: $contentMarginSm;
}

.newOKIDsStepper {
  margin-bottom: $contentMarginMd;
}

.noticeTextButton {
  height: $lineHeightSm;
  line-height: $lineHeightSm;
  margin: 0;
}

.OKIDNotice {
  font-size: $fontSizeSectionHeader;
  font-weight: bold;
  line-height: $lineHeightSectionHeader;
}

.rerunLabelCard {
  margin: 0 0 $contentMarginMd (-$contentMarginMd);
}

.searchContainer {
  position: relative;
  z-index: 1;
}

.searchLabelsInput {
  margin-bottom: $contentMarginMd;
  z-index: 1;
}

.sourceTypeButtons {
  margin-bottom: $contentMarginMd;
  width: 100%;
}
