@import 'OK/styles/util';

.activeOrganisationMessage {
  margin-bottom: $contentMarginSm;
}

.activeTag {
  @include fontDefault();

  border-radius: 0 0 $cardBorderRadius $cardBorderRadius;
  left: $contentMarginSm;
  padding: 4px $contentMarginSm;
  position: absolute;
  top: -4px;
  z-index: 1;
}

.card {
  &:global(.alert),
  &:global(.creation),
  &:global(.navigation),
  &:global(.notification) {
    .managerButton {
      color: $textColorLight !important;
    }
  }
}

.innerClassName {
  display: flex;
  flex-direction: column;
}

// Styles for all manager buttons
.managerButton {
  margin-bottom: $contentMarginSm;

  &:last-child {
    margin-bottom: 0;
  }
}

.managerButtonLeave {
  width: auto;
}

.managerOptions {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
}

.tag {
  @include fontDefault();
}
