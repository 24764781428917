@use "sass:math";
@use 'sass:color';
@import 'OK/styles/util';

$popupWidth: 335px;

.button {
  width: 100%;
}

.dateNotice {
  margin-bottom: $contentMarginLg;
  margin-left: -$contentMarginMd;
  margin-right: -$contentMarginMd;
  padding: 0 $contentMarginMd;

  :last-child {
    margin-bottom: 0;
  }
}

.durationTag {
  height: 30px;
  line-height: 30px !important;
  margin-left: $contentMarginXs;
  vertical-align: middle;

  @include fontDefault();
}

.editButton {
  font-weight: normal;
  margin: 0;
}

.editButton {
  font-weight: normal;
  margin: 0;
  cursor: none;
}

.requestRecordingError {
  margin-bottom: 15px;
  margin-top: -15px;
}

.errorMessage {
  margin: $contentMarginXs 0 15px;
}

.fineprint {
  margin: $contentMarginLg 0 0 0;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $contentMarginLg $contentMarginLg;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hostContainer {
  align-items: center;
  display: flex;
  margin-bottom: $contentMarginLg;

  &:last-child {
    margin-bottom: 0;
  }
}

.hostImage {
  height: 88px;
  margin-right: $contentMarginSm;
  width: 88px;
  border-radius: 44px;
}

.hostName {
  margin-bottom: $contentMarginSm;
}
.hostOrganisation {
  margin-bottom: 0;
}
.hostJobTitle {
  margin-bottom: 0;
}

.inlineLink {
  display: inline;
  margin: 0;
}

.input {
  margin-bottom: $contentMarginMd;
  width: 100%;
}

.inputLabel {
  margin-bottom: $contentMarginSm;
}

.inputMessage {
  margin: -15px 0 $contentMarginSm;
}

.mainContainer {
  padding: $contentMarginLg $contentMarginLg $contentMarginXl;
}

.marketingOptInCheckbox {
  margin-right: 15px;
  min-width: 32px;
}

.marketingOptInCheckboxContainer {
  display: flex;
  justify-content: space-between;
  margin-top: $contentMarginMd !important;
}

.marketingOptInContainer {
  margin-bottom: $contentMarginSm;
}

.marketingOptInLabel {
  margin-bottom: $contentMarginSm;
  margin-top: 4px;
}

.marketingOptInOptionalLabel {
  margin-bottom: 0;
}

.mediaContainer {
  background-position: center;
  background-size: cover;
  height: $popupWidth;
  position: relative;
}

.noWebinarFoundMessage {
  margin: $contentMarginLg 0;
  text-align: center;
}

.shareButton {
  margin: 0;
}

.shareButtonFloatingContainer {
  .shareToast {
    left: -(math.div(190px - 99px, 2));
    top: calc(0px - 40px - 53px - 10px);
  }
}

.shareButtonHeaderContainer {
  margin: 0 15px 0 auto;

  .shareToast {
    left: -(math.div(190px - 69px, 2));
    top: $contentMarginXs;
  }
}

.shareToast {
  position: absolute;
  text-align: center;
  width: 190px !important;
}

.titleContainer {
  align-items: center;
  display: flex;

  > * {
    margin-bottom: 0;
  }
}

.popup {
  overflow: hidden;
  padding: 0;
  width: $popupWidth;
}

.registerButton {
  margin-bottom: 0;
}

.registerContainer {
  padding: $contentMarginLg $contentMarginLg $contentMarginXl;
}

.registrationInput {
  margin-bottom: $contentMarginMd;

  input {
    font-weight: normal !important;
  }
}

.registrationLabel {
  margin-bottom: 0;
  margin-top: -$contentMarginSm;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.webinarLoadingSpinner {
  display: block;
  margin: $contentMarginLg auto;
}

@include forLightTheme() {
  .registerContainer {
    background-color: $contentBackgroundColorCard;
  }

  .headerContainer {
    background: linear-gradient(
      to bottom,
      color.change($contentBackgroundColorCard, $alpha: 0.9) 25%,
      transparent 50%,
      color.change($contentBackgroundColorCard, $alpha: 0.9) 75%
    );
  }
}

@include forDarkTheme() {
  .registerContainer {
    background-color: $contentBackgroundColorCardDark;
  }

  .headerContainer {
    background: linear-gradient(
      to bottom,
      color.change($contentBackgroundColorCardDark, $alpha: 0.9) 25%,
      transparent 50%,
      color.change($contentBackgroundColorCardDark, $alpha: 0.9) 75%
    );
  }
}
