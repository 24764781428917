@import 'OK/styles/util';

.backButton {
  margin: 0 auto 0 0;
}

.container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.contentContainer {
  min-height: 100%;
}

.contentInnerContainer {
  &.withMenu {
    padding-top: $mainMenuHeight;
  }
}

.currentProgress {
  border-radius: 2px 0 0 2px;
  height: 100%;
  max-width: 100px;
  min-width: 0;
  transition: $timingShort ease-in-out width;
}

.menuContainer {
  height: $mainMenuHeight;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

.menu {
  align-items: center !important;
  flex-direction: row !important;
  justify-content: flex-end;
  padding: 15px !important;

  > * {
    max-height: 40px;
  }
}

.progressBar {
  border-radius: 2px;
  height: 12px;
  width: 100px;
}

.appSettings {
  margin-left: $contentMarginLg;
}

@include forLightTheme() {
  .contentContainer {
    background-color: $contentBackgroundColorCard;
  }

  .currentProgress {
    background-color: $brandColor;
  }

  .menuContainer {
    background-color: $contentBackgroundColorFocus;
  }

  .progressBar {
    background-color: $contentBackgroundColor;
  }
}

@include forDarkTheme() {
  .contentContainer {
    background-color: $contentBackgroundColorCardDark;
  }

  .currentProgress {
    background-color: $brandColorAlternate;
  }

  .menuContainer {
    background-color: $contentBackgroundColorFocusDark;
  }

  .progressBar {
    background-color: $contentBackgroundColorDark;
  }
}
