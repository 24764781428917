@import 'OK/styles/util';

.icon {
  position: absolute;
  right: $inputPadding;
  top: $inputPadding;

  &.clearButton {
    height: $minClickableHeight;
    margin: 0;
    padding: 0;
    right: 0;
    top: 0;
    width: $minClickableHeight;
  }
}

.message {
  margin: $contentMarginXs 0 0;
}

.textarea {
  background: none;
  border: 0;
  line-height: inherit;
  margin: 0;
  min-height: 88px;
  outline: none;
  padding: $contentMarginXs $minClickableHeight $contentMarginXs $contentMarginSm;
  resize: none;
  width: 100%;
}

.textareaContainer {
  border-radius: $inputBorderRadius;
  border-style: solid;
  border-width: 1px;
  display: flex;
  height: 100%;
  padding: 1px;
  position: relative;

  &.focused {
    border-width: 2px;
    padding: 0;

    .icon {
      right: $inputPadding - 1px;
      top: $inputPadding - 1px;
    }

    .clearButton {
      right: -1px;
      top: -1px;
    }
  }

  &.readOnly {
    background-color: $midtonesColorStatic;
    border-color: transparent;
  }

  &.disabled {
    opacity: 0.5;
  }
}

@include forLightTheme() {
  .icon {
    color: $brandColor;
  }

  .textarea {
    color: $textColor;
  }

  .textareaContainer {
    background-color: $contentBackgroundColor;
    border-color: $inputBorderColorInactive;

    &.hasValue {
      border-color: $inputBorderColorActive;
    }

    &.focused {
      border-color: $inputBorderColorActive;
    }
  }
}

@include forDarkTheme() {
  .icon {
    color: $brandColorAlternate;
  }

  .textarea {
    color: $textColorLight;
  }

  .textareaContainer {
    background-color: $contentBackgroundColorDark;
    border-color: $inputBorderColorInactiveDark;

    &.hasValue {
      border-color: $inputBorderColorActiveDark;
    }

    &.focused {
      border-color: $inputBorderColorActiveDark;
    }
  }
}
