@import 'OK/styles/util';

$dateBorderRadius: 15px;
$dateHeight: 30px;
$datePaddingVertical: 5px;
$dateWidth: 2.45rem;
$rangeEndBorderRadius: 0 15px 15px 0;
$rangeStartBorderRadius: 15px 0 0 15px;

.hasDataIndicator {
  border-radius: 3px;
  bottom: -3px;
  display: block;
  height: 6px;
  left: calc(50% - 3px);
  position: absolute;
  width: 6px;
}

.legend {
  margin-top: $contentMarginLg;

  > p {
    margin-bottom: 0;
  }
}

.sampleDataIndicator {
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  height: 8px;
  width: 8px;
  margin-right: $contentMarginXs;
}

.popup {
  width: $mediaPopupMaxWidthMobile;

  :global {
    .react-datepicker {
      background: none;
      border: none;
      width: 100%;

      .react-datepicker__current-month {
        font-size: $fontSizeMd;
        font-weight: normal;
        line-height: $lineHeightMd;
        margin-bottom: $contentMarginMd;
        text-align: left;
        text-transform: uppercase;
      }

      .react-datepicker__day {
        flex-grow: 1;
        font-size: $fontSizeMd;
        line-height: $dateHeight;
        margin: $datePaddingVertical 0;
        position: relative;

        &:hover {
          &:not(.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range) {
            border-radius: $dateBorderRadius;
          }
        }
      }

      .react-datepicker__day--in-range {
        border-radius: 0;
      }

      .react-datepicker__day--in-selecting-range {
        border-radius: 0;

        &:hover {
          &:not(.react-datepicker__day--selecting-range-start) {
            border-radius: $rangeEndBorderRadius;
          }
        }
      }

      .react-datepicker__day--keyboard-selected {
        border-radius: $dateBorderRadius;
      }

      .react-datepicker__day-name {
        font-size: $fontSizeSm;
        line-height: $lineHeightSm;
        text-transform: uppercase;
        width: $dateWidth;

        &:local(.reducedFont) {
          font-size: $fontSizeXs;
          margin: 0;
        }
      }

      .react-datepicker__day-names {
        display: flex;
        justify-content: space-between;
        margin-bottom: $contentMarginSm;
      }

      .react-datepicker__day--range-end {
        border-radius: $rangeEndBorderRadius;
      }

      .react-datepicker__day--range-start {
        position: relative;
        border-radius: $rangeStartBorderRadius;
      }

      .react-datepicker__day--range-start.react-datepicker__day--range-end {
        border-radius: $dateBorderRadius;
      }

      .react-datepicker__day--selecting-range-start {
        border-radius: $rangeStartBorderRadius;
      }

      .react-datepicker__header {
        background: none;
        border-bottom: 0;
        padding: 0;
      }

      .react-datepicker__month {
        margin: 0;
      }

      .react-datepicker__month-container {
        float: none;
      }

      .react-datepicker__navigation {
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        position: absolute;
        top: calc(50% - 16px);
      }

      .react-datepicker__navigation-icon::before {
        border-width: 2px 2px 0 0;
      }

      .react-datepicker__navigation-icon--previous:before {
        right: -6px;
      }

      .react-datepicker__navigation-icon--next:before {
        left: -6px;
      }

      .react-datepicker__navigation--next {
        right: -$contentMarginLg - 15px;
      }

      .react-datepicker__navigation--previous {
        left: -$contentMarginLg - 15px;
      }

      .react-datepicker__week {
        display: flex;
      }
    }
  }
}

@include forLightTheme() {
  $activeDateBackgroundColor: #E5F1FB;
  $dateTextColor: $brandColor;
  $dateTextColorInactive: #6D6D6D;
  $dayOfWeekColor: #767575;

  .hasDataIndicator, .sampleDataIndicator {
    background-color: #454646;
  }

  .popup {
    :global {
      .react-datepicker {
        .react-datepicker__day {
          color: $textColor;

          &:hover {
            &:not(.react-datepicker__day--in-range) {
              background-color: $midtonesColorStatic;
            }
          }
        }

        .react-datepicker__day--in-range {
          background-color: $activeDateBackgroundColor;
          color: $dateTextColor;
        }
      
        .react-datepicker__day--keyboard-selected {
          background-color: $activeDateBackgroundColor;
          color: $dateTextColor;
        }
      
        .react-datepicker__day--in-selecting-range {
          background-color: $midtonesColorStatic;
          color: $dateTextColor;
        }
      
        .react-datepicker__day-name {
          color: $dayOfWeekColor;
        }
      
        .react-datepicker__day--outside-month {
          color: $dateTextColorInactive;
        }
      
        .react-datepicker__navigation {
          background-color: $contentBackgroundColor;
          border-color: $midtonesColorStatic;
        }
      
        .react-datepicker__navigation-icon::before {
          border-color: $textColor;
        }
      }
    }
  }
}

@include forDarkTheme() {
  $activeDateBackgroundColor: #001727;
  $dateTextColor: $textColorLight;
  $dateTextColorInactive: #7F7F7F;
  $dayOfWeekColor: #767575;

  .hasDataIndicator, .sampleDataIndicator {
    background-color: #e2e2e2;
  }

  .popup {
    :global {
      .react-datepicker {
        .react-datepicker__current-month {
          color: $textColorLight;
        }

        .react-datepicker__day {
          color: $textColorLight;

          &:hover {
            &:not(.react-datepicker__day--in-range) {
              background-color: $midtonesColorStaticDark;
            }
          }
        }

        .react-datepicker__day--in-range {
          background-color: $activeDateBackgroundColor;
          color: $dateTextColor;
        }
      
        .react-datepicker__day--keyboard-selected {
          background-color: $activeDateBackgroundColor;
          color: $dateTextColor;
        }
      
        .react-datepicker__day--in-selecting-range {
          background-color: $midtonesColorStaticDark;
          color: $dateTextColor;
        }
      
        .react-datepicker__day-name {
          color: $dayOfWeekColor;
        }
      
        .react-datepicker__day--outside-month {
          color: $dateTextColorInactive;
        }
      
        .react-datepicker__navigation {
          background-color: $contentBackgroundColorDark;
          border-color: $midtonesColorStaticDark;
        }
      
        .react-datepicker__navigation-icon::before {
          border-color: $textColorLight;
        }
      }
    }
  }
}