.button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin: 0;
}

.input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  display: inline-flex;
}

.inputWithButton {
  display: flex;
}
