@import 'OK/styles/util';

.assetName {
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.backButton {
  font-weight: bold;
}

.backButtonPopup {
  font-weight: bold;
  margin: 0;
  margin-left: -28px;
  margin-right: -10px;
}

.containerEnter,
.containerEnterActive,
.containerEnterDone {
  opacity: 1;
}

.containerAppear,
.containerAppearActive,
.containerAppearDone,
.containerExit,
.containerExitActive,
.containerExitDone {
  opacity: 0;
}

.menuBar {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  position: relative;
  transition: height ease $timingShort;
  width: 100%;
}

.menuContainer {
  position: fixed;
  width: 100%;
}

.submenuContent {
  align-items: center;
  display: flex;
  height: $minClickableHeight;
}

.sectionButton {
  flex: 0 0 44px;
  font-weight: bold;
  margin: 0 0 0 $contentMarginXs;
  padding: 0 $contentMarginXs;

  &.active {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
}

.title {
  &.left {
    margin: 0 auto 0 0;
  }

  &.right {
    margin: 0 0 0 auto;
  }
}

.titlePopup {
  display: flex;
  align-items: center;

  &.left {
    margin: 0 auto 0 0;
  }

  &.right {
    margin: 0 0 0 auto;
  }
}

.topMenuContent {
  @include propertyWithFallbackValue(
    padding-left,
    calc(#{$contentMarginMd} + var(--safeAreaInsetLeft)),
    $contentMarginLg
  );
  @include propertyWithFallbackValue(
    padding-right,
    calc(#{$contentMarginMd} + var(--safeAreaInsetRight)),
    $contentMarginLg
  );
  align-items: stretch;
  display: flex;
  height: $minClickableHeight;

  @include forMinScreenWidth(medium) {
    margin: 0 auto;
    max-width: $contentMaxWidthDesktop;
  }
}

@include forLightTheme() {
  .menuBar {
    border-bottom-color: $midtonesColorStatic;
  }

  .menuContainer {
    background-color: rgba(255, 255, 255, 0.95);
  }

  .sectionButton {
    color: $textColor;

    &.active {
      border-bottom-color: $navigationColor;
      color: $navigationColor;
    }
  }
}

@include forDarkTheme() {
  .menuBar {
    border-bottom-color: $midtonesColorStaticDark;
  }

  .menuContainer {
    background-color: rgba(0, 0, 0, 0.95);
  }

  .sectionButton {
    color: $textColorLight;

    &.active {
      border-bottom-color: $navigationColor;
      color: $navigationColor;
    }
  }
}

.tabIcon {
  align-self: center;
  margin-bottom: 5px;
  margin-right: 5px;
}

.themeOverrideDark {
  .sectionButton {
    color: $textColorLight;
  }
}

.themeOverrideLight {
  .sectionButton {
    color: $textColor;
  }
}
