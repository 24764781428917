@import 'OK/styles/util';

.button {
  @include increaseClickableArea(3px, 5px);
  flex: 0 0 34px;
  height: 100%;
  padding: 0;
  user-select: none;
  width: 34px;

  img {
    position: relative;
    top: -1px;
  }

  &:disabled {
    opacity: 0;
  }
}

.input {
  // Remove input[type=number] arrows
  appearance: textfield;
  background: none;
  border: 0;
  display: block;
  height: 100%;
  padding: 0;
  text-align: center;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

.stepper {
  border-radius: $inputBorderRadius;
  border-style: solid;
  border-width: 1px;
  display: flex;
  height: $inputHeight;
  position: relative;
}

.valueLimitReached {
  line-height: $inputHeight - 2px;
  margin: 0;
  position: absolute;

  &.max {
    right: 15px;
  }

  &.min {
    left: 15px;
  }
}

@include forLightTheme() {
  .button {
    color: $brandColor;
  }

  .input {
    color: $textColor;
  }

  .stepper {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;

    &.focused {
      border-color: $brandColor;
    }

    &.disabled {
      background-color: $midtonesColorStatic;
    }
  }
}

@include forDarkTheme() {
  .button {
    color: $brandColorAlternate;
  }

  .input {
    color: $textColorLight;
  }

  .stepper {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;

    &.focused {
      border-color: $brandColorAlternate;
    }

    &.disabled {
      background-color: $midtonesColorStaticDark;
    }
  }
}
