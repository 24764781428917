@import 'OK/styles/util';

.container {
  max-width: 335px;
  :last-child {
    margin-bottom: 0;
  }
}

.copyButton {
  border-radius: 2px;
  font-size: $fontSizeXs;
  font-weight: bold;
  line-height: $lineHeightXs;
  padding: 0 $contentMarginSm;
}

.errorMessage {
  margin-top: -15px;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;

  > * {
    margin: 0;
  }
}

.input {
  margin-bottom: $contentMarginMd;
}

.label {
  margin-bottom: $contentMarginSm;
}

.nameInput {
  margin-bottom: $contentMarginMd;
}

.okidInput {
  margin-bottom: $contentMarginSm;
  border-radius: $inputBorderRadius;
  border-radius: $inputBorderRadius;
  height: $inputHeight;
  line-height: $inputHeight;
  padding: 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@include forLightTheme() {
  .okidInput {
    background-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .okidInput {
    background-color: $midtonesColorStaticDark;
  }
}
