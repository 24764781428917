@import 'OK/styles/util';

.cancelButton {
  left: $contentMarginMd;
  pointer-events: auto;
  position: absolute;
  top: $contentMarginMd;
}

.confirmButton {
  pointer-events: auto;
  position: absolute;
  right: $contentMarginMd;
  top: $contentMarginMd;
}

.container {
  padding-top: 100%;
  position: relative;
}

.instructions {
  font-weight: bold;
  margin: auto;
  padding: 2px $contentMarginSm;
  top: $contentMarginXxl;
}

.overlay {
  align-items: flex-end;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-in;
}

.zoomContainer {
  align-items: flex-start;
  bottom: $contentMarginMd;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: $contentMarginMd 0 0 $contentMarginMd;
  pointer-events: auto;
  position: absolute;
  width: $minClickableHeight + $contentMarginMd;
}

.zoomSlider {
  height: 16px;
  margin: $contentMarginXl -25px;
  transform: rotate(270deg);
  width: 65px;
}

@include forLightTheme() {
  .instructions {
    background-color: $midtonesColorStatic;
  }

  .overlay {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

@include forDarkTheme() {
  .instructions {
    background-color: $midtonesColorStaticDark;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
