@import 'OK/styles/util';

.container {
  cursor: pointer;
}

.headerContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;

  > * {
    margin-bottom: 0;
  }
}

.priceContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;

  > * {
    margin-bottom: 0;
  }
}

.publicId {
  margin-bottom: $contentMarginSm;
}

.statusContainer {
  align-items: center;
  display: flex;
  height: $lineHeightMd;
  justify-content: flex-end;
}

.statusTag {
  @include fontDefault();
}

@include forLightTheme() {
  .statusTag {
    color: $textColorLight;
  }
}

@include forDarkTheme() {
  .statusTag {
    color: $textColorLight;
  }
}
