@import 'OK/styles/util';

.cardContainer {
  display: block;
  width: 100%;
  margin-bottom: 0 !important;

  @include forMinScreenWidth(medium) {
    display: inline-block;
  }
}

.documentCarouselInnerSecondary {
  gap: $contentMarginMd;
  padding: 0 $contentMarginSm;
  :global(.os-scrollbar-horizontal) {
    padding: 0 $contentMarginXxl !important;
  }
  @include forMinScreenWidth(medium) {
    :global(.os-scrollbar-horizontal) {
      padding: 0 $contentMarginXl !important;
    }
  }
}

.documentCarouselSecondary {
  margin: 0 (-$contentMarginLg) !important;

  @include forMinScreenWidth(medium) {
    margin: 0 (-$contentMarginXxl) !important;
  }
}

.errorMessage {
  margin: -15px 0 15px 0;
}

.sectionCardContent {
  padding-top: $contentMarginLg;
}

.searchInput {
  z-index: 1;
}

.searchInputContainer {
  margin-bottom: $contentMarginMd;
  position: relative;
  z-index: 1;
}

.searchNewButton {
  @include increaseClickableArea(2px, 0, absolute);
  height: $buttonHeight;
  margin: 0;
  right: 30px + $contentMarginSm;
  top: 0;
  width: 44px;
  z-index: 3;
}

.searchNewButtonSpan {
  background: $contentBackgroundColor;
  display: flex;
  align-items: center;
  height: 36px;
  width: 44px;
}

.searchResultButton {
  height: $lineHeightMd;
  margin: 0;

  img {
    margin-left: $contentMarginSm;
    width: 1em;
  }
}

.searchResults {
  margin-top: -$contentMarginXs;
  position: absolute;
  width: 100%;
}

.searchResultOKID {
  @include fontMono();
}

.sectionIntro {
  @include forMinScreenWidth(medium) {
    max-width: 400px;
  }
}

.sectionNotice {
  margin-left: -$contentMarginMd;
  margin-right: -$contentMarginMd;

  @include forMinScreenWidth(medium) {
    float: right;
    margin: -$contentMarginMd 0 0 0;
    width: 267px;
  }
}

@include forDarkTheme() {
  .searchNewButtonSpan {
    background: $contentBackgroundColorDark;
  }
}