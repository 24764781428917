@import 'OK/styles/util';

.input {
  margin-bottom: $contentMarginMd;
  z-index: 1;
}

.saveButton {
  margin-bottom: $contentMarginSm;
}

.searchContainer {
  position: relative;
  z-index: 1;
}

.searchSuggestions {
  left: 0;
  position: absolute;
  top: $inputHeight - $contentMarginXs;
  width: 100%;
}

.selectionCard {
  margin: 0 0 $contentMarginLg (-$contentMarginMd);
}
