@import 'OK/styles/util';

@include forLightTheme() {
  .default {
    .button {
      background-color: $contentBackgroundColor;
      border-color: $midtonesColorStatic;
      color: $textColor;

      &.active {
        background-color: $navigationColorMidtone;
        border-color: $navigationColorMidtone;
        color: $textColorLight;
      }
    }
  }

  .separate {
    .button {
      background-color: $midtonesColorStatic;
      color: $textColor;

      &.active {
        background-color: $navigationColorMidtone;
        color: $textColorLight;
      }
    }
  }
}

@include forDarkTheme() {
  .default {
    .button {
      background-color: $contentBackgroundColorDark;
      border-color: $midtonesColorStaticDark;
      color: $textColorLight;

      &.active {
        background-color: $navigationColorMidtone;
        border-color: $navigationColorMidtone;
        color: $textColorLight;
      }
    }
  }

  .separate {
    .button {
      background-color: $midtonesColorStaticDark;
      color: $textColorLight;

      &.active {
        background-color: $navigationColorMidtone;
        color: $textColor;
      }
    }
  }
}
