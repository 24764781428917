@import 'OK/styles/util';

.menuBackgroundDiv {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 250ms opacity;
  z-index: -1;

  &.show {
    opacity: 1;
  }
}

@include forLightTheme() {
  .menuBackgroundDiv {
    background: $navigationMenuBackgroundColor;
    border-bottom: 1px solid $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .menuBackgroundDiv {
    background: $navigationMenuBackgroundColorDark;
    border-bottom: 1px solid $midtonesColorStaticDark;
  }
}
