@import 'OK/styles/util';

.accessoryButton {
  height: $inputHeight - 2px;
  margin-left: $contentMarginSm;
  position: relative;
  top: -1px;

  button {
    height: 100%;
    margin: 0;
  }
}

.cancelButton {
  @include increaseClickableArea(2px);
  height: $inputHeight - 2px !important;
  position: absolute;
  right: 14px;
  top: -1px;

  &:not(:last-child) {
    right: 44px;
  }
}

.container {
  align-items: center;
  border-radius: $inputBorderRadius;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex: 1 0;
  height: $inputHeight;
  min-width: 0;
  padding: 1px;
  position: relative;

  // Focused state
  &.focused {
    border-width: 2px;
    padding: 0;

    .icon {
      right: 10px;
    }
  }

  // Error state
  &.error {
    border-color: $alertColorCard !important;

    .input {
      color: $alertColorCard;
    }
  }

  // Read-only state
  &.readOnly {
    border: 0;

    .input {
      font-weight: bold;
    }
  }

  // Disabled state
  &.disabled {
    opacity: 0.5;
  }
}

.errorMessage {
  margin: $contentMarginXs 0 (-$contentMarginXs) 0;
}

.icon {
  flex: 0;
  line-height: 0;
  padding: 11px;
  

  &.rotate {
    animation: rotate 1s linear infinite;
  }
}

.iconButton {
  @include increaseClickableArea(2px);
  align-items: center;
  background: none;
  border: 0;
  display: flex;
  justify-content: center;
  position: relative;
  width: $inputHeight;
}

// Base input style
.input {
  appearance: none;
  background: none;
  border: 0;
  flex: 1 1;
  height: 100%;
  line-height: inherit;
  min-width: 0;
  padding: 0 0 0 $inputPadding;
  transition: all 100ms;
  // transition: width 100ms, border 100ms, padding 100ms;

  &.dateInput {
    padding: 0 $inputPadding;
  }
}

// Input group style
.inputGroup {
  .input {
    border-radius: 0 $inputBorderRadius $inputBorderRadius 0;
  }
}

// Light theme

@include forLightTheme() {
  .container {
    background-color: $contentBackgroundColor;
    border-color: $inputBorderColorInactive;

    &.hasValue,
    &.focused {
      border-color: $inputBorderColorActive;
    }

    &.hovered {
      border-color: $navigationColor;
    }

    &.readOnly {
      background-color: $midtonesColorStatic;

      .input {
        background-color: $midtonesColorStatic;
      }
    }
  }

  .input {
    color: $textColor;

    &::placeholder {
      color: $midtonesColorText;
    }
  }
}

// Dark theme

@include forDarkTheme() {
  .container {
    background-color: $contentBackgroundColorDark;
    border-color: $inputBorderColorInactiveDark;

    &.hovered {
      border-color: $navigationColor;
    }

    &.hasValue,
    &.focused {
      border-color: $inputBorderColorActiveDark;
    }

    &.readOnly {
      background-color: $midtonesColorStaticDark;

      .input {
        background-color: $midtonesColorStaticDark;
      }
    }
  }

  .input {
    color: $textColorLight;

    &::placeholder {
      color: $midtonesColorTextDark;
    }
  }
}

// Loading animation

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
