@import 'OK/styles/util';

@include forLightTheme() {
  .tooltip {
    background-color: $brandColor;
    color: $textColorLight;

    &.above {
      &::before {
        border-top-color: $brandColor;
      }
    }

    &.below {
      &::before {
        border-bottom-color: $brandColor;
      }
    }

    &.alert {
      background-color: $alertColorMidtone;

      &.above {
        &::before {
          border-top-color: $alertColorMidtone;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $alertColorMidtone;
        }
      }
    }

    &.creation {
      background-color: $creationColorMidtone;

      &.above {
        &::before {
          border-top-color: $creationColorMidtone;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $creationColorMidtone;
        }
      }
    }

    &.navigation {
      background-color: $navigationColorMidtone;

      &.above {
        &::before {
          border-top-color: $navigationColorMidtone;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $navigationColorMidtone;
        }
      }
    }

    &.notification {
      background-color: $notificationColorMidtone;

      &.above {
        &::before {
          border-top-color: $notificationColorMidtone;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $notificationColorMidtone;
        }
      }
    }
  }
}

@include forDarkTheme() {
  .tooltip {
    background-color: $brandColorAlternate;
    color: $textColor;

    &.above {
      &::before {
        border-top-color: $brandColorAlternate;
      }
    }

    &.below {
      &::before {
        border-bottom-color: $brandColorAlternate;
      }
    }

    &.alert {
      background-color: $alertColorMidtoneDark;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $alertColorMidtoneDark;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $alertColorMidtoneDark;
        }
      }
    }

    &.creation {
      background-color: $creationColorMidtoneDark;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $creationColorMidtoneDark;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $creationColorMidtoneDark;
        }
      }
    }

    &.navigation {
      background-color: $navigationColorMidtoneDark;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $navigationColorMidtoneDark;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $navigationColorMidtoneDark;
        }
      }
    }

    &.notification {
      background-color: $notificationColorMidtoneDark;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $notificationColorMidtoneDark;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $notificationColorMidtoneDark;
        }
      }
    }
  }
}
