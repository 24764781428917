@import 'OK/styles/util';

$selectWidth: 95px;
$tooltipWidth: 135px;

.container {
  display: flex;
  height: $inputHeight;
  min-width: 0;
}

.input {
  border-left-width: 1px !important;
  border-radius: 0 $inputBorderRadius $inputBorderRadius 0 !important;
  padding-left: 1px !important;
}

.labelTooltip {
  left: calc((#{$selectWidth} / 2) - (#{$tooltipWidth} / 2));
  text-align: center;
  top: -35px;
  width: $tooltipWidth;
}

.select {
  flex: 0 0 $selectWidth;
  height: 100%;

  button {
    @include increaseClickableArea(3px, 3px, absolute);
    border: 0;
    border-radius: $inputBorderRadius 0 0 $inputBorderRadius !important;
    width: 100%;
  }
}

.tooltipTransition {
  z-index: 1;
}
