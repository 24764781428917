@import 'OK/styles/util';

$desktopDescriptionWidth: 400px;

.addSlideButton {
  align-items: center;
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  flex-direction: column;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  min-height: 450px;
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: $cardWidthDesktop;
  }

  img {
    margin-bottom: $contentMarginSm;
  }
}

.carousel {
  width: 100%;
  :global(.os-scrollbar-horizontal) {
    padding: 0 $contentMarginXxl !important;
  }
  @include forMinScreenWidth(medium) {
    :global(.os-scrollbar-horizontal) {
      padding: 0 $contentMarginXl !important;
    }
  }
}

.carouselContainer {
    margin: 0 (-$contentMarginLg) !important;
    @include overlayScrollbarsDefaultScrollbarPadding();
  
    @include forMinScreenWidth(medium) {
      margin: 0 (-$contentMarginXxl) !important;
    }
  }

.creationError {
  margin: -15px 0 15px 0;
}

.inspection {
  height: 100%;
  width: 100%;
}

.inspectionSlide {
  margin: 0 $contentMarginSm;
  width: 100%;
  flex: 0 0 100%;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    > * {
      margin-right: $contentMarginSm;
    }
  }

  @include forMinScreenWidth(medium) {
    width: $cardWidthDesktop;
    flex: 0 0 $cardWidthDesktop;

    &:first-child {
      margin-left: $contentMarginSm;
    }

    &:last-child {
      > * {
        margin-right: $contentMarginSm;
      }
    }
  }
}

.searchInput {
  z-index: 1;
}

.searchInputContainer {
  margin-bottom: $contentMarginMd;
  position: relative;
  z-index: 1;
}

.searchNewButton {
  @include increaseClickableArea(2px, 0, absolute);
  height: $buttonHeight;
  margin: 0;
  right: 30px + $contentMarginSm;
  top: 0;
  width: 44px;
  z-index: 3;
}

.searchResultButton {
  height: $lineHeightMd;
  margin: 0;

  img {
    margin-left: $contentMarginSm;
    width: 1em;
  }
}

.searchResultOKID {
  @include fontMono();
}

.searchResults {
  margin-top: -$contentMarginXs;
  position: absolute;
  width: 100%;
}

.sectionDescription {
    margin-top: $contentMarginXl;
    margin-bottom: $contentMarginMd;
    @include forMinScreenWidth(medium) {
      width: $desktopDescriptionWidth;
    }
  }

.sectionIntro {
  @include forMinScreenWidth(medium) {
    max-width: 400px;
  }
}

.sectionNotice {
  margin-right: -$contentMarginMd;
  margin-left: -$contentMarginMd;

  @include forMinScreenWidth(medium) {
    float: right;
    margin: -$contentMarginMd 0 $contentMarginMd 0;
    width: 267px;
  }
}

@include forLightTheme() {
  .addSlideButton {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
    color: $textColor;
  }

  .subSectionHeader {
    border-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .addSlideButton {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
    color: $textColorLight;
  }

  .subSectionHeader {
    border-color: $midtonesColorStaticDark;
  }
}
