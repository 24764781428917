@import 'OK/styles/util';

$aggregateChartWidth: 110px;
$desktopDescriptionWidth: 400px;

.allLogsChartContainer {
  height: 100%;
  width: $aggregateChartWidth;
}

.basicsSectionContent {
  margin-top: -170px;

  @include forMinScreenWidth(medium) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: $contentMarginMd;
  }
}

.countryTag {
  margin-left: 0 !important;
}

.contactInfoCard {
  order: 1;

  @include forMinScreenWidth(medium) {
    order: 2;
  }
}

.container {
  padding-top: 7px;
}

.chartDisplayTypeButtons {
  margin-bottom: $contentMarginMd;
}

.chartFilterChip {
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  margin-right: $contentMarginSm;
}

.chartFilterDropdowns {
  display: flex;
  flex-direction: column;

  @include forMinScreenWidth(medium) {
    flex-direction: row;
    justify-content: space-between;
    gap: 60px;
  }
}

.chartFilterLabel {
  margin-bottom: $contentMarginSm;
}

.chartFilters {
  margin-bottom: $contentMarginLg;
}

.chartFilterSection {
  margin-bottom: $contentMarginMd;
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: 50%;
  }
}

.chartHeaders {
  align-items: center;
  display: flex;
  margin-bottom: $contentMarginMd;

  > * {
    margin-bottom: 0;
  }
}

.chartScaleIcon {
  margin-left: $contentMarginMd;
  width: 16px;
}

.chartsContainer {
  display: flex;
  height: 200px;
  margin-bottom: 0;
}

.dashboard {
  margin-bottom: 0;
}

.contactInfoActionIcon {
  flex: 0 0 16px;
  margin-left: $contentMarginXs;
}

.contactInfoItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;
}

.contactInfoLabel {
  margin: 0 $contentMarginLg 0 0;
  white-space: nowrap; 
}

.contactInfoValue {
  display: inline-block;
  margin: 0 0 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.countryTag {
  @include fontDefault();
  font-size: $fontSizeSectionHeader !important;
  line-height: $lineHeightSectionHeader !important;
  margin-left: $contentMarginXs;
  position: relative;
  top: -2px;
}

.copyContactInfoButton {
  border: none !important;
  font-size: $fontSizeXs;
  font-weight: bold;
  height: 30px;
  line-height: $lineHeightXs;
  padding: $contentMarginXs $contentMarginSm;

  @include increaseClickableArea(7px, 0px);
}

.desktopDetailSeparator {
  margin-top: $contentMarginSm;
}

.detailIcon {
  margin-right: $contentMarginXs;
}

.hideTab,
.showTab {
  color: $navigationColor !important;
  margin-left: auto;

  > svg {
    margin-left: $contentMarginSm;
    position: relative;
    top: 3px;
  }
}

.shareTab {
  color: $navigationColor !important;
  margin-right: $contentMarginMd;

  > svg {
    margin-left: $contentMarginSm;
    position: relative;
  }
}

.shareToast {
  left: -50px;
  position: absolute;
  text-align: center;
  top: $contentMarginXxl;
  width: 175px;
  pointer-events: none;
}

.detailsTabs {
  margin: 0 -7px;
}

.detailsTabsContainer {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  position: sticky;
  transition: top ease $timingShort;
  z-index: 10;
}

.downCaret {
  position: relative;
  top: -2px;
  transform: rotate(90deg);
}

.editButton {
  margin: 0 $contentMarginSm 0 0;
  white-space: nowrap;
}

.grade {
  margin-right: $contentMarginMd;
}

.gradeBox {
  margin-bottom: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    flex: 1 0 $cardWidth3ColumnDesktop;
    margin-bottom: 0;
    margin-right: $contentMarginMd;

    &:last-child {
      margin-right: 0;
    }
  }
}

.gradeBoxesContainer {
  @include forMinScreenWidth(medium) {
    display: flex;
  }
}

.latestLogsChartContainer {
  height: 100%;
  width: calc(100% - $aggregateChartWidth);
}

.latestLogsChartHeader {
  margin-right: auto;
}

.layout {
  padding-top: $mainMenuHeight + ($minClickableHeight * 2) - 50px;
}

.logo {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
  position: absolute;
}

.logoContainer {
  padding-bottom: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: $cardBorderRadius;
  border-style: solid !important;
  border-width: 2px !important;
  border-color: transparent;
  display: flex;

  @include forMinScreenWidth(medium) {
    order: 1;
    margin: 0;
    padding-top: 0;
  }
}

.addressImageContainer {
  padding-top: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 150px;
  order: 2;
  margin-top: -$contentMarginXl;
  border-radius: 5px;
  display: flex;

  @include forMinScreenWidth(medium) {
    height: 200px;
    order: 1;
    margin: 0;
    padding-top: 0;

    margin-top: 0;
  }
}

.noRecentLogsMessage {
  margin-bottom: 0;
  margin-top: $contentMarginXs;
}

.okid {
  @include fontMono();
}

.okidNotice {
  margin-bottom: $contentMarginSm;
}

.organisationContactInfoContainer {
  overflow: hidden;
  border-radius: 5px;
  order: 3;
  display: flex;
  flex-direction: column;
}

.organisationDetails {
  margin-bottom: 0;
  order: 2;
  border-style: solid;
  border-width: 1px;
  margin-bottom: -$contentMarginXl;

  @include forMinScreenWidth(medium) {
    flex: 1 0;
    margin: 0;
  }
}

.organisationGrades {
  margin-bottom: $contentMarginMd;
}

.organisationName {
  margin-bottom: $contentMarginSm;
  margin-right: $contentMarginSm;
}

.organisationNameText {
  font-weight: bold;
  display: block;
  font-size: $fontSizeCardHeader;
  line-height: $lineHeightCardHeader;
  margin-right: $contentMarginSm;
}

.productSlide {
  margin-left: $contentMarginSm;
  margin-right: $contentMarginSm;

  &:first-child {
    margin-left: $contentMarginLg;
  }

  &:last-child {
    margin-right: 0;

    > * {
      margin-right: $contentMarginLg;
    }
  }

  @include forMinScreenWidth(medium) {
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      > * {
        margin-right: 0;
      }
    }
  }
}

.productsCarouselContainer {
  margin-left: -$contentMarginLg;
  margin-right: -$contentMarginLg;

  @include overlayScrollbarsDefaultScrollbarPadding();

  @include forMinScreenWidth(medium) {
    margin-left: 0;
    margin-right: 0;
  }
}

.searchContainer {
  position: relative;
  z-index: 1;
}

.searchInput {
  z-index: 1;
}

.searchSuggestions {
  left: 0;
  position: absolute;
  top: $inputHeight - 5px;
  width: 100%;
}

.section {
  padding-top: $contentMarginLg;
}

.sectionDescription {
  @include forMinScreenWidth(medium) {
    width: $desktopDescriptionWidth;
  }
}

.seeGradesButton {
  font-weight: bold;
  margin: 0 $contentMarginMd;

  @include forMinScreenWidth(medium) {
    margin: 0;
  }
}

.submenu {
  align-items: center;
  display: flex;
  width: 100%;
}

.submenuOrganisationName {
  flex: 1 1 100%;
  margin: 0 auto 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subSection {
  margin-bottom: $contentMarginLg;

  &:not(:last-child) {
    &::after {
      content: '';
      display: block;
      margin: $contentMarginLg $contentMarginMd 0;
      height: 2px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.visualSupplyChainComingSoonText {
  margin: -100% 0 0 0;
  font-size: $fontSizePageHeader;
  font-weight: bold;

  @include forMinScreenWidth(medium) {
    margin: 0;
  }
}

.visualSupplyChainPreview {
  align-items: center;
  background-image: url('/img/visual_supply_chain_coming_soon.jpg');
  background-position: center;
  background-size: cover;
  border-radius: $cardBorderRadius;
  display: flex;
  height: 0;
  justify-content: center;
  padding-top: 100%;
  position: relative;
  width: 100%;

  @include forMinScreenWidth(medium) {
    padding-top: 0;
    height: 400px;
  }
}

@include forLightTheme() {
  .basicsSection,
  .gradesSection,
  .placeholderSection,
  .logoContainer,
  .organisationContactInfoContainer,
  .organisationDetails {
    background-color: $contentBackgroundColor !important;
  }

  .sectionsSubmenuContainer {
    background-color: $contentBackgroundColor !important;
  }

  .organisationDetails,
  .organisationContactInfoContainer {
    border-color: $contentBackgroundColor !important;
  }

  .sectionCard,
  .outerSection {
    background-color: $contentBackgroundColorCard !important;
  }

  .detailsContainer {
    background-color: $midtonesColorStatic !important;
  }

  .detailsTabsContainer {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
    z-index: 10;
  }

  .subSection {
    &:not(:last-child) {
      &::after {
        background-color: $midtonesColorStatic;
      }
    }
  }
}

@include forDarkTheme() {
  .basicsSection,
  .gradesSection,
  .placeholderSection,
  .logoContainer,
  .organisationContactInfoContainer,
  .organisationDetails {
    background-color: $contentBackgroundColorDark !important;
  }

  .sectionsSubmenuContainer {
    background-color: $contentBackgroundColorDark !important;
  }

  .organisationDetails,
  .organisationContactInfoContainer {
    border-color: $contentBackgroundColorDark !important;
  }
  .sectionCard,
  .outerSection {
    background-color: $contentBackgroundColorCardDark !important;
  }

  .detailsTabsContainer {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
  }

  .subSection {
    &:not(:last-child) {
      &::after {
        background-color: $midtonesColorStaticDark;
      }
    }
  }
}

$desktopInfoColumnWidth: 295px;

.basicSectionCard {
  max-width: $desktopInfoColumnWidth;
  padding-right: 20px !important;
  padding-left: 20px !important;
  margin-right: 0;

  @include forMinScreenWidth(medium) {
    min-width: $desktopInfoColumnWidth;
  }
}

.nameField {
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginSm;
}

.contactInfo {
  margin-top: 5px;
}

.infoField {
  display: flex;
  justify-content: space-between;
}

.basicSectionMapCard {
  max-width: $desktopInfoColumnWidth;
}

.outerSection {
  height: 170px;
}

.sectionCard {
  padding: $contentMarginMd 0 0;
  overflow: hidden;

  &:last-child {
    padding-bottom: 100px;
  }
}

.nameEditButton {
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  height: 40px;
  margin: 0;
  border: 1px solid $navigationColor !important ;
  z-index: 10;
}

.organisationMap {
  order: 2;
}

.editButtonContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 19px;
  padding-right: 19px;
}

.tabClassName {
  font-weight: normal !important;
}

.sectionsSubmenuContainer {
  position: sticky;
  top: 0;
  z-index: 5;
  transition: all $timingShort ease-in-out;
}

.sectionsSubmenuContainerMenu {
  top: 105px !important;
}
