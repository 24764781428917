@import 'OK/styles/util';

.errorMessage {
  margin: $contentMarginXs 0 0 0;
}

.inputContainer {
  margin-top: $contentMarginMd;
  display: flex;
  transition: height $timingShort ease, opacity $timingShort ease;

  &.exit {
    height: 0;
    opacity: 0;
  }

  &.enter {
    height: $inputHeight;
    opacity: 1;
  }
}

.submitButton {
  margin: 0 0 0 $contentMarginSm;
}
