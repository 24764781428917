@import 'OK/styles/util';

.filterNotesSelect {
  margin-bottom: $contentMarginMd;
  max-width: 400px;
}

.highlightsLogButton {
  margin-top: 30px;
}

.noteCard {
  height: 100%;
  width: 100%;
}

.noteSlide {
  flex: 0 0 calc(100% - #{$contentMarginLg * 2});
  margin: 0 $contentMarginSm;

  &:first-child {
    margin-left: $contentMarginLg;
  }

  &:last-child {
    > * {
      display: inline-block;
      margin-right: $contentMarginLg;
    }
  }

  @include forMinScreenWidth(medium) {
    flex-basis: $cardWidth3ColumnDesktop;

    &:first-child {
      margin-left: 0;
    }
  }
}

.notesCarousel {
  margin-left: -$contentMarginLg;
  margin-right: -$contentMarginLg;

  @include overlayScrollbarsDefaultScrollbarPadding();
}

.section {
  padding-bottom: $contentMarginXxl;
  padding-top: $contentMarginXl;
}

.sectionDescription {
  max-width: 400px;
}

.sectionNotice {
  @include forMinScreenWidth(medium) {
    float: right;
    margin-top: 0;
    max-width: 350px;
  }
}
