@use 'sass:color';
@import 'OK/styles/util';

@include forLightTheme() {
  .actionIndicator {
    &.alert {
      background-color: $alertColorCard;
    }

    &.creation {
      background-color: $creationColorCard;
    }

    &.navigation {
      background-color: $navigationColorCard;
    }

    &.notification {
      background-color: $notificationColorCard;
    }
  }

  .card {
    &.alert {
      background-color: $alertColorCard;
      color: $textColorLight;
    }
    &.creation {
      background-color: $creationColorCard;
      color: $textColorLight;
    }
    &.navigation {
      background-color: $navigationColorCard;
      color: $textColorLight;
    }
    &.notification {
      background-color: $notificationColorCard;
      color: $textColorLight;
    }
  }

  .footerRow {
    &:last-child {
      background: none;
    }
  }

  .mediaGalleryContainer {
    background-color: color.change($midtonesColorStatic, $alpha: 0.5);
  }

  .mediaOverlay {
    background-color: color.change($midtonesColorStatic, $alpha: 0.5);
  }
}

@include forDarkTheme() {
  .actionIndicator {
    &.alert {
      background-color: $alertColorCardDark;
    }

    &.creation {
      background-color: $creationColorCardDark;
    }

    &.navigation {
      background-color: $navigationColorCardDark;
    }

    &.notification {
      background-color: $notificationColorCardDark;
    }
  }

  .card {
    &.alert {
      background-color: $alertColorCardDark;
      color: $textColor;
    }
    &.creation {
      background-color: $creationColorCardDark;
      color: $textColor;
    }
    &.navigation {
      background-color: $navigationColorCardDark;
      color: $textColor;
    }
    &.notification {
      background-color: $notificationColorCardDark;
      color: $textColor;
    }
  }

  .footerRow {
    &:last-child {
      background: none;
    }
  }

  .mediaGalleryContainer {
    background-color: color.change($midtonesColorStaticDark, $alpha: 0.5);
  }

  .mediaOverlay {
    background-color: color.change($midtonesColorStaticDark, $alpha: 0.5);
  }
}
