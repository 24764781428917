@import 'OK/styles/util';

.dropIndicator {
  background-color: $navigationColor;
  height: 2px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.dropZone {
  position: relative;
}
