@import 'OK/styles/util';

.bold {
  font-weight: bold;
}

.resultTable {
  border-spacing: 0;
  width: 100%;

  td,
  th {
    padding: 0 $contentMarginXs;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  tfoot {
    th {
      padding-top: $contentMarginSm;
    }
  }

  th {
    text-align: left;
  }

  thead {
    margin-bottom: $contentMarginSm;

    th {
      padding-bottom: $contentMarginSm;
    }
  }

  .countColumn {
    background-color: $midtonesColorStatic;
    padding: 0 $contentMarginSm;
    text-align: right;
    width: 79px;
  }

  .indicatorColumn {
    padding-right: $contentMarginMd;
    width: 22px;

    @include forMinScreenWidth(medium) {
      padding-right: $contentMarginSm;
    }
  }

  .percentageColumn {
    padding-left: $contentMarginSm;
    text-align: right;
    width: 63px;
  }
}

.row {
  &:first-child {
    td {
      padding-top: $contentMarginXs;
    }

    .countColumn {
      border-radius: 3px 3px 0 0;
    }
  }

  &:last-child {
    td {
      padding-bottom: $contentMarginSm;
    }

    .countColumn {
      border-radius: 0 0 3px 3px;
    }
  }

  &.withBottomMargin {
    td {
      padding-bottom: $contentMarginSm;
    }
  }

  &.withBottomBorder {
    td {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      margin-bottom: $contentMarginSm;
      padding-bottom: $contentMarginSm;
    }
  }
}

.spacer {
  height: $contentMarginSm;
}

.spacerRowTD {
  line-height: $lineHeightMd;
}

.text {
  margin: 0;
}

@include forLightTheme() {
  .countColumn {
    background-color: $midtonesColorStatic;
  }

  .row.withBottomBorder {
    td {
      border-bottom-color: $midtonesColorStatic;
    }
  }
}

@include forDarkTheme() {
  .countColumn {
    background-color: $midtonesColorStaticDark;
  }

  .row.withBottomBorder {
    td {
      border-bottom-color: $midtonesColorStaticDark;
    }
  }
}
