@import 'OK/styles/util';

.buttonSave {
  margin-bottom: $contentMarginSm !important;
}

.field {
  margin-bottom: $contentMarginLg;
}

.header {
  align-items: center;
  display: flex;
  margin: 0 0 0 0;
  justify-content: space-between;
  height: auto;
}

.identifier {
  @include forMinScreenWidth(medium) {
    flex: 1 1 50%;

    &:first-child {
      margin-right: $contentMarginXxl;
    }
  }
}

.input {
  margin-top: $contentMarginSm;
}

.inputHelpText {
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  margin-top: $contentMarginSm;
}

.label {
  display: block;
  margin-bottom: $contentMarginMd;
}

.monoInput {
  @include fontMono();
}

.nameError {
  margin: $contentMarginXs 0 0 0;
}

.publishRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginSm;

  p {
    margin: 0;
  }
}

.normalWeight {
  font-weight: normal;
}

.skuErrorMessage {
  margin: 5px 0 15px 0;
}

.textAvatar {
  margin-bottom: 10px;
}
