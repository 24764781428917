@import 'OK/styles/util';

@include forLightTheme() {
  .tooltip {
    background-color: $brandColor;
    color: $textColorLight;

    &.above {
      &::before {
        border-top-color: $brandColor;
      }
    }

    &.below {
      &::before {
        border-bottom-color: $brandColor;
      }
    }

    &.alert {
      background-color: $alertColorCard;

      &.above {
        &::before {
          border-top-color: $alertColorCard;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $alertColorCard;
        }
      }
    }

    &.creation {
      background-color: $creationColorCard;

      &.above {
        &::before {
          border-top-color: $creationColorCard;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $creationColorCard;
        }
      }
    }

    &.navigation {
      background-color: $navigationColorCard;

      &.above {
        &::before {
          border-top-color: $navigationColorCard;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $navigationColorCard;
        }
      }
    }

    &.notification {
      background-color: $notificationColorCard;

      &.above {
        &::before {
          border-top-color: $notificationColorCard;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $notificationColorCard;
        }
      }
    }
  }
}

@include forDarkTheme() {
  .tooltip {
    background-color: $brandColorAlternate;
    color: $textColor;

    &.above {
      &::before {
        border-top-color: $brandColorAlternate;
      }
    }

    &.below {
      &::before {
        border-bottom-color: $brandColorAlternate;
      }
    }

    &.alert {
      background-color: $alertColorCardDark;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $alertColorCardDark;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $alertColorCardDark;
        }
      }
    }

    &.creation {
      background-color: $creationColorCardDark;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $creationColorCardDark;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $creationColorCardDark;
        }
      }
    }

    &.navigation {
      background-color: $navigationColorCardDark;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $navigationColorCardDark;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $navigationColorCardDark;
        }
      }
    }

    &.notification {
      background-color: $notificationColorCardDark;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $notificationColorCardDark;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $notificationColorCardDark;
        }
      }
    }
  }
}
