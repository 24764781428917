@import 'OK/styles/util';

.cancelButton {
  float: right;
  position: relative;
  top: -2px;
}

.header {
  display: inline-block;
}

.popupContent {
  margin-bottom: 200px;
}

.testRefId {
  margin-left: $contentMarginSm;
  position: relative;
  top: -3px;
}
