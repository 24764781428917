@use 'sass:color';
@import 'OK/styles/util';

// doiuse-disable css-masks

.addSlideIndicator {
  @include increaseClickableArea(16px);
  background-image: url('/icons/plus_green.svg');
  background-position: center;
  background-repeat: no-repeat;
  height: 16px;
  margin: 15px;
  width: 16px;
}

// Added bottom padding and matching it with negative bottom margin, we can increase the slider height without affecting the layout of elements below
// it and enabling the search results to extend beyond the bottom of the slider content.
.carousel {
  display: flex;

  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;

  @include forMinScreenWidth(medium) {
    justify-content: flex-start;
  }

  &.snap {
    scroll-snap-type: x mandatory;
  }

  &.noSnap {
    overflow: hidden;
    scroll-snap-type: none;
  }

  &.extendSides {
    margin-left: -$contentMarginLg;
    margin-right: -$contentMarginLg;
    width: calc(100% + #{$contentMarginLg * 2});
  }

  &.fadeSides {
    mask-image: linear-gradient(to right, transparent 0%, #000 20px, #000 calc(100% - 20px), transparent 100%);
  }

  // Customize scrollbar
  &.withCustomScrollbar {
    padding-bottom: $contentMarginLg;

    // Chrome, Safari, Edge
    &::-webkit-scrollbar {
      background: $contentBackgroundColor;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: $brandColor;
      border-radius: 8px;
      border-style: solid;
      border-width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $navigationColor;
      border-radius: 8px;
      height: 10px;
    }

    // Firefox
    scrollbar-width: thin;
    scrollbar-color: $navigationColor $brandColor;

    // Adjust positioning
    &.extendSides {
      &::-webkit-scrollbar-track {
        margin: 0 30px;
      }
    }
  }

  // Hide scroll bar
  &.hideScrollbar {
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
    &::-webkit-scrollbar {
      display: none; // Chrome, Safari and Opera
    }
  }
}

.container {
  position: relative;
}

.dotIndicator {
  @include increaseClickableArea(16px);
  border-color: currentColor;
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  height: 16px;
  margin: 15px;
  width: 16px;

  &.active {
    background-color: currentColor;
  }
}

.dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 16px;

  &.left {
    justify-content: flex-start;
    padding-left: 16px;
  }

  &.right {
    justify-content: flex-end;
    padding-right: 16px;
  }
}

.fade {
  position: absolute;
  top: 0;
  width: 30px;
  z-index: 1;

  &.left {
    left: -30px;
  }

  &.right {
    right: -30px;
  }
}

.fadeEffect {
  bottom: 0;
  position: absolute;
  top: 0;
  width: 10px;
  z-index: 1;

  &.left {
    left: -30px;
  }

  &.right {
    right: -30px;
  }
}

.slide {
  position: relative;
  scroll-snap-align: center;

  @include forMinScreenWidth(medium) {
    scroll-snap-align: start;
  }

  &.fullWidth {
    flex: 0 0 100%;
  }

  &.withSlideSeparator:first-child {
    border-left-width: 0 !important;
  }
}

@include forLightTheme() {
  .carousel {
    &.withCustomScrollbar {
      // Chrome, Safari, Edge
      &::-webkit-scrollbar {
        background: transparent;
      }

      &::-webkit-scrollbar-track {
        background: $brandColor;
        border-color: $contentBackgroundColor;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $navigationColor;
      }
    }
  }
}

@include forDarkTheme() {
  .carousel {
    &.withCustomScrollbar {
      // Chrome, Safari, Edge
      &::-webkit-scrollbar {
        background: transparent;
      }

      &::-webkit-scrollbar-track {
        background: $brandColorAlternate;
        border-color: $contentBackgroundColorDark;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $navigationColor;
      }
    }
  }
}
