@import 'OK/styles/util';

.label {
  margin-bottom: $contentMarginSm;
}

.value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child {
    margin-bottom: 0;
  }
}
