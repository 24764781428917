@import 'OK/styles/util';

.downloadButton {
  float: right;
  height: $minClickableHeight;
  justify-content: center;
  margin: 0;
  position: relative;
  right: -11px;
  top: -11px;
  width: $minClickableHeight;
}

.languageTag {
  margin-right: 8px;

  &.unilingual {
    @include fontDefault();
  }
}

.languageTags {
  display: flex;
  flex-wrap: wrap;
}
