@import 'OK/styles/util';

.container {
  border-radius: $inputBorderRadius;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  padding-top: calc(100% - 2px); // Account for border
  position: relative;
  width: 100%;

  &.error {
    border-color: $alertColor !important;
  }
}

.continueButton {
  margin-left: $buttonPadding;
}

.fileInput {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  // Invisible mode
  &.invisible {
    height: 0;
    width: 0;
  }
}

.label {
  margin: 0;
}

.layout {
  align-self: center;
  display: flex;
  flex-direction: column;
  height: calc(100% + 2px); // Add back border size for accurate height
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: calc(100% + 2px); // Add back border size for accurate width
}

.photoPreview {
  background-position: center;
  background-size: cover;
  border-radius: $inputBorderRadius;
}

.uploadIcon {
  height: 80px;
  margin-bottom: $contentMarginSm;
}

@include forLightTheme() {
  .container {
    border-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .container {
    border-color: $midtonesColorStaticDark;
  }
}
