@import 'OK/styles/util';

.actionButtonUpper {
  margin-top: -5px;
}

.captcha {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: $contentMarginMd;
}

.captchaAgree {
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  margin: 0 0 0 $contentMarginSm;
}

.captchaAgreeLink {
  margin: 0;
}

.catchaError {
  border-color: $alertColor;
}

.hcaptcha {
  font-size: 10px;
  line-height: 15px;
  margin: 4px 0 2px auto;
  text-align: center;

  a,
  p {
    margin: 0;
  }
}

.changeUsernameButton {
  height: inherit;
  line-height: inherit;
  margin: 0;
}

.container {
  width: 100%;

  .actionButton {
    margin: $contentMarginMd 0 $contentMarginSm;
  }

  :global(#captcha) {
    iframe {
      display: block;
      margin-left: -14px;
      transform: scale(0.9075907);
    }
  }

  .errorMessage {
    font-size: $fontSizeSm;
    line-height: $lineHeightSm;
    margin: -$contentMarginXs 0 (-$contentMarginXs);

    &.captchaError {
      margin-top: $contentMarginXs;
    }
  }

  .textXs {
    font-size: $fontSizeXs;
    line-height: $lineHeightXs;
    margin-bottom: 0;

    .link {
      display: inline;
      font-weight: bold;
      line-height: inherit;
    }
  }
}

.codeInput {
  height: auto;
  margin-bottom: $contentMarginSm;

  input {
    font-size: 2rem;
    font-weight: bold;
  }

  // Hide browser arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
  }

  input[type='number'] {
    appearance: textfield;
  }
}

.fineprint {
  margin-top: $contentMarginMd;
}

.inlineErrorText {
  color: $alertColorCard;
}

.input {
  margin-bottom: $contentMarginSm;
}

.inputLabel {
  margin-bottom: $contentMarginSm;
}

.instructions {
  margin-bottom: $contentMarginSm;
}

.header {
  display: flex;
  justify-content: space-between;
}

.link {
  margin-bottom: 0;
}

.linkButton {
  justify-content: flex-start;
  margin: 0 0 $contentMarginSm;
  min-width: 75%;
}

.username {
  overflow: hidden;
  text-overflow: ellipsis;
}

.switchModeButton {
  margin: 0;
}

@include forLightTheme() {
  .errorMessage {
    color: $alertColorCard;
  }
}

@include forDarkTheme() {
  .errorMessage {
    color: $alertColorCardDark;
  }
}
