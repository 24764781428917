@import 'OK/styles/util';

.card {
  border-style: solid;
  border-width: 1px;
}

.deleteButton {
  margin-bottom: 0;
}

.field {
  display: block;
  margin-bottom: $contentMarginMd;
}

.fieldLast {
  margin-bottom: 0;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;

  > * {
    margin-bottom: 0;
  }
}

.label {
  margin-bottom: $contentMarginSm;
}

.linkDisplayerContainer {
  > :last-child {
    > :last-child {
      display: none;
    }
  }
}

.normalWeight {
  font-weight: normal;
}

@include forLightTheme() {
  .card {
    border-color: transparent;

    &.unsaved {
      background-color: $contentBackgroundColor;
      border-color: $brandColor;
    }
  }
}

@include forDarkTheme() {
  .card {
    border-color: transparent;

    &.unsaved {
      background-color: $contentBackgroundColorDark;
      border-color: $brandColorAlternate;
    }
  }
}

.searchInputContainer {
  margin-bottom: $contentMarginMd;
  position: relative;
  z-index: 1;
}

.searchInputContainerPrimary {
  z-index: 3;
}

.searchInput {
  z-index: 3;
}

.searchInputPrimary {
  z-index: 4 !important;
}

.searchResults {
  margin-top: -$contentMarginXs;
  position: absolute;
  width: 100%;
  max-height: 150px;
  z-index: 2;
}

.searchResultsPrimary {
  z-index: 1;
  max-height: 215px;
}

.searchResultButton {
  margin-bottom: $contentMarginLg;
  height: $lineHeightMd;
  margin: 0;

  img {
    margin-left: $contentMarginSm;
    width: 1em;
  }
}

.seperatorMain {
  margin-top: 0;
}

.readOnlyInput {
  input {
    font-weight: normal !important;
  }
}

.publishLabel {
  margin: 0 auto 0 0;
}

.publishRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;
}

.publishedToggle {
  margin-left: $contentMarginSm;
}
