@use 'sass:color';
@import 'OK/styles/util';

.actionIndicator {
  &.alert {
    background-color: $alertColor;
  }

  &.creation {
    background-color: $creationColor;
  }

  &.navigation {
    background-color: $navigationColor;
  }

  &.notification {
    background-color: $notificationColor;
  }
}

.card {
  &.alert {
    background-color: $alertColor;
    color: $textColorLight;
  }
  &.creation {
    background-color: $creationColor;
    color: $textColorLight;
  }
  &.navigation {
    background-color: $navigationColor;
    color: $textColorLight;
  }
  &.notification {
    background-color: $notificationColor;
    color: $textColorLight;
  }
}

@include forLightTheme() {
  .footerRow {
    &:last-child {
      background: none;
    }
  }

  .mediaGalleryContainer {
    background-color: color.change($contentBackgroundColor, $alpha: 0.5);
  }

  .mediaOverlay {
    background-color: color.change($contentBackgroundColor, $alpha: 0.5);
  }
}

@include forDarkTheme() {
  .footerRow {
    &:last-child {
      background: none;
    }
  }

  .mediaGalleryContainer {
    background-color: color.change($contentBackgroundColorDark, $alpha: 0.5);
  }

  .mediaOverlay {
    background-color: color.change($contentBackgroundColorDark, $alpha: 0.5);
  }
}
