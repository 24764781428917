@import 'OK/styles/util';

.cancelButton {
  margin-left: auto;
}

.container {
  @include forMinScreenWidth(medium) {
    max-width: 405px;
  }
}

.header {
  margin-bottom: $contentMarginLg;
}

.headerCreateButton {
  margin-left: $contentMarginMd;
}

.headerRow {
  display: flex;
}

.instructions {
  @include forMinScreenWidth(medium) {
    width: 247px;
  }
}

.label {
  margin-bottom: $contentMarginSm;
}

.scanInputButton {
  margin-right: 15px !important;
}

.skuInput {
  @include fontMono();
  margin-bottom: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    margin-bottom: 0;
  }
}

.unbold {
  font-weight: normal;
}
