@import 'OK/styles/util';

.additionalOption {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: $contentMarginSm;

  &:last-child {
    margin-bottom: 0;
  }
}

.creationError {
  margin: -15px 0 15px;
}

.errorMessage {
  margin: -15px 0 15px 0;
}

.field {
  margin-bottom: $contentMarginMd;
}

.fieldHeader {
  margin-bottom: $contentMarginSm;
}

.inspectionLinkError {
  margin: $contentMarginXs 0 0 0;
}

.inspectionRefId {
  height: 28px;
  line-height: 28px !important;
  margin: 0 $contentMarginSm $contentMarginSm 0;

  &:last-child {
    margin: 0 0 $contentMarginSm 0;
  }
}

.inspectionsSection {
  margin-top: $contentMarginMd;
  overflow: visible;
}

.layoutColumn {
  flex: 0 0 50%;

  @include forMinScreenWidth(medium) {
    width: 50%;
  }
}

.layoutContainer {
  width: 100%;

  @include forMinScreenWidth(medium) {
    display: flex;
  }
}

.mediaError {
  margin: $contentMarginXs 0 15px 0;
}

.mediaGallery {
  margin: 0 0 $contentMarginMd;
}

.mediaGalleryContainer {
  margin-bottom: $contentMarginMd;
}

.normalWeight {
  font-weight: normal;
}

.notice {
  margin-bottom: $contentMarginMd;
}

.optionCheckbox {
  display: inline-block;
  margin-right: $contentMarginSm;
}

.optionError {
  margin: -$contentMarginXs 0 $contentMarginSm;
}

.refIds {
  margin-bottom: $contentMarginSm;
}

.saveButton {
  margin-left: $contentMarginMd;
}

.searchMediaButton {
  margin-bottom: $contentMarginLg;
}

.searchContainer {
  position: relative;
}

.searchInput {
  z-index: 1;
}

.searchResults {
  margin-top: -$contentMarginXs;
  position: absolute;
  width: 100%;
}

.searchResultButton {
  height: $lineHeightMd;
  margin: 0;

  img {
    margin-left: $contentMarginSm;
    width: 1em;
  }
}

.searchResultOKID {
  @include fontMono();
}

.searchSuggestionAction {
  height: $lineHeightMd;
  margin: 0;
}

.separator {
  margin: $contentMarginLg $contentMarginMd;
}

.severityContainer {
  margin-bottom: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    margin-bottom: 0;
  }
}

.severityDescription {
  margin: 0;
}

.severityError {
  margin-bottom: 15px;
}

.severityRadio {
  margin-bottom: $contentMarginMd;

  &:last-child {
    margin-bottom: 0;
  }
}

span.severityIcon {
  @include fontDefault();
  line-height: 22px !important;
  margin-right: $contentMarginXs;
  text-align: center;
  width: 30px;
}

.severityLevel {
  margin-bottom: $contentMarginSm;
}
