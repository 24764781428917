@import 'OK/styles/util';

.filePreviewContainer {
  overflow: hidden;
  position: relative;

  &.noPreview {
    align-items: center;
    display: flex;
    justify-content: center;

    > p {
      margin: 0;
    }
  }
}

@include forLightTheme() {
  .filePreviewContainer {
    background-color: $contentBackgroundColor;

    &.noPreview {
      background-color: $midtonesColorStatic;
    }
  }
}

@include forDarkTheme() {
  .filePreviewContainer {
    background-color: $contentBackgroundColorDark;

    &.noPreview {
      background-color: $midtonesColorStaticDark;
    }
  }
}
