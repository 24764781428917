@import 'OK/styles/util';

.cancelButton {
  margin: $contentMarginMd auto $contentMarginSm $contentMarginMd;
  pointer-events: auto;
}

.confirmButton {
  margin: $contentMarginMd $contentMarginMd $contentMarginSm;
  pointer-events: auto;
  position: absolute;
}

.container {
  padding-top: 0 !important;
  position: relative;
}

.instructions {
  font-weight: bold;
  margin: 0 auto;
  padding: 2px $contentMarginSm;
  top: $contentMarginXxl;
}

.overlay {
  align-items: flex-end;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-in;
}

.zoomContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  padding: $contentMarginMd 0 0 $contentMarginMd;
  pointer-events: auto;
  position: absolute;
  top: 0;
  width: $minClickableHeight + $contentMarginMd;
}

.zoomSlider {
  height: 16px;
  margin: $contentMarginXl -25px;
  transform: rotate(270deg);
  width: 65px;
}

@include forLightTheme() {
  .instructions {
    background-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .instructions {
    background-color: $midtonesColorStaticDark;
  }
}
