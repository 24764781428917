// doiuse-disable calc

@import 'OK/styles/util';

.containerInner {
  display: flex;
  flex-direction: column;
}

.field {
  display: flex;
  flex-direction: row;
  margin-bottom: $contentMarginSm;
}

.fieldName {
  flex-shrink: 0;
  margin: 0 auto 0 0;
}

.fieldValue {
  margin: 0 0 0 $contentMarginSm;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.linkToDownload {
  width: 100%;
  margin-bottom: 0;
}

.revisionButton {
  margin-bottom: 0;
}

.addLanguageButton {
  margin-top: $contentMarginSm;
}

.attachment {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.attachmentIcon {
  margin-right: $contentMarginSm;
  position: relative;
  top: -1px;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 $contentMarginMd 0;
}

.languagePointsTooltip {
  color: $textColorLight !important;
  font-size: $fontSizeMd;
  font-weight: bold;
  line-height: $lineHeightMd;
  padding: 2px 5px;
  position: absolute;
  right: 0;
  top: -30px;
  z-index: 1;

  &:before {
    left: calc(50% - 6px);
  }
}

.normalWeight {
  font-weight: normal;
}

.standardName {
  margin-top: $contentMarginSm;
  margin-bottom: $contentMarginSm;
}

p.points {
  margin-right: $contentMarginSm;
}

.publishError {
  margin: -15px 0 15px;
}

p.publishLabel {
  margin-right: auto;
}

.publishNotice {
  font-size: $fontSizeMd !important;
  line-height: $lineHeightMd !important;
  margin-bottom: $contentMarginMd !important;
  margin-top: 0 !important;

  p {
    margin-bottom: 0 !important;
  }
}

.publishRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginSm;
}

.publishStatusIcon {
  margin-right: $contentMarginSm;
}

.refId {
  float: right;
  position: relative;
  top: 3px !important;
}

.manageButton {
  margin-bottom: 0;
  margin-top: $contentMarginSm;
}

.manageButtons {
  margin-top: auto;
}

.title {
  margin: 0 $contentMarginSm 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unlinkError {
  margin: $contentMarginXs 0 0;
}
