@import 'OK/styles/util';

.content {
  flex: 1;
  
  &.padBottom {
    padding-bottom: $contentMarginHuge;
  }

  &.padTop {
    padding-top: $contentMarginHuge;
  }

  &.padTopMenuOnly {
    padding-top: $mainMenuHeight;
  }
}
