@use 'sass:color';
@import 'OK/styles/util';

.availableInspection {
  cursor: pointer;
}

.availableInspectionCard {
  width: 100%;
}

.availableInspectionSlide {
  margin-bottom: $contentMarginMd;

  &:last-child {
    margin-bottom: 0;
  }

  @include forMinScreenWidth(medium) {
    margin-bottom: 0;
    margin-right: $contentMarginMd;

    &:first-child {
      margin-left: $contentMarginLg;
    }

    &:last-child {
      .availableInspection {
        margin-right: $contentMarginLg !important;
      }
    }
  }
}

.availableInspections {
  margin-top: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    padding-left: $contentMarginMd;
    padding-right: $contentMarginMd;
  }
}

.availableInspectionsCarousel {
  @include forMinScreenWidth(medium) {
    margin-left: -$contentMarginLg;
    margin-right: -$contentMarginLg;
  }

  @include overlayScrollbarsDefaultScrollbarPadding();
}

.availableInspectionsCarouselInner {
  display: block !important;

  @include forMinScreenWidth(medium) {
    display: flex !important;
  }
}

.contactInputs {
  margin-right: $contentMarginMd;
}

.contactSection {
  margin-top: $contentMarginLg;
  padding: 0 $contentMarginLg;

  > label {
    display: block;
    margin-top: $contentMarginMd;
  }
}

.desktopContactAndMediaInputs {
  display: flex;
  margin-top: $contentMarginMd;
}

.desktopSeparator {
  margin: $contentMarginLg $contentMarginMd;
}

.detailedReportLink {
  margin: 0;
}

.documentationContainer {
  margin-top: $contentMarginLg;
  padding: 0 $contentMarginSm;
}

.documentationTypeButtons {
  margin-bottom: $contentMarginMd;
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: auto;
  }
}

.errorMessage {
  margin: -15px 0 15px;
}

.feedback {
  height: 150px;
}

.feedbackButton {
  margin: 0;
}

.feedbackNotice {
  margin: $contentMarginMd (-$contentMarginSm);
}

.gradeExplanations {
  flex: 1 1 50%;
}

.gradingAccordionContent {
  @include forMinScreenWidth(medium) {
    display: flex;
  }
}

.gradingExplanationContainer {
  background-color: $creationColor;
  color: $textColorLight;
  padding: $contentMarginLg 0;

  p {
    margin-bottom: $contentMarginSm;
  }
}

.gradingExplanationContent {
  max-width: none;
}

.gradingExplanationHeader {
  &:not(:first-child) {
    margin-top: $contentMarginMd;
  }

  svg {
    margin-right: $contentMarginSm;
  }
}

.gradingExplanationIntro {
  @include forMinScreenWidth(medium) {
    order: 2;
  }
}

.gradingExplanationIntroContainer {
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;

  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginLg * 2;
  }
}

.gradingExplanationParagraph {
  margin-left: 26px;
}

.gradingExplanationToggleButton {
  color: $textColorLight !important;

  &:hover {
    color: $textColorLightHover !important;
  }

  &:active {
    color: $textColorLightActive !important;
  }
}

.inspectionsSection {
  padding: $contentMarginLg $contentMarginSm $contentMarginXl;

  @include forMinScreenWidth(medium) {
    padding-bottom: $contentMarginLg;
  }
}

.inspectionTutorialSection {
  margin-bottom: $contentMarginMd;

  p {
    margin-bottom: $contentMarginSm;
  }

  // stylelint-disable-next-line order/order
  @include forMinScreenWidth(medium) {
    display: flex;
  }
}

.inspectionTutorialText {
  order: 2;

  @include forMinScreenWidth(medium) {
    margin-left: $contentMarginLg * 2;
  }
}

.label {
  margin-bottom: $contentMarginSm;
}

.loadingSpinner {
  margin-top: $contentMarginXxl;
}

.mapComingSoon {
  background-image: url('/img/visual_supply_chain_coming_soon.jpg');
  background-position: center center;
  background-size: cover;
  font-size: $fontSizeCardHeader;
  font-weight: bold;
  height: 250px;
  line-height: 250px;
  text-align: center;

  p {
    margin: 0;
  }
}

.mediaPickerContainer {
  flex: 1 1;

  @include forMinScreenWidth(medium) {
    flex: 0 0 245px;
  }
}

.noProductFoundContainer {
  margin-top: $contentMarginLg;
}

.phoneInputLabel {
  @include forMinScreenWidth(medium) {
    display: block;
    margin: 0 0 $contentMarginMd 0 !important;
  }
}

.popupContent {
  padding: 0 0 $contentMarginXxl 0;
}

.privacyPolicyCheckbox {
  display: inline-block !important;
  height: 16px !important;
  margin: 0 $contentMarginSm 0 0;
  position: relative;
  top: 2px;
  width: 16px !important;
}

.privacyPolicyLink {
  margin: 0;
}

.productDocument {
  margin: 0 $contentMarginMd $contentMarginMd;

  &:last-child {
    margin-bottom: 0;
  }

  @include forMinScreenWidth(medium) {
    display: inline-block;
    width: 247px;
  }
}

.productNote {
  height: 100%;
}

.productNoteSlide {
  margin: 0 $contentMarginXs;

  &:first-child {
    margin-left: $contentMarginSm;
  }

  &:last-child {
    .productNote {
      display: inline-block;
      margin-right: $contentMarginSm;
    }
  }
}

.productNotesCarousel {
  margin-left: -$contentMarginSm;
  margin-right: -$contentMarginSm;

  @include overlayScrollbarsDefaultScrollbarPadding();
}

.video {
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  margin: $contentMarginMd 0;

  @include forMinScreenWidth(medium) {
    flex: 0 0 247px;
    height: 247px !important;
    margin: 0 0 $contentMarginMd 0;
    order: 1;
    padding-top: 0 !important;
    width: 247px;
  }
}

@include forLightTheme() {
  .popupContent {
    border-top-color: $brandColor;
  }

  .video {
    border-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .popupContent {
    border-top-color: $brandColorAlternate;
  }

  .video {
    border-color: $midtonesColorStaticDark;
  }
}
