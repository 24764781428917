@import 'OK/styles/util';

.abortButton {
  align-self: flex-start;
  border-radius: 2px;
  flex: 0 0;
  font-size: $fontSizeXs;
  line-height: $lineHeightXs;
  margin: auto 0 $contentMarginXs;
  padding: 2px $contentMarginXs;

  @include increaseClickableArea(10px, 0);
}

.container {
  &.linked {
    cursor: pointer;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.logs {
  margin-bottom: 0;
}

.logsTag {
  @include fontDefault();
}

.miniGallery {
  align-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 18px;
  width: 138px;
  margin-bottom: $contentMarginXs;
}

.miniGalleryImage {
  border-radius: 2px;
  height: 18px;
  margin: 0 $contentMarginXs $contentMarginXs 0;
  overflow: hidden;
  position: relative;
  width: 18px;
}

.nameText {
  height: $lineHeightMd !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0 !important;
}

.seeAllLogsLink {
  font-size: $fontSizeXs;
  font-weight: bold;
  line-height: $lineHeightXs;
  margin: 0 0 0 $contentMarginXs;
  position: relative;
  top: -1px;
}

.titleAccessoryText {
  display: inline-block;
  margin: 0;
}
