@import 'OK/styles/util';

.logMetaDataHeader {
  display: inline-block;
  font-weight: bold;
  width: 90px;

  @include forMinScreenWidth(medium) {
    display: block;
  }
}

.logMetaDataRow {
  @include forMinScreenWidth(medium) {
    margin-bottom: $contentMarginSm;
  }
}

.logMetaDataValue {
  display: inline-block;

  @include forMinScreenWidth(medium) {
    display: block;
  }
}

.logRelationButtons {
  margin: $contentMarginLg 0;
  width: 100%;
}

.logRelationCard {
  width: 100%;
}

.logRelationCardInner {
  width: 100%;
}

.logRelationsContainer {
  display: flex;
  margin-top: calc(#{$lineHeightCardHeader} + #{$contentMarginMd});

  > div {
    margin-right: $contentMarginMd;

    &:last-child {
      margin-right: 0;
    }
  }
}

.noticeHeader {
  margin-bottom: 0 !important;
}

.okidLink {
  margin: 0;
}

.placeTag {
  margin-left: $contentMarginXs;
}

.placeTagOrder {
  margin-left: 0;
}

.section {
  padding-bottom: $contentMarginXxl;
  padding-top: $contentMarginXl;
}

.summaryColumn {
  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginMd;
    flex: 1 1 $cardWidth4ColumnDesktop + 2px;
    max-width: 240px;
  }
}

.summaryContents {
  @include forMinScreenWidth(medium) {
    display: flex;
  }
}

.summaryNotice {
  margin-bottom: $contentMarginMd;
  padding: $contentMarginMd $contentMarginMd $contentMarginLg;

  @include forMinScreenWidth(medium) {
    margin-left: 0;
    margin-right: 0;
  }
}

.summarySection {
  padding: $contentMarginLg 0 $contentMarginXxl;
}
