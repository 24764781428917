@import 'OK/styles/util';

.mainContent {
  display: flex;
  flex-direction: column;
}

.tag {
  margin-right: $contentMarginXs;
}

.tagRow {
  margin-bottom: 0;
}

.title {
  margin-bottom: auto;
}
