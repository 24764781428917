@use "sass:math";

@import 'OK/styles/util';

.basicsContainer {
  padding-bottom: $contentMarginXxl;
  padding-top: $contentMarginLg;
}

.commentSection {
  padding-bottom: $contentMarginXl;
}

.commentSectionHeader {
  margin-bottom: $contentMarginLg;
  padding-top: $contentMarginLg;
  border-top-width: 1px;
  border-top-style: solid;
}

.floatingButtons {
  position: sticky;
  bottom: 0 !important;
  margin-top: -$contentMarginMd;
}

@include forLightTheme() {
  .commentSectionHeader {
    border-color: $midtonesColorStatic;
  }

  .commentSection {
    background-color: $contentBackgroundColor;
  }
}

@include forDarkTheme() {
  .commentSectionHeader {
    border-color: $midtonesColorStaticDark;
  }

  .commentSection {
    background-color: $contentBackgroundColorDark;
  }
}

.deleteFileButton {
  margin-bottom: $contentMarginSm;
}

.field {
  margin-bottom: $contentMarginMd;

  &:last-child {
    margin-bottom: 0;
  }
}

.fieldHeader {
  margin-bottom: 0;
}

.fieldValue {
  margin-bottom: 0;
}

.layoutContainer {
  padding-top: $mainMenuHeight + ($minClickableHeight * 2);
}

.linksSection {
  padding-bottom: $contentMarginSm !important;
}

.mediaDetailsContainer {
  flex: 1 0;
}

.mediaInfoContainer {
  @include forMinScreenWidth(medium) {
    display: flex;
  }
}

.mediaPicker {
  margin-bottom: $contentMarginLg;

  @include forMinScreenWidth(medium) {
    margin-bottom: 0;
    margin-right: $contentMarginMd;
  }
}

.mediaPreviewContainer {
  border-radius: $cardBorderRadius;
  margin-bottom: $contentMarginLg;
  overflow: hidden;

  @include forMinScreenWidth(medium) {
    margin-bottom: 0;
    margin-right: $contentMarginMd;
  }
}

.popupMenuBackground {
  position: fixed;
  z-index: 147;
  background-color: rgba(244, 244, 244, 1);
}

.topBorderPopup {
  background-color: transparent;
}

.popupMenu {
  padding-left: 0;
  position: fixed;
  z-index: 148;
  background-color: white;
  transition: 100ms all;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.refId {
  font-size: $fontSizeCardHeader !important;
  line-height: $lineHeightCardHeader !important;
  margin-left: $contentMarginSm;
  position: relative;
  top: -2px;
}

.reliabilityScore {
  flex: 0 0 !important;
}

.replaceMediaButton {
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: 247px;
  }
}

.replaceMediaError {
  margin-bottom: 15px;
  margin-top: -15px;
}

.shareToast {
  left: -(math.div(160px - $minClickableHeight, 2));
  position: absolute;
  text-align: center;
  top: $contentMarginXs;
  width: 160px;

  @include forMinScreenWidth(medium) {
    left: -(math.div(160px - 69px, 2));
  }
}

.submenu {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.submenuButton {
  flex: 1 0 $minClickableHeight;
  margin-bottom: 0;

  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginMd;
  }
}

.submenuButtonContainer {
  position: relative;

  button {
    margin-bottom: 0;
  }
}

.submenuButtons {
  align-items: center;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  height: 24px;
  margin-right: $contentMarginMd;
  padding-right: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginMd;
    padding-right: 0;
  }
}

.submenuRefId {
  margin-left: $contentMarginXs;
}

.submenuTitle {
  margin: 0 auto 0 0;
}

@include forLightTheme() {
  .basicsContainer {
    background-color: $contentBackgroundColorCard;
  }

  .pageSubmenu {
    background-color: $contentBackgroundColor;
  }

  .submenuButtons {
    border-right-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .basicsContainer {
    background-color: $contentBackgroundColorCardDark;
  }

  .pageSubmenu,
  .popupMenu,
  .popupMenuBackground {
    background-color: $contentBackgroundColorDark;
  }

  .popupMenu {
    background-color: $contentBackgroundColorDark;
    border-left: 1px solid $midtonesColorStaticDark;
    border-right: 1px solid $midtonesColorStaticDark;
    box-shadow: none !important;
  }

  .popupMenu {
    border-top: 1px solid $midtonesColorStaticDark;
  }

  .submenuButtons {
    border-right-color: $midtonesColorStaticDark;
  }
}
