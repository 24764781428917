@use 'sass:color';
@import 'OK/styles/util';

.closeVideoButton {
  margin: $contentMarginLg 0 0 0;
}

.container {
  background-color: color.change($brandColor, $alpha: 0.9);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 150;
}

.contentContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: auto;
  max-width: $contentMaxWidthMobile;
  padding: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    max-width: $contentMaxWidthDesktop;
  }
}
