@import 'OK/styles/util';

.label {
  margin-bottom: $contentMarginSm;
  flex-shrink: 0;
  margin: 0 auto 0 0;
}

.value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0 0 $contentMarginSm;

  &:last-child {
    margin-bottom: 0;
  }
}

.field {
  display: flex;
  flex-direction: row;
  margin-bottom: $contentMarginSm;
}

.standardName {
  margin-top: $contentMarginSm;
  margin-bottom: $contentMarginSm;
}
