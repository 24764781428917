@use "sass:math";

@import 'OK/styles/util';

$aggregateChartWidth: 110px;

.allLogsChartContainer {
  height: 100%;
  width: $aggregateChartWidth;
}

.basicsContainer {
  padding-bottom: $contentMarginXxl;
  padding-top: $contentMarginLg;

  :global(#chartLegendContainer) {
    margin-bottom: $contentMarginMd;
  }
}

.chartDisplayTypeButtons {
  margin-bottom: $contentMarginMd;
}

.chartHeaders {
  align-items: center;
  display: flex;
  margin-bottom: $contentMarginMd;

  > * {
    margin-bottom: 0;
  }
}

.chartDisplayTypeButton {
  border-radius: 2px;
  font-size: $fontSizeXs;
  font-weight: bold;
  line-height: 30px;
  height: 30px;
  margin-right: $contentMarginSm;
  padding: $contentMarginXs $contentMarginSm;
}

.chartFilterChip {
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  margin-right: $contentMarginSm;
}

.chartFilterDropdowns {
  display: flex;
  flex-direction: column;

  @include forMinScreenWidth(medium) {
    flex-direction: row;
  }
}

.chartFilterLabel {
  margin-bottom: $contentMarginSm;
}

.chartFilterSection {
  margin-bottom: $contentMarginMd;
  width: 100%;

  &:first-child {
    margin-right: $contentMarginLg * 2;
  }

  @include forMinScreenWidth(medium) {
    width: 50%;
  }
}

.chartFilters {
  margin-bottom: $contentMarginLg;
}

.chartScaleIcon {
  margin-left: $contentMarginMd;
  width: 16px;
}

.chartsContainer {
  display: flex;
  height: 200px;
  margin-bottom: 0;
}

.floatingButtons {
  position: sticky;
  bottom: 0 !important;
  margin-top: -70px;
}

.header {
  margin-bottom: $contentMarginLg;
}

.latestLogsChartContainer {
  height: 100%;
  width: calc(100% - $aggregateChartWidth);
}

.latestLogsChartHeader {
  margin-right: auto;
}

.layoutContainer {
  padding-top: $mainMenuHeight + ($minClickableHeight * 2);
}

.noRecentLogsMessage {
  margin-bottom: 0;
  margin-top: $contentMarginXs;
}

.refId {
  font-size: $fontSizeCardHeader !important;
  line-height: $lineHeightCardHeader !important;
  margin-left: $contentMarginSm;
  position: relative;
  top: -2px;
}

.reliabilityScore {
  font-weight: bold;
}

.searchContainer {
  position: relative;
  z-index: 1;
}

.searchInput {
  z-index: 1;
}

.searchSuggestions {
  left: 0;
  position: absolute;
  top: $inputHeight - 5px;
  width: 100%;
}

.shareToast {
  left: -(math.div(160px - $minClickableHeight, 2));
  position: absolute;
  text-align: center;
  top: $contentMarginXs;
  width: 160px;

  @include forMinScreenWidth(medium) {
    left: -(math.div(160px - 69px, 2));
  }
}

.submenu {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.submenuButton {
  flex: 1 0 $minClickableHeight;
  margin-bottom: 0;

  @include forMinScreenWidth(medium) {
    &:not(:last-child) {
      margin-right: $contentMarginMd;
    }
  }
}

.submenuButtonContainer {
  position: relative;

  button {
    margin-bottom: 0;
  }
}

.submenuButtons {
  align-items: center;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  height: 24px;
  margin-right: $contentMarginMd;
  padding-right: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    padding-right: 0;
  }
}

.submenuRefId {
  margin-left: $contentMarginXs;
}

.submenuTitle {
  margin: 0 auto 0 0;
}

.testDetailsColumn {
  @include forMinScreenWidth(medium) {
    flex: 1 1 !important;

    &:first-child {
      margin-right: $contentMarginMd * 2;
      max-width: 400px;
    }
  }
}

@include forLightTheme() {
  .basicsContainer {
    background-color: $contentBackgroundColorCard;
  }

  .submenuButtons {
    border-right-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .basicsContainer {
    background-color: $contentBackgroundColorCardDark;
  }

  .submenuButtons {
    border-right-color: $midtonesColorStaticDark;
  }
}
