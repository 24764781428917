@import 'OK/styles/util';

.button {
  font-size: $fontSizeXs;
  font-weight: bold;
  height: 30px;
  line-height: $lineHeightXs;
  margin-right: 15px;
  padding: 0;
  text-align: center;
  width: 30px;
}

.container {
  position: relative;

  &.withFooter {
    padding-bottom: $lineHeightXs;
  }
}

.footer {
  border-radius: 0 0 $cardBorderRadius $cardBorderRadius;
  padding: $contentMarginXs $contentMarginMd;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  p {
    margin: 0;
  }
}

.languageButtons {
  margin-top: $contentMarginMd;
}

.text {
  margin: 0;
}

.toggleButton {
  display: flex;
}

.toggleButtonIcon {
  position: relative;
  top: -2px;
  transform: rotate(90deg);
  transition: all $timingShort ease;

  &.open {
    top: 4px;
    transform: rotate(-90deg);
  }
}

@include forLightTheme() {
  .button {
    background-color: $midtonesColorStatic;

    &.active {
      background-color: $navigationColor;
      color: $textColorLight;
    }
  }

  .footer {
    background-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .button {
    background-color: $midtonesColorStaticDark;

    &.active {
      background-color: $navigationColor;
      color: $textColorLight;
    }
  }

  .footer {
    background-color: $midtonesColorStaticDark;
  }
}
