@import 'OK/styles/util';

.confirmDeletionLabel {
  margin-bottom: $contentMarginSm;
}

.detailHeader {
  margin-bottom: 0;
}

.detailsContainer {
  @include forMinScreenWidth(medium) {
    margin-left: $contentMarginLg * 2;
    padding-top: $contentMarginMd;
  }
}

.deleteLabelButton {
  margin: 0;
}

.editLableButton {
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: 247px;
  }
}

.feature {
  margin: 0;
}

.floatingButtons {
  position: sticky;
  bottom: 0 !important;
  margin-top: -$contentMarginMd;
}

.headerContainer {
  padding-top: $contentMarginLg + ($minClickableHeight * 2);
}

.headerContainerPopup {
  padding-top: $contentMarginMd;
}

.headerRefId {
  font-size: $fontSizeCardHeader !important;
  line-height: $lineHeightCardHeader !important;
  margin-left: $contentMarginSm;
  position: relative;
  top: -3px;
}

.itemCard {
  margin-bottom: 0;
}

.itemSlide {
  margin-left: $contentMarginSm;
  margin-right: $contentMarginSm;

  &:first-child {
    margin-left: $contentMarginLg;
  }

  &:last-child {
    > * {
      margin-right: $contentMarginLg;
    }
  }

  @include forMinScreenWidth(medium) {
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
      > * {
        margin-right: 0;
      }
    }
  }
}

.itemsCarouselContainer {
  margin: 0 (-$contentMarginLg);

  @include forMinScreenWidth(medium) {
    margin-left: 0;
    margin-right: 0;
  }

  @include overlayScrollbarsDefaultScrollbarPadding();
}

.languageTag {
  margin: $contentMarginSm 0 $contentMarginMd;
}

.linksMenuContainer {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: $contentMarginLg;
  padding-top: $contentMarginLg;
}

.linksTab {
  font-weight: normal !important;
}

.linksTabs {
  margin-left: -7px;
}

.pageHeader {
  margin: 0;
}

.popupMenuBackground {
  position: fixed;
  z-index: 147;
  background-color: rgba(244, 244, 244, 1);
}

.topBorderPopup {
  background-color: transparent;
}

.popupMenu {
  padding-left: 0;
  position: fixed;
  z-index: 148;
  background-color: white;
  transition: 100ms all;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.preview {
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  height: 0;
  margin-bottom: $contentMarginLg;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  width: 100%;

  @include forMinScreenWidth(medium) {
    height: 400px;
    margin-bottom: 0;
    padding-top: 0;
    width: 400px;
  }
}

.submenuButton {
  margin: 0;

  @include forMinScreenWidth(medium) {
    margin: 0 0 0 $contentMarginMd;
  }
}

.submenuButtons {
  margin-left: auto;
  margin-top: $contentMarginMd;
}

.submenuRefId {
  margin-left: $contentMarginXs;
}

.submenuTitle {
  margin: 0;
}

.templateContainer {
  padding: $contentMarginMd 0 $contentMarginXxl;
}

.templateInfoContainer {
  @include forMinScreenWidth(medium) {
    display: flex;
  }
}

@include forLightTheme() {
  .headerContainer,
  .templateContainer {
    background-color: $contentBackgroundColorCard;
  }

  .linksMenuContainer {
    border-color: $midtonesColorStatic;
  }

  .preview {
    background-color: $midtonesColorStatic;
    border-color: $midtonesColorStatic;
  }

  .pageSubmenu {
    background-color: $contentBackgroundColor;
  }
}

@include forDarkTheme() {
  .headerContainer,
  .templateContainer {
    background-color: $contentBackgroundColorCardDark;
  }

  .linksMenuContainer {
    border-color: $midtonesColorStaticDark;
  }

  .preview {
    background-color: $midtonesColorStaticDark;
    border-color: $midtonesColorStaticDark;
  }

  .pageSubmenu,
  .popupMenu,
  .popupMenuBackground {
    background-color: $contentBackgroundColorDark;
  }

  .popupMenu {
    background-color: $contentBackgroundColorDark;
    border-left: 1px solid $midtonesColorStaticDark;
    border-right: 1px solid $midtonesColorStaticDark;
    box-shadow: none !important;
  }

  .popupMenu {
    border-top: 1px solid $midtonesColorStaticDark;
  }
}
