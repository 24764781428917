@import 'OK/styles/util';

.tooltip {
  border-radius: 2px;
  display: inline-block;
  font-size: $fontSizeXs;
  font-weight: bold;
  padding: $contentMarginXs $contentMarginSm;
  position: absolute;

  &::before {
    border: 6px solid transparent;
    content: '';
    height: 0;
    left: calc(50% - 3px);
    position: absolute;
    width: 0;
  }

  &.above {
    &::before {
      top: 100%;
    }
  }

  &.below {
    &::before {
      top: -11px;
    }
  }
}

.tooltipContainer {
  position: relative;

  &.bounceDown {
    animation: bounceDown 4s linear 1s infinite;
  }

  &.bounceUp {
    animation: bounceUp 4s linear 1s infinite;
  }
}

@keyframes bounceUp {
  0% {
    top: 0;
  }

  2% {
    top: -5px;
  }

  5% {
    top: 0;
  }

  8% {
    top: -3px;
  }

  11% {
    top: 0;
  }
}

@keyframes bounceDown {
  0% {
    top: 0;
  }

  2% {
    top: 5px;
  }

  5% {
    top: 0;
  }

  8% {
    top: 2px;
  }

  11% {
    top: 0;
  }
}
