@import 'OK/styles/util';

.activeToolButton {
  position: relative;

  &::after {
    background-color: $navigationColor;
    bottom: 0;
    content: '';
    height: 2px;
    left: 4px;
    position: absolute;
    right: 4px;
  }
}

.backButton {
  margin-right: auto;
}

.backIcon {
  transform: rotate(180deg);
}

.cancelAlert {
  button {
    margin: 0;
  }
}

.discardChangesButton {
  margin-bottom: $contentMarginSm;
}

.doneButton {
  position: absolute;
  right: 30px;
  top: 10px;
}

.fakeMedia,
.selectedMediaContainer {
  height: 335px;
  position: relative;
  width: 335px;

  @include forMinScreenWidth(medium) {
    height: 423px;
    width: 423px;
  }
}

.floatingButton {
  margin-top: 20px;
}

.instructions {
  bottom: 20px;
  left: 30px;
  margin: 0;
  position: absolute;
}

.mediaPreviewContainer {
  position: relative;
}

.menu {
  border-radius: $cardBorderRadius $cardBorderRadius 0 0;
  display: flex;
  height: $minClickableHeight;
  padding: 0 15px;

  button {
    margin-bottom: 0;
    width: $minClickableHeight;
  }
}

.popupContent {
  border-top-width: 0;
  padding: 0;
  width: $mediaPopupMaxWidthMobile;

  @include forMinScreenWidth(medium) {
    width: $mediaPopupMaxWidthDesktop;
  }
}

.thumbnail {
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  flex: 0 0 38px;
  height: 38px;
  scroll-snap-align: center;
  width: 38px;

  &.active {
    position: relative;

    &::before {
      border-radius: 2px;
      border-style: solid;
      border-width: 1px;
      bottom: -3px;
      content: '';
      left: -3px;
      position: absolute;
      right: -3px;
      top: -3px;
    }
  }
}

.thumbnailMedia {
  border-radius: 2px;
}

.thumbnails {
  overflow: hidden;
}

.thumbnailsCarousel {
  padding: 20px 0;
}

.toolContainer {
  position: relative;
}

.toolUIContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@include forLightTheme() {
  .doneButton {
    color: $textColorLight;
    text-shadow: #000 0 0 6px;
  }

  .fakeMedia {
    background-color: $midtonesColorStatic;
  }

  .instructions {
    color: $textColorLight;
    text-shadow: #000 0 0 10px;
  }

  .menu {
    background-color: $contentBackgroundColor;
  }

  .thumbnail {
    background-color: $midtonesColorStatic;
    border-color: $midtonesColorStatic;

    &.active::before {
      border-color: $brandColor;
    }
  }
}

@include forDarkTheme() {
  .doneButton {
    color: $textColor;
    text-shadow: #fff 0 0 6px;
  }

  .fakeMedia {
    background-color: $midtonesColorStaticDark;
  }

  .instructions {
    color: $textColor;
    text-shadow: #fff 0 0 10px;
  }

  .menu {
    background-color: $contentBackgroundColorDark;
  }

  .thumbnail {
    background-color: $midtonesColorStaticDark;
    border-color: $midtonesColorStatic;

    &.active::before {
      border-color: $brandColorAlternate;
    }
  }
}
