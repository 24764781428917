@import 'OK/styles/util';

@include forLightTheme() {
  .chip {
    background-color: $contentBackgroundColorCard;
    color: $textColor;

    &.selected {
      background-color: $navigationColor;
      color: $textColorLight;
    }
  }
}

@include forDarkTheme() {
  .chip {
    background-color: $contentBackgroundColorCardDark;
    color: $textColorLight;

    &.selected {
      background-color: $navigationColor;
      color: $textColor;
    }
  }
}
