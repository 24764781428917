@use 'sass:color';
@import 'OK/styles/util';

.addMediaButton {
  background-image: url('/icons/plus_green.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.audioThumbnail {
  background-position: center;
  background-repeat: no-repeat;
}

.editingButtons {
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: $contentMarginMd;
  position: absolute;
  top: 0;
  width: 100%;

  button {
    margin: 0;
  }
}

.errorMessage {
  margin: $contentMarginXs 0 15px 0;
}

.gallery {
  overflow: hidden;
  position: relative;

  &.withMediaDetails {
    .previews {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.imageContainer {
  flex: 0 0 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  width: 100%;
}

.mediaInfo {
  padding: $contentMarginMd;

  &.bottomRounded {
    border-bottom-left-radius: $cardBorderRadius;
    border-bottom-right-radius: $cardBorderRadius;
  }
}

.mediaLoadingSpinner {
  position: absolute;
  width: 50px;
  top: 20%;
  left: 35%;
  z-index: 1000;
}

.mediaRefId {
  margin-left: $contentMarginSm;
}

.mediaSlide {
  flex: 0 0 100%;
}

.mediaType {
  display: inline-block;
}

.navigationControls {
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: calc(50% - 22px) $contentMarginMd 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;

  button {
    height: $minClickableHeight;
    padding: 0;
    pointer-events: auto;
    transition: 100ms opacity;
    width: $minClickableHeight;

    &:disabled {
      opacity: 0.5;
    }
  }

  .nextMediaButton {
    margin-left: auto;
  }

  .previousMediaButton {
    img {
      transform: rotate(180deg);
    }
  }
}

.previews {
  border-radius: $cardBorderRadius;
  display: flex;
  overflow: hidden;
}

.reorderInstructions {
  font-size: $fontSizeXs;
  font-weight: bold;
  line-height: $lineHeightXs;
  margin: $contentMarginSm 0 0;
}

.reorderLeftButton {
  img {
    transform: rotate(180deg);
  }
}

.reorderRow {
  align-items: center;
  border-bottom-left-radius: $cardBorderRadius;
  border-bottom-right-radius: $cardBorderRadius;
  display: flex;
  justify-content: space-between;
  padding: 0 $contentMarginMd;

  > * {
    margin: 0;
  }
}

.thumbnailOverlay {
  margin-top: 0 !important;
  position: absolute;
  z-index: 1;
}

.thumbnail {
  border-radius: 2px;
  height: 40px;
  margin: 2px;
  overflow: hidden;
  padding: 0;
  width: 40px;
}

.thumbnailContainer {
  border-radius: 4px;
  cursor: pointer;
  height: 46px;
  margin: 0 $contentMarginSm $contentMarginSm 0;
  padding: 1px;
  width: 46px;

  &.active {
    border-style: solid;
    border-width: 2px;
    cursor: default;

    .thumbnail {
      margin: 0;
    }
  }

  &.addMedia {
    &:hover {
      border-style: solid;
      border-width: 1px;

      .thumbnail {
        margin: 1px;
      }
    }
  }

  &:not(.active, .addMedia) {
    &:hover {
      border-style: solid;
      border-width: 1px;

      .thumbnail {
        margin: 1px;
      }
    }
  }
}

.thumbnails {
  display: flex;
  flex-wrap: wrap;
  margin-top: $contentMarginMd;
}

.videoThumbnail {
  background-position: 15px center;
  background-repeat: no-repeat;
}

@include forLightTheme() {
  .addMediaButton.thumbnail {
    background-color: $contentBackgroundColor;
  }

  .audioThumbnail {
    background-image: url('/icons/audio_light.svg');
  }

  .thumbnailContainer.active {
    border-color: $brandColor;
  }

  .videoThumbnail {
    background-image: url('/icons/play_light.svg');
  }

  .thumbnailOverlay {
    background-color: color.change($contentBackgroundColor, $alpha: 0.5) !important;
  }
}

@include forDarkTheme() {
  .addMediaButton.thumbnail {
    background-color: $contentBackgroundColorDark;
  }

  .audioThumbnail {
    background-image: url('/icons/audio_dark.svg');
  }

  .thumbnailContainer.active {
    border-color: $brandColorAlternate;
  }

  .videoThumbnail {
    background-image: url('/icons/play_dark.svg');
  }

  .thumbnailOverlay {
    background-color: color.change($contentBackgroundColorDark, $alpha: 0.5) !important;
  }
}
