// doiuse-disable calc

@use 'sass:color';
@import 'OK/styles/util';

$desktopDescriptionMaxWidth: 400px;

.annualPricingToggleContainer {
  display: flex;
  margin: $contentMarginLg $contentMarginLg 0 0;
}

.annualPricingToggleSection {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  @include forMinScreenWidth(medium) {
    justify-content: flex-end;
  }
}

.annualPricingLabel {
  margin: 0 $contentMarginMd 0 0;
}

.basicsSection2 {
  display: flex;
  margin-top: -150px;
  padding-bottom: $contentMarginXl;
}

.basicsSection2Cards {
  flex: 1;
  max-width: 100%;
}

.cardButton {
  @include forMinScreenWidth(medium) {
    width: 247px;
  }
}

.carousel {
  padding: 0 $contentMarginSm;
  :global(.os-scrollbar-horizontal) {
    padding: 0 $contentMarginXxl !important;
  }
  @include forMinScreenWidth(medium) {
    :global(.os-scrollbar-horizontal) {
      padding: 0 $contentMarginXl !important;
    }
  }
}

.copyButton {
  border-radius: 2px;
  font-size: $fontSizeXs;
  font-weight: bold;
  line-height: $lineHeightXs;
  padding: 0 $contentMarginSm;
}

.currentSubscriptionDescription {
  margin-bottom: 0;

  @include forMinScreenWidth(medium) {
    width: $desktopDescriptionMaxWidth;
  }
}

.currentPlanStatus {
  margin-bottom: $contentMarginSm;
}

.fineprint {
  @include forMinScreenWidth(medium) {
    width: $desktopDescriptionMaxWidth;
  }
}

.header {
  margin-left: 0;
  font-size: 24px;
  font-weight: bold;
}

.icon {
  margin-bottom: 5px;
}

.invoicesCarousel {
  margin: 0 (-$contentMarginLg) !important;

  @include forMinScreenWidth(medium) {
    margin: 0 (-$contentMarginXxl) !important;
    margin-right: -$contentMarginXl !important;
  }
}

.invoiceCard {
  width: 100%;
}

.invoiceSlide {
  margin: 0 $contentMarginSm;
  flex: 0 0 100%;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    > * {
      display: inline-block;
      margin-right: $contentMarginSm;
    }
  }

  @include forMinScreenWidth(medium) {
    flex-basis: $cardWidth3ColumnDesktop;

    &:first-child {
      margin-left: $contentMarginMd;
    }

    &:last-child {
      > * {
        display: inline-block;
        margin-right: $contentMarginSm;
      }
    }
  }
}

.label {
  margin-bottom: $contentMarginSm;
}

.logLifeNotice {
  float: right;
  margin-bottom: $contentMarginMd;
  margin-top: $contentMarginSm;
  margin-left: -$contentMarginMd !important;
  margin-right: -$contentMarginMd !important;

  @include forMinScreenWidth(medium) {
    margin-right: 0 !important;
    margin-top: $contentMarginLg;
    width: 420px;
  }
}

.manageErrorMessage {
  margin: -15px 0 15px 0;
}
.accordion,
.manageSection {
  padding: $contentMarginLg 0 $contentMarginLg;
  border-radius: 5px;
}

.manageSection {
  padding-bottom: 0;
}

.nameInput {
  margin-bottom: 15px;
}

.pricingError {
  margin-top: $contentMarginSm;
}

.pricingFinePrint {
  margin-top: 15px;

  @include forMinScreenWidth(medium) {
    margin-top: $contentMarginLg;
  }
}

.pricingSlide {
  flex: 0 0 calc(100% - #{$contentMarginMd});

  @include forMinScreenWidth(medium) {
    flex-grow: 1;
    flex-shrink: 1 !important;
    max-width: none;
    min-width: 0;
    width: $cardWidth3ColumnDesktop;
  }
}

.sectionDescription {
  @include forMinScreenWidth(medium) {
    max-width: $desktopDescriptionMaxWidth;
  }
}

.settingsCard {
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 0px;
  border-color: transparent !important;
  flex: 1 1;
  margin-bottom: $contentMarginMd;
  padding: $contentMarginMd $contentMarginMd $contentMarginLg;

  :last-child {
    margin-bottom: 0;
  }
}

.settingsCards {
  flex: 1;
}

.settingsInput {
  margin-bottom: $contentMarginMd;
}

.spacer {
  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginMd;
    width: $cardWidth3ColumnDesktop;
  }
}

.termsLink {
  margin: 0;
}

.unbold {
  font-weight: normal;
}

// Old

.contactCard {
  position: relative;
}

.contactSectionInner {
  margin-top: -130px;
  padding-bottom: $contentMarginXxl;
}

.headerAccordion {
  margin-bottom: 0 !important;
  padding: 0 $contentMarginLg;
}

@include forLightTheme() {
  .freeTier {
    background-color: color.change($contentBackgroundColor, $alpha: 0.5) !important;
  }
  .accordion,
  .identificationSection,
  .manageSection {
    background-color: $contentBackgroundColor;
  }

  .settingsCard {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .freeTier {
    background-color: color.change($contentBackgroundColorDark, $alpha: 0.5) !important;
  }
  .accordion,
  .identificationSection,
  .manageSection {
    background-color: $contentBackgroundColorDark;
  }

  .settingsCard {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
  }
}
