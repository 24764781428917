@import 'OK/styles/util';

/* Default styles (mobile) */

.accountProfilePhoto {
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  height: 34px;
  margin: 0;
  position: relative;
  width: 34px;
}

.actions {
  left: 0;
  padding: $contentMarginSm 0;
  pointer-events: auto;
  right: 0;
  transition: $timingShort all;
}

.appSettings {
  margin-left: auto;
}

.cartTooltip {
  left: calc(100% - ((125px + 75px) / 2));
  width: 125px;
  top: -48px;

  @include forMinScreenWidth(medium) {
    left: calc(100% - ((125px + 84px) / 2));
    top: calc(100% + $contentMarginSm);
  }
}

.container {
  @include propertyWithFallbackValue(
    padding,
    var(--safeAreaInsetTop) var(--safeAreaInsetRight) 0 var(--safeAreaInsetLeft),
    0
  );
  bottom: 0;
  left: 0;
  margin: 0 auto;
  pointer-events: none;
  right: 0;
  top: 0;
}

.innerActionContainer {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 0 15px;

  @include forMinScreenWidth(medium) {
    padding: 0 $contentMarginLg;
    padding-right: $contentMarginXl;
  }
}

.innerNavigationContainer {
  align-items: stretch;
  display: flex;

  @include forMaxScreenWidth(medium) {
    padding: 0;
  }
}

.logoContainer {
  display: flex;
  margin-right: auto;

  @include forMinScreenWidth(medium) {
    margin-right: 0;
    padding-right: $contentMarginSm;
  }
}

.logoLink {
  margin: 0;
}

.navigation {
  @include propertyWithFallbackValue(
    padding,
    15px var(--safeAreaInsetRight) var(--safeAreaInsetBottom) var(--safeAreaInsetLeft),
    15px 0 0 0
  );
  bottom: 0;
  // Height is button height + top and bottom padding + bottom safe area inset
  height: $mainMenuTabsHeight; // Fallback for browsers not supporting env()
  height: $mainMenuTabsHeightPlusBottomSafeArea;
  left: 0;
  pointer-events: auto;
  position: fixed;
  right: 0;
  transition: bottom $timingShort, height $timingShort;
  z-index: 100;

  &.withFooterBanner {
    bottom: 54px;
    height: 77px;
  }
}

.navigationButton {
  cursor: pointer;
  display: block !important;
  flex: 1;
  font-size: $fontSizeXs;
  line-height: $lineHeightXs;
  margin-bottom: 0;
  position: relative;
  text-align: center;
}

.navigationButtonAccess {
  padding-top: 14px !important;
  padding-left: 10px !important;
  margin-bottom: 0 !important;
}

.navigationButtonHome {
  color: $textColor !important;
}

.navigationIcon {
  height: 22px;
  margin-bottom: $contentMarginXs;
  position: relative;
}

.navigationIconAccess {
  margin-top: 2px !important;
}

.navigationLabel {
  margin: 0;
}

.notificationBadge {
  border-radius: 10px;
  color: $textColorLight;
  font-size: $fontSizeXs;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  position: absolute;
  top: -10px;
  width: 20px;
  left: 50%;

  @include forMinScreenWidth(medium) {
    left: auto;
    right: -10px;
  }
}

.organisationButton {
  margin: 0 15px 0 $contentMarginMd;
}

.userButton {
  margin: 0;
}

.organisationLogo {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  height: 30px;
  width: 30px;
}

.search {
  height: $inputHeight;
  overflow: hidden;
  pointer-events: all;
  position: absolute !important;
  right: 0;
  transition: width $timingMedium ease;
  width: $inputHeight;

  @include forMinScreenWidth(medium) {
    right: $contentMarginSm;
  }

  input {
    transition: opacity $timingShort ease 100ms;
  }

  &.expanded {
    width: calc(100% + 15px);
    opacity: 1;
  }

  &:not(.expanded) {
    cursor: pointer;

    input {
      cursor: pointer;
      opacity: 0;
      transition: opacity $timingShort;
      padding: 0;
    }
  }
}

.searchForm {
  height: $inputHeight;
  left: $contentMarginLg;
  pointer-events: none;
  position: absolute;
  right: $contentMarginLg - 15px;

  @include forMinScreenWidth(medium) {
    right: $contentMarginLg;
  }
}

.sessionOptionsContainer {
  display: flex;
  padding-right: $inputHeight + 14px;
}

.talkToUsTooltip {
  left: calc(100% - ((125px + 75px) / 2));
  top: -48px;
  width: 125px;

  @include forMinScreenWidth(medium) {
    left: calc(100% - ((125px + 126px) / 2));
    top: calc(100% + $contentMarginSm);
  }
}

.tooltip {
  font-size: $fontSizeSm !important;
  line-height: $lineHeightSm !important;
}

.tooltipAnimationContainer {
  position: absolute !important;
  left: 0;
  top: 0;
}

.tooltipAnimationContainer,
.tooltipInnerContainer {
  height: 100%;
  width: 100%;
}

.tooltipContainer {
  height: 100%;
  left: 0;
  margin: 0 auto;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Desktop adjustments */

@include forMinScreenWidth(medium) {
  .actions {
    flex: 1;
    padding-left: 0;
    position: static;
  }

  .container {
    bottom: auto;
    display: flex;
    max-width: $contentMaxWidthDesktop;
  }

  .home {
    .navigationIcon {
      padding-top: 1px;
    }

    .navigationLabel {
      top: -3px;
    }
  }

  .navigation {
    flex: none;
    height: 60px;
    padding-top: $contentMarginSm;
    position: static;
  }

  .navigationButton {
    flex: none;
    font-size: $fontSizeMd;
    line-height: $fontSizeMd;
    padding: $contentMarginSm;

    > * {
      display: inline-block;
    }

    &:first-child {
      padding-left: 0;
    }

    &.active {
      &:after {
        bottom: 0px;
        top: unset;
      }
    }
  }

  .navigationIcon {
    margin: 0;
    vertical-align: text-top;
  }

  .navigationLabel {
    margin: 0 0 0 $contentMarginSm;
  }
}

/* Theme-dependant styles */

/* Light */

@include forLightTheme() {
  .accountProfilePhoto,
  .organisationLogo {
    border-color: $midtonesColorStatic;
  }

  .backgroundEnabled {
    .navigationButton {
      color: #707070 ;

      &:hover {
        color: $textColor !important;
      }

      &.active {
        color: $textColor !important;
      }
    }

    .navigationButtonHome {
      color: $textColor;
    }
  }

  .logoLink {
    color: $textColor;
  }

  .navigation {
    background-color: $contentBackgroundColorFocus;

    @include forMinScreenWidth(medium) {
      background: none;
    }
  }

  .navigationButton {
    color: #707070;

    &:hover {
      color: $textColor !important;
    }

    &.active {
      color: $textColor !important;
    }
  }

  .navigationButton:hover {
    color: $textColor !important;
  }

  .navigationButtonHome {
    color: $textColor !important;
  }

  .notificationBadge {
    background-color: $alertColorFocus;

    @include forMinScreenWidth(medium) {
      background-color: $alertColor;
    }
  }
}

/* Dark */

@include forDarkTheme() {
  .accountProfilePhoto,
  .organisationLogo {
    border-color: $midtonesColorStaticDark;
  }

  .logoLink {
    color: $textColorLight;
  }

  .navigation {
    background-color: $contentBackgroundColorFocusDark;

    @include forMinScreenWidth(medium) {
      background: none;
    }
  }

  .navigationButton {
    color: #707070 !important;

    &:hover {
      color: $textColorLight !important;
    }

    &.active {
      color: $textColorLight !important;
    }
  }

  .navigationButtonHome {
    color: $textColorLight !important;
  }

  .notificationBadge {
    background-color: $alertColorFocusDark;

    @include forMinScreenWidth(medium) {
      background-color: $alertColor;
    }
  }
}

// Theme overrides

.theme_override_light {
  .logoLink {
    color: $textColor !important;
  }
  .navigationButton {
    @include forMinScreenWidth(medium) {
      color: $textColor !important;
    }

    &:hover {
      @include forMinScreenWidth(medium) {
        color: $textColor !important;
      }
    }

    &.active {
      color: $textColor !important;
    }
  }
}

.theme_override_dark {
  .logoLink {
    color: $textColorLight !important;
  }
  .navigationButton {
    @include forMinScreenWidth(medium) {
      color: $textColorLight !important;
    }

    &:hover {
      @include forMinScreenWidth(medium) {
        color: $textColorLight !important;
      }
    }

    &.active {
      color: $textColorLight !important;
    }
  }
}
