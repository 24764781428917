@import 'OK/styles/util';

.tabsContainer {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-left: 8px;
}

.tabsContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.tabUnderline {
  display: block;
  position: absolute;
  bottom: 2px;
  width: 40px;
  height: 2px;
  transition: transform 0.3s ease-in-out;
  border-radius: 5px;
}

@include forLightTheme {
  .tabUnderline {
    background-color: $brandColor;
  }
}

@include forDarkTheme {
  .tabUnderline {
    background-color: $brandColorAlternate;
  }
}
