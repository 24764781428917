@import 'OK/styles/util';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.layout {
  height: 0;
  padding-top: 100%;
  position: relative;
  width: 100%;
}

.icon {
  height: 64px;
  width: 64px;
}

.player {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
}

@include forLightTheme() {
  .container {
    background-color: $contentBackgroundColor;
  }
}

@include forDarkTheme() {
  .container {
    background-color: $contentBackgroundColorDark;
  }
}
