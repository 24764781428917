@import 'OK/styles/util';

.button {
  margin-bottom: $contentMarginSm;
}

.buttonTooltip {
  display: block;
  margin: 0 auto 3px;
  padding: 0 $contentMarginXs;
  position: relative;
  width: fit-content;
  z-index: 1;
}

.features {
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  list-style-image: url('/icons/check_green.svg');
  margin-bottom: $contentMarginXl;
  padding-inline-start: $contentMarginMd;
  min-height: 85px;

  li {
    margin-bottom: $contentMarginSm;
  }
}

.finePrint {
  margin: 0;
  padding-top: $contentMarginSm;

  p {
    font-size: $fontSizeXs;
    line-height: $lineHeightXs;
    margin: 0;
    position: relative;
  }
}

.fullPrice {
  position: relative;
}

.price {
  font-size: $fontSizeFocal;
  line-height: $lineHeightFocal;
  margin: 0 $contentMarginSm 0 0;

  &.discountPrice {
    color: $creationColor;
  }
}

.priceContainer {
  align-items: center;
  display: flex;
  margin-bottom: $contentMarginMd;

  p {
    font-size: $fontSizeXs;
    line-height: $lineHeightXs;
    margin: 0;
    position: relative;
  }

  .strike {
    background-color: $alertColor;
    height: 2px;
    left: -1px;
    position: absolute;
    top: 7px;
    transform: rotate(20deg);
    width: 22px;
  }
}

.smartToggle {
  display: inline-block;
  margin-left: $contentMarginSm;
}

.smarty {
  float: right;
  font-size: $fontSizeMd;
  font-weight: normal;

  ion-toggle {
    margin: 0 0 0 5px;
    position: relative;
    top: 4px;
  }
}

// Force show the tooltip instead of showing on hover
.supplierTooltip {
  font-size: $fontSizeSm !important;
  font-weight: bold;
  left: calc(50% - 60px);
  padding: 0 $contentMarginXs;
  text-align: center;
  top: -54px;
  width: 120px;
  line-height: $lineHeightSectionHeader !important;
  vertical-align: middle;
}

.tier {
  height: calc(100% - #{$contentMarginXl});
  margin-top: $contentMarginXl;
  max-width: 440px;
  min-height: 400px;
  position: relative;
}
