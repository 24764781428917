@import 'OK/styles/util';

.searchButton {
  height: $minClickableHeight;
  margin-left: -12px;
  margin-right: -12px;
  padding: 0;
  width: $minClickableHeight;
}

.searchIcon {
  position: relative;
  top: -1px;
}
