@import 'OK/styles/util';

// doiuse-disable calc
// doiuse-disable css-masks

.carousel {
  display: flex;
}

.container {
  :global(.os-scrollbar-horizontal) {
    left: $contentMarginLg !important;
    padding: 0;
    right: $contentMarginLg !important;
  }

  :global(.os-viewport) {
    padding-bottom: $contentMarginLg !important;
  }

  &.fadeOutSides {
    mask-image: linear-gradient(
      to right,
      transparent 0%,
      #000 $contentMarginMd,
      #000 calc(100% - $contentMarginMd),
      transparent 100%
    );
    margin-left: -$contentMarginMd;
    margin-right: -$contentMarginMd;

    .carousel {
      padding-left: $contentMarginMd;
      padding-right: $contentMarginMd;
    }

    .slide {
      &:last-child {
        border-right: 20px solid transparent;
        box-sizing: content-box;
      }
    }

    :global(.os-scrollbar-horizontal) {
      left: $contentMarginMd + $contentMarginLg !important;
      right: $contentMarginMd + $contentMarginLg !important;
    }
  }

  &.withOverflow {
    .carousel {
      padding-bottom: $contentMarginMd;
    }

    :global(.os-host) {
      padding-bottom: 17px;
    }
  }

  /* OverlayScrollbars */

  :global(.os-scrollbar-handle) {
    min-width: 44px !important;

    &:before {
      bottom: -17px !important;
      top: -17px !important;
    }
  }

  :global(.os-scrollbar-track:before) {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    right: 0;
    bottom: 2px;
    border-radius: 5px;
  }

  :global(.os-scrollbar-unusable) {
    display: none;
  }
}

@include forLightTheme() {
  .container {
    :global(.os-scrollbar-handle) {
      background: $navigationColor !important;
    }

    :global(.os-scrollbar-track:before) {
      background: $brandColor;
    }
  }
}

@include forDarkTheme() {
  .container {
    :global(.os-scrollbar-handle) {
      background: $navigationColor !important;
    }

    :global(.os-scrollbar-track:before) {
      background: $brandColorAlternate;
    }
  }
}
