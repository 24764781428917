@import 'OK/styles/util';

.testLevelIndicator {
  border-radius: 2px;
  display: inline-block;
  height: 12px;
  position: relative;
  top: 1px;
  width: 12px;

  &.minor {
    background-color: $creationColor;
  }

  &.major {
    background-color: $notificationColor;
  }

  &.critical {
    background-color: $alertColor;
  }
}
