@import 'OK/styles/util';

@include forLightTheme() {
  .mediaInfo {
    background-color: $contentBackgroundColorCard;
  }

  .reorderRow {
    background-color: $midtonesColorStatic;
  }

  .thumbnail {
    background-color: $midtonesColorStatic;
  }

  .thumbnailContainer {
    &.addMedia {
      &:hover {
        border-color: $creationColorHover;
      }
    }

    &:not(.active, .addMedia) {
      &:hover {
        border-color: $navigationColorHover;
      }
    }
  }
}

@include forDarkTheme() {
  .mediaInfo {
    background-color: $contentBackgroundColorCardDark;
  }

  .reorderRow {
    background-color: $midtonesColorStaticDark;
  }

  .thumbnail {
    background-color: $midtonesColorStaticDark;
  }

  .thumbnailContainer {
    &.addMedia {
      &:hover {
        border-color: $creationColorHoverDark;
      }
    }

    &:not(.active, .addMedia) {
      &:hover {
        border-color: $navigationColorHoverDark;
      }
    }
  }
}
