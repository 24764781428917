.container {
  position: relative;

  &.inProgress {
    .content {
      opacity: 0.1;
      pointer-events: none;
    }
  }
}

.progressInfo {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.spinner {
  height: 64px;
  max-height: 50%;
  max-width: 50%;
  width: 64px;
}
