@use 'sass:color';
@import 'OK/styles/util';

.menu {
  border-radius: $cardBorderRadius;
  display: flex;
  flex-direction: column;
  font-size: $fontSizeMd;
  height: 100%;
  left: 0;
  line-height: $lineHeightMd;
  overflow: scroll;
  padding-top: 54px;
  position: absolute;
  top: 0;
  width: 100%;
}

@include forLightTheme() {
  .menu {
    background-color: color.change($contentBackgroundColor, $alpha: 0.95);
  }
}

@include forDarkTheme() {
  .menu {
    background-color: color.change($contentBackgroundColorDark, $alpha: 0.95);
  }
}
