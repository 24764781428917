// doiuse-disable calc

@use 'sass:color';
@import 'OK/styles/util';

$desktopDescriptionMaxWidth: 400px;

.basicsSection2 {
  display: flex;
  margin-top: -150px;
  padding-bottom: $contentMarginXl;
}

.basicsSection2Cards {
  flex: 1;
  max-width: 100%;
}

.cardButton {
  @include forMinScreenWidth(medium) {
    width: 247px;
  }
}

.changeRoleButton {
  margin-bottom: $contentMarginSm;

  &:last-child {
    margin-bottom: 0;
  }
}

.fineprint {
  @include forMinScreenWidth(medium) {
    width: $desktopDescriptionMaxWidth;
  }
}

.headerAccordion {
  margin-bottom: 0 !important;
  padding: 0 $contentMarginLg;
}

.identificationSectionInner {
  padding-bottom: 150px;
}

.identificationSectionInner {
  @include forMinScreenWidth(medium) {
    display: flex;
  }
}

.icon {
  margin-bottom: 5px;
}

.label {
  margin-bottom: $contentMarginSm;
}

.logLifeNotice {
  float: right;
  margin-bottom: $contentMarginMd;
  margin-top: $contentMarginSm;

  @include forMinScreenWidth(medium) {
    margin-top: $contentMarginLg;
    width: 420px;
  }
}

.manageErrorMessage {
  margin: -15px 0 15px 0;
}

.accordion,
.manageSection {
  padding: $contentMarginLg 0 $contentMarginLg;
  border-radius: 5px;
}

.manageSection {
  padding-bottom: 0;
}

.nameInput {
  margin-bottom: 15px;
}

.okidInput {
  margin-bottom: $contentMarginSm;

  @include forMinScreenWidth(medium) {
    width: 247px;
  }
}

.okidInput,
.staticInput {
  border-radius: $inputBorderRadius;
  height: $inputHeight;
  line-height: $inputHeight;
  padding: 0 15px;
}

.profilePhoto {
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  height: 0;
  margin-bottom: $contentMarginMd;
  overflow: hidden;
  padding-top: calc(100% - 2px);
  position: relative;
  width: 100%;

  @include forMinScreenWidth(medium) {
    padding-top: $cardWidth3ColumnDesktop - 2px;
    width: $cardWidth3ColumnDesktop;
  }
}

.profilePhotoButton {
  margin-bottom: 0;

  // &:first-child {
  //   margin-bottom: $contentMarginSm;
  // }

  &:last-child {
    margin-bottom: $contentMarginMd;
  }
}

.profilePhotoContainer {
  margin-bottom: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginMd;
    width: $cardWidth3ColumnDesktop;
  }
}

.profilePhotoErrorMessage {
  margin: -15px 0 $contentMarginSm 0;
}

.searchInput {
  margin-bottom: $contentMarginLg;

  @include forMinScreenWidth(medium) {
    max-width: $desktopDescriptionMaxWidth;
  }
}

.searchModeButtons {
  margin-bottom: $contentMarginMd;
}

.sectionDescription {
  @include forMinScreenWidth(medium) {
    max-width: $desktopDescriptionMaxWidth;
  }
}

.settingsCard {
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 0px;
  border-color: transparent !important;
  flex: 1 1;
  margin-bottom: $contentMarginMd;
  padding: $contentMarginMd $contentMarginMd $contentMarginLg;

  :last-child {
    margin-bottom: 0;
  }
}

.settingsCards {
  flex: 1;
}

.settingsInput {
  margin-bottom: $contentMarginMd;
}

.spacer {
  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginMd;
    width: $cardWidth3ColumnDesktop;
  }
}

.termsLink {
  margin: 0;
}

.unbold {
  font-weight: normal;
}

.userCard,
.userCardContainer {
  height: 100%;
  width: 100%;
}

.usersCarouselContainer {
  margin: 0 (-$contentMarginLg) !important;

  @include forMinScreenWidth(medium) {
    margin: 0 (-$contentMarginXxl) !important;
  }
}

.usersCarousel {
  padding: 0 $contentMarginSm;
  :global(.os-scrollbar-horizontal) {
    padding: 0 $contentMarginXxl !important;
  }
  @include forMinScreenWidth(medium) {
    :global(.os-scrollbar-horizontal) {
      padding: 0 $contentMarginXl !important;
    }
  }
}

.userSlide {
  margin: 0 $contentMarginSm;
  width: 100%;
  flex: 0 0 100%;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    > * {
      margin-right: $contentMarginSm;
    }
  }

  @include forMinScreenWidth(medium) {
    width: auto;
    flex: 0 0 0;

    &:first-child {
      margin-left: $contentMarginSm;
    }

    &:last-child {
      > * {
        margin-right: $contentMarginSm;
      }
    }
  }
}
// Old

.contactCard {
  position: relative;
}

.contactSectionInner {
  margin-top: -130px;
  padding-bottom: $contentMarginXxl;
}

.header {
  margin-left: 0;
  font-size: 24px;
  font-weight: bold;
}

.membershipsError {
  margin: -25px 0 25px 0;
}

.membershipsSection {
  padding-top: $contentMarginXl;
}

.result {
  cursor: pointer;
  margin: 0 $contentMarginMd $contentMarginMd 0;
}

.resultMedia {
  opacity: 0.5;

  &.active {
    opacity: 1;
  }
}

.results {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$contentMarginMd;
}

@include forLightTheme() {
  .freeTier {
    background-color: color.change($contentBackgroundColor, $alpha: 0.5) !important;
  }
  .accordion,
  .identificationSection,
  .manageSection {
    background-color: $contentBackgroundColor;
  }

  .okidInput,
  .staticInput {
    background-color: $midtonesColorStatic;
  }

  .profilePhoto {
    border-color: $midtonesColorStatic;
  }

  .settingsCard {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .freeTier {
    background-color: color.change($contentBackgroundColorDark, $alpha: 0.5) !important;
  }

  .accordion,
  .identificationSection,
  .manageSection {
    background-color: $contentBackgroundColorDark;
  }

  .okidInput,
  .staticInput {
    background-color: $midtonesColorStaticDark;
  }

  .profilePhoto {
    border-color: $midtonesColorStaticDark;
  }

  .settingsCard {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
  }
}
