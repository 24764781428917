@import 'OK/styles/util';

.label {
  display: flex;
  align-items: center;
  gap: 6px;
}

.labelContainer {
  align-items: flex-start;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 20px;

  @include forMinScreenWidth(medium) {
    gap: 30px;
  }
}

.labelContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  padding-top: 20px;

  @include forMinScreenWidth(medium) {
    flex-direction: row;
  }
}

.labelDescription {
  background-color: #e2e2e2;
  border-radius: 5px;
  padding: 20px;
}

.labelDescriptionText {
  margin: 0;
}

.labelText {
  font-size: 12px;
  margin: 0;
}

.labelCircle {
  width: 10px;
  height: 10px;
  background-color: #1a1a1a;
  border-radius: 10px;
}

.mapContainer {
  background-color: white;
  padding: 30px;
}

.mapTitle {
  font-size: 20px;
}

@include forDarkTheme() {
  .labelDescription {
    background-color: $contentBackgroundColorCardDark;
  }

  .mapContainer {
    background-color: black;
  }
}

// testListView

@import 'OK/styles/util';

.archiveCardBottomContainer {
  color: #727272;
  font-size: 12px;
  width: 100%;
  max-width: 315px;
  height: 45px;
  background-image: linear-gradient(to bottom, rgb(248, 248, 248, 0), rgba(255, 255, 255, 1) 100%);
  padding: 12px 20px;
  position: absolute;
  bottom: -45px;
}

.archiveCardBottomContainerExtra {
    color: #727272;
    font-size: 12px;
    width: 315px;
    height: 10px;
    background-color: rgba(112, 112, 112, 0.03);
    background-image: linear-gradient(to bottom, rgba(112, 112, 112, 0.03), rgba(255, 255, 255, 1) 100%);
    position: absolute;
    bottom: -5px;
    z-index: -100;
  }

.archiveCardContainer {
  background-color: white;
  border: 1px solid $midtonesColorStatic;
  border-radius: 5px;
  display: flex;
  position: relative;
  max-width: 315px;
  width: 100%;
  height: 68px;
}

.assetDescription {
  padding: 14px 0px 14px 20px;
}

.assetID {
  background-color: white;
  color: #767575;
  font-family: 'Noto Mono';
  font-size: 12px;
  padding: 0 10px;
  position: absolute;
  bottom: -12px;
  left: 10px;
}

.assetImage {
  background-color: black;
  width: 66px;
  height: 66px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.assetName {
  font-size: 14px;
  margin-bottom: 0 !important;
  max-height: 1.5em;
  overflow: hidden;
  position: relative;
  text-overflow: clip !important;
  white-space: nowrap;
  width: 228px;
  word-wrap: break-word;
  color: black;
}

.assetName::after {
  content: '';
  width: 22px;
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 1) 28%);
  height: 1.5em;
  position: absolute;
  right: 0;
}

.assetType {
  background-color: white;
  color: #767575;
  font-size: 12px;
  padding: 0 10px;
  position: absolute;
  top: -12px;
  left: 10px;
}

@include forDarkTheme() {
  .archiveCardBottomContainer {
    background-image: none;
  }

  .archiveCardContainer {
    background-color: #010101;
    border: 1px solid #767575;
  }

  .assetID {
    background-color: #010101;
  }

  .assetType {
    background-color: #010101;
  }

  .assetName {
    color: #F4F4F4;
  }

  .assetName::after {
    background-image: linear-gradient(to right, transparent, rgba(1, 1, 1, 1) 28%);
  }
}

// mapPopup

.mapPopupContainer {
  max-width: 375px;
}