@import 'OK/styles/util';

.popupContent {
  max-width: 335px;
}

@include forLightTheme {
  .popupContent {
    background-color: $contentBackgroundColorCard;
  }
}

@include forDarkTheme {
  .popupContent {
    background-color: $contentBackgroundColorCardDark;
  }
}
