@import 'OK/styles/util';

// Light theme

:global(.theme_light) {
  .container {
    button {
      background-color: $contentBackgroundColor;
      border-color: $inputBorderColorActive;
      color: $buttonTextColorAlternate;
    }

    select {
      &:not(:disabled) {
        &:hover {
          ~ button {
            border-color: $navigationColorFocus;
          }
        }

        &:focus {
          ~ button {
            border-color: $inputBorderColorActive;
          }
        }
      }
    }

    &.error {
      button {
        border-color: $alertColorFocus !important;
      }
    }
  }
}

// Dark theme

:global(.theme_dark) {
  .container {
    button {
      background-color: $contentBackgroundColorDark;
      border-color: $inputBorderColorActiveDark;
      color: $buttonTextColor;
    }

    select {
      &:not(:disabled) {
        &:hover {
          ~ button {
            border-color: $navigationColorFocusDark;
          }
        }

        &:focus {
          ~ button {
            border-color: $inputBorderColorActiveDark;
          }
        }
      }
    }

    &.error {
      button {
        border-color: $alertColorFocusDark !important;
      }
    }
  }
}
