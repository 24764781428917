@import 'OK/styles/util';

.attachment {
  margin-top: $contentMarginSm;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.attachmentIcon {
  margin-right: $contentMarginSm;
}

.header {
  display: inline-block;
  margin: 0 $contentMarginSm $contentMarginSm 0;
}

.linkStepButton {
  height: $buttonHeight;
  margin-bottom: $contentMarginSm;
}

.linkStepErrorMessage {
  margin: -$contentMarginXs 0 $contentMarginSm 0;
}

p.publishLabel {
  margin-right: auto;
}

.publishNotice {
  font-size: $fontSizeMd !important;
  line-height: $lineHeightMd !important;
  margin-bottom: $contentMarginMd !important;
  margin-top: 0 !important;

  p {
    margin-bottom: 0 !important;
  }
}

.publishRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.refId {
  float: right;
  position: relative;
  top: 5px;
}

.searchContainer {
  position: relative;
  z-index: 1;
}

.searchInput {
  z-index: 1;
}

.searchSuggestions {
  position: absolute;
  top: $inputHeight - $contentMarginXs;
  left: 0;
  right: 0;
}

.section {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: $contentMarginMd;
  padding-bottom: $contentMarginSm;

  &:last-child {
    border-bottom-width: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.unlinkButton {
  margin: 0;
}

.unsaved {
  border-style: dashed;
  border-width: 2px;
}

@include forLightTheme() {
  .section {
    border-color: $midtonesColorStatic;
  }

  .unsaved {
    background-color: $contentBackgroundColor;
    border-color: $brandColor;
  }
}

@include forDarkTheme() {
  .section {
    border-color: $midtonesColorStaticDark;
  }

  .unsaved {
    background-color: $contentBackgroundColorDark;
    border-color: $brandColorAlternate;
  }
}
