@import 'OK/styles/util';

.carousel {
  gap: 20px;
}

.productFilterButtons {
    display: flex;
    gap: 8px;
    margin-top: $contentMarginMd;
    width: 100%;
}

.searchInput {
  z-index: 1;

  @include forMinScreenWidth(medium) {
    max-width: 400px;
  }
}

.searchNewButton {
  @include increaseClickableArea(2px, 0, relative);
  height: $buttonHeight;
  z-index: 3;
}

.searchResults {
  width: 100%;

  @include forMinScreenWidth(medium) {
    max-width: 400px;
  }
}

.searchSuggestion {
  max-width: 120px;

  @include forMinScreenWidth(medium) {
    max-width: 280px;
  }
}