// doiuse-disable intrinsic-width

@import 'OK/styles/util';

.container {
  @include fontMono();
  border-radius: 2px;
  display: inline-block;
  font-weight: bold;
  padding: 0 $contentMarginXs;
  position: relative;
  top: -1px;

  &.block {
    display: block;
    width: fit-content;
  }

  /* Sizes */

  &.xs {
    font-size: $fontSizeXs;
    line-height: $lineHeightXs;
  }

  &.sm {
    font-size: $fontSizeSm;
    line-height: $lineHeightSm;
  }

  &.md {
    font-size: $fontSizeMd;
    line-height: $lineHeightMd;
  }

  // States

  &.withIcon {
    line-height: 28px;
  }
}

.inlineBlock {
  display: inline-block;
}

.loadingSpinner {
  height: 12px;
  margin: 8px 0 8px $contentMarginSm;
  width: 12px;
}

.removeTagButton {
  @include increaseClickableArea(14px);
  height: 16px;
  margin: 6px 0 6px $contentMarginSm;
  padding: 0;
  position: relative;
  top: -1px;
  width: 16px;
}

@include forLightTheme() {
  .container {
    background-color: $midtonesColorStatic;
    color: $textColor;
  }

  .inverted {
    background-color: $brandColor;
    color: $textColorLight;
  }

  // Tints

  .alert {
    background-color: $alertColor;
  }

  .creation {
    background-color: $creationColor;
  }

  .navigation {
    background-color: $navigationColor;
  }

  .notification {
    background-color: $notificationColor;
  }
}

@include forDarkTheme() {
  .container {
    background-color: $midtonesColorStaticDark;
    color: $textColorLight;
  }

  .inverted {
    background-color: $brandColorAlternate;
    color: $textColor;
  }

  // Tints

  .alert {
    background-color: $alertColor;
  }

  .creation {
    background-color: $creationColor;
  }

  .navigation {
    background-color: $navigationColor;
  }

  .notification {
    background-color: $notificationColor;
  }
}
