@import 'OK/styles/util';

@include forLightTheme() {
  .alert {
    color: $alertColorFocus;
  }

  .creation {
    color: $creationColorFocus;
  }

  .navigation {
    color: $navigationColorFocus;
  }

  .notification {
    color: $notificationColorFocus;
  }
}

@include forDarkTheme() {
  .alert {
    color: $alertColorFocusDark;
  }

  .creation {
    color: $creationColorFocusDark;
  }

  .navigation {
    color: $navigationColorFocusDark;
  }

  .notification {
    color: $notificationColorFocusDark;
  }
}
