@import 'OK/styles/util';

.assemblyCarousel {
  margin-left: -$contentMarginLg;
  margin-right: -$contentMarginLg;

  @include overlayScrollbarsDefaultScrollbarPadding();

  @include forMinScreenWidth(medium) {
    margin-left: 0;
    margin-right: 0;
  }
}

.greenCircleButton {
  border-radius: 40px;
  height: 80px;
  justify-content: center;
  margin-bottom: $contentMarginSm;
  width: 80px;
}

.itemCard {
  width: 100%;
}

.itemSlide {
  margin-right: $contentMarginMd;
  flex: 0 0 calc(100% - #{$contentMarginLg * 2});

  &:first-child {
    margin-left: $contentMarginLg;
  }

  @include forMinScreenWidth(medium) {
    flex-basis: $cardWidth4ColumnDesktop;

    &:first-child {
      margin-left: 0;
    }
  }
}

.scanSlideButton {
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  margin-right: $contentMarginLg;
  min-height: 250px;
  height: 100%;
  width: 100%;

  @include forMinScreenWidth(medium) {
    min-height: 300px;
    width: $cardWidth4ColumnDesktop;
  }
}

.scanSlideButtonInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin: 0;
  }
}

.section {
  padding-bottom: $contentMarginXxl;
  padding-top: $contentMarginXl;
}

.sectionDescription {
  max-width: 400px;
}

.sectionNotice {
  @include forMinScreenWidth(medium) {
    float: right;
    margin-top: 0;
    max-width: 350px;
  }
}

@include forLightTheme() {
  .scanSlideButton {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .scanSlideButton {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
  }
}
