@import 'OK/styles/util';

.container {
  border-radius: $cardBorderRadius;
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  margin: $contentMarginMd $contentMarginSm $contentMarginLg;
  padding: $contentMarginMd $contentMarginSm;

  &.extendSides {
    margin-left: -5px;
    margin-right: -5px;
  }

  p {
    margin-bottom: $contentMarginSm;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include forLightTheme() {
  .container {
    background-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .container {
    background-color: $midtonesColorStaticDark;
  }
}
