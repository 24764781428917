@use "sass:math";

@import 'OK/styles/util';

.addLanguageButton {
  @include forMinScreenWidth(medium) {
    width: 247px;
  }
}

.basicsContainer {
  padding-bottom: $contentMarginXxl;
  padding-top: $contentMarginLg;
}

.deleteNoteButton {
  margin-bottom: $contentMarginSm;
}

.floatingButtons {
  position: sticky;
  bottom: 0 !important;
  margin-top: -$contentMarginMd;
}

.header {
  margin-bottom: $contentMarginLg;
}

.layoutContainer {
  padding-top: $mainMenuHeight + ($minClickableHeight * 2);
}

.popupMenuBackground {
  position: fixed;
  z-index: 147;
  background-color: rgba(244, 244, 244, 1);
}

.topBorderPopup {
  background-color: transparent;
}

.popupMenu {
  padding-left: 0;
  position: fixed;
  z-index: 148;
  background-color: white;
  transition: 100ms all;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.refId {
  font-size: $fontSizeCardHeader !important;
  line-height: $lineHeightCardHeader !important;
  margin-left: $contentMarginSm;
  position: relative;
  top: -2px;
}

.reliabilityScore {
  font-weight: bold;
}

.shareToast {
  left: -(math.div(160px - $minClickableHeight, 2));
  position: absolute;
  text-align: center;
  top: $contentMarginXs;
  width: 160px;

  @include forMinScreenWidth(medium) {
    left: -(math.div(160px - 69px, 2));
  }
}

.subHeader {
  margin-bottom: $contentMarginLg;
}

.submenu {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.submenuButton {
  flex: 1 0 $minClickableHeight;
  margin-bottom: 0;

  @include forMinScreenWidth(medium) {
    &:not(:last-child) {
      margin-right: $contentMarginMd;
    }
  }
}

.submenuButtonContainer {
  position: relative;

  button {
    margin-bottom: 0;
  }
}

.submenuButtons {
  align-items: center;
  display: flex;
  height: 24px;
}

.submenuRefId {
  margin-left: $contentMarginXs;
}

.submenuTitle {
  margin: 0 auto 0 0;
}

.textInput {
  @include forMinScreenWidth(medium) {
    flex: 0 1 calc(50% - #{$contentMarginLg * 2});
    margin: 0 $contentMarginLg $contentMarginMd $contentMarginLg;
  }
}

.textInputsContainer {
  @include forMinScreenWidth(medium) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-$contentMarginLg) $contentMarginSm;
  }
}

@include forLightTheme() {
  .basicsContainer {
    background-color: $contentBackgroundColorCard;
  }

  .pageSubmenu {
    background-color: $contentBackgroundColor;
  }
}

@include forDarkTheme() {
  .basicsContainer {
    background-color: $contentBackgroundColorCardDark;
  }

  .pageSubmenu,
  .popupMenu,
  .popupMenuBackground {
    background-color: $contentBackgroundColorDark;
  }

  .popupMenu {
    background-color: $contentBackgroundColorDark;
    border-left: 1px solid $midtonesColorStaticDark;
    border-right: 1px solid $midtonesColorStaticDark;
    box-shadow: none !important;
  }

  .popupMenu {
    border-top: 1px solid $midtonesColorStaticDark;
  }
}
