@import 'OK/styles/util';

:global(.theme_light) {
  .link {
    color: $navigationColorFocus;

    &:hover {
      color: $navigationColorFocusHover;
    }

    &:active {
      color: $navigationColorFocusActive;
    }

    &.alert {
      color: $alertColorFocus;

      &:hover {
        color: $alertColorFocusHover;
      }

      &:active {
        color: $alertColorFocusActive;
      }
    }

    &.creation {
      color: $creationColorFocus;

      &:hover {
        color: $creationColorFocusHover;
      }

      &:active {
        color: $creationColorFocusActive;
      }
    }

    &.notification {
      color: $notificationColorFocus;

      &:hover {
        color: $notificationColorFocusHover;
      }

      &:active {
        color: $notificationColorFocusActive;
      }
    }
  }

  .button {
    background-color: $buttonBackgroundColor;
    color: $buttonTextColor;

    &:hover {
      background-color: $buttonBackgroundColorHover;
      color: $buttonTextColor;
    }

    &:active {
      background-color: $buttonBackgroundColorActive;
      color: $buttonTextColor;
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabled;
      color: $buttonTextColor;
    }

    &.alert {
      background-color: $alertColorFocus;

      &:hover {
        background-color: $alertColorFocusHover;
      }

      &:active {
        background-color: $alertColorFocusActive;
      }
    }

    &.creation {
      background-color: $creationColorFocus;

      &:hover {
        background-color: $creationColorFocusHover;
      }

      &:active {
        background-color: $creationColorFocusActive;
      }
    }

    &.navigation {
      background-color: $navigationColorFocus;

      &:hover {
        background-color: $navigationColorFocusHover;
      }

      &:active {
        background-color: $navigationColorFocusActive;
      }
    }

    &.notification {
      background-color: $notificationColorFocus;

      &:hover {
        background-color: $notificationColorFocusHover;
      }

      &:active {
        background-color: $notificationColorFocusActive;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColor;
      color: $buttonTextColorAlternate;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHover;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActive;
      }
    }
  }
}

:global(.theme_dark) {
  .link {
    color: $navigationColorFocusDark;

    &:hover {
      color: $navigationColorFocusHoverDark;
    }

    &:active {
      color: $navigationColorFocusActiveDark;
    }

    &.alert {
      color: $alertColorFocusDark;

      &:hover {
        color: $alertColorFocusHoverDark;
      }

      &:active {
        color: $alertColorFocusActiveDark;
      }
    }

    &.creation {
      color: $creationColorFocusDark;

      &:hover {
        color: $creationColorFocusHoverDark;
      }

      &:active {
        color: $creationColorFocusActiveDark;
      }
    }

    &.notification {
      color: $notificationColorFocusDark;

      &:hover {
        color: $notificationColorFocusHoverDark;
      }

      &:active {
        color: $notificationColorFocusActiveDark;
      }
    }
  }

  .button {
    background-color: $buttonBackgroundColorDark;
    color: $buttonTextColorAlternate;

    &:hover {
      background-color: $buttonBackgroundColorHoverDark;
    }

    &:active {
      background-color: $buttonBackgroundColorActiveDark;
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabledDark;
    }

    &.alert {
      background-color: $alertColorFocusDark;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $alertColorFocusHoverDark;
      }

      &:active {
        background-color: $alertColorFocusActiveDark;
      }
    }

    &.creation {
      background-color: $creationColorFocusDark;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $creationColorFocusHoverDark;
      }

      &:active {
        background-color: $creationColorFocusActiveDark;
      }
    }

    &.navigation {
      background-color: $navigationColorFocusDark;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $navigationColorFocusHoverDark;
      }

      &:active {
        background-color: $navigationColorFocusActiveDark;
      }
    }

    &.notification {
      background-color: $notificationColorFocusDark;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $notificationColorFocusHoverDark;
      }

      &:active {
        background-color: $notificationColorFocusActiveDark;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColorDark;
      color: $buttonTextColor;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHoverDark;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActiveDark;
      }
    }
  }
}
