@import 'OK/styles/util';

.handle {
  background-color: $contentBackgroundColor;
  border-radius: 50%;
  height: 21px;
  transition: margin 250ms;
  width: 21px;
}

.spinner {
  display: inline-block;
  margin-right: $contentMarginSm;
}

.toggle {
  @include increaseClickableArea(10px, 0);
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  padding: 2px;
  vertical-align: middle;
  width: 46px;

  &.checked {
    .handle {
      margin-left: 21px;
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}
