@import 'OK/styles/util';

.labelType {
  height: $lineHeightMd;
}

.productDetailsRow {
  display: flex;
  margin-bottom: $contentMarginXs;
}

.productName {
  margin: 0 0 0 $contentMarginXs;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.timeSinceTag {
  @include fontDefault();

  margin-bottom: $contentMarginXs;
}
