@import 'OK/styles/util';

.container {
  &.noBackground {
    background: none;
  }

  &.padTop {
    padding-top: $contentMarginHuge;
  }
}

.contentContainer {
  margin: 0 auto;
  max-width: $contentMaxWidthMobile;
  padding: 0 $contentMarginLg;

  @include forMinScreenWidth(medium) {
    max-width: $contentMaxWidthDesktop;
  }

  &.edgeOverflow {
    max-width: inherit;
    padding: 0 0 $contentMarginXxl;
  }

  &.flex {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    &.alignCenter {
      align-items: center;
    }

    &.alignRight {
      align-items: flex-end;
    }
  }

  &.pageContent {
    padding: 0 $contentMarginXl;
  }

  &.noPopup {
    padding: 0 $contentMarginXl;
  }

  &.noPadding {
    padding: 0;
  }
}
