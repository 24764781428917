@import 'OK/styles/util';

.activeLabel,
.makeActiveButton {
  line-height: $buttonHeight;
  margin: $contentMarginSm 0 0;
}

.container {
  overflow: hidden;
}

.deleteButton {
  margin-bottom: 0;
}

.details {
  padding: $contentMarginMd;
}

.dimensions {
  margin-bottom: $contentMarginSm;
}

.photo {
  background-color: $midtonesColorStatic;
  height: $cardWidthMobile;
  position: relative;
  width: $cardWidthMobile;
}

.spec {
  margin-bottom: 0;
}

.textLanguage {
  margin-bottom: $contentMarginSm;
}

.type {
  margin-bottom: 0;
}
