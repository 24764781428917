@import 'OK/styles/util';

.card {
  display: inline-block;
}

.email {
  line-height: 19px !important;
  height: 19px;
  margin: 0;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.manageButton {
  margin-bottom: $contentMarginSm;

  &:last-child {
    margin-bottom: 0;
  }
}

.mobile {
  line-height: 19px !important;
  height: 19px;
  margin: 0;
  margin-bottom: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name {
  margin-bottom: 2px;
}

.roleTag {
  @include fontDefault();
  margin-top: auto;
}
