@use 'sass:color';
@import 'OK/styles/util';

.actionsContainer {
  line-height: 0;
  display: flex;
  align-items: center;
}

.commenterName {
  margin-bottom: $contentMarginXs;
}

.container {
  border-radius: $cardBorderRadius;
  // margin-bottom: $contentMarginMd;
  padding: $contentMarginSm $contentMarginMd $contentMarginMd;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    height: 12px;
    position: absolute;
    left: -6px;
    width: 12px;
    transform: rotate(45deg);
  }

  &:last-child {
    margin-bottom: 0;
  }

  // Customize UI for comment by current user
  &.postedByCurrentUser {
    &:before {
      left: unset;
      right: -6px;
    }
  }
}

.errorMessage {
  margin: $contentMarginXs 0 0 0;
}

.header {
  display: flex;
  margin-bottom: 15px;
}

.imagePlaceholder {
  height: 44px;
  width: 44px;
  border-radius: 22px;
  background-color: $midtonesColorStatic;
  margin-right: $contentMarginSm;
}

.informationContainer {
  margin-left: auto;
}

.organisationInfoContainer {
  display: flex;
}

.organisationImage {
  border-width: 1px;
  border-style: solid;
  border-radius: 1px;
  height: 20px;
  margin-right: $contentMarginSm;
  width: 20px;
}

.organisationName {
  margin: 0;
}

.actionButton {
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  height: $lineHeightSm;
  margin: 0 $contentMarginMd 0 0;
}

.actionButtonLike {
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  height: 40px;
  margin: 0 $contentMarginMd 0 0;
  border: 1px solid $navigationColor !important ;
  color: $navigationColor !important;
}

.actionButtonLikeUser {
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  height: 40px;
  margin: 0 $contentMarginMd 0 0;
  border: 1px solid $navigationColor !important ;
  color: $contentBackgroundColor !important;
}

.textContainer {
  margin-bottom: $contentMarginSm;

  p:last-child {
    margin-bottom: 0;
  }
}

@include forLightTheme() {
  .container {
    background-color: $contentBackgroundColorCard;

    &:before {
      background-color: $contentBackgroundColorCard;
    }

    &.postedByCurrentUser {
      background-color: $midtonesColorStatic;

      &:before {
        background-color: $midtonesColorStatic;
      }
    }
  }

  .organisationImage {
    border-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .container {
    background-color: $contentBackgroundColorCardDark;

    &:before {
      background-color: $contentBackgroundColorCardDark;
    }

    &.postedByCurrentUser {
      background-color: $midtonesColorStaticDark;

      &:before {
        background-color: $midtonesColorStaticDark;
      }
    }
  }

  .organisationImage {
    border-color: $midtonesColorStaticDark;
  }
}

.timeSinceTag {
  height: $contentMarginLg;
  display: flex !important;
  align-items: center;
}
