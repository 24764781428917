@import 'OK/styles/util';

.carousel {
  padding-left: $contentMarginLg;
  padding-right: $contentMarginLg;
}

.carouselContainer {
  margin: 0 (-$contentMarginLg);

  @include overlayScrollbarsDefaultScrollbarPadding();

  @include forMinScreenWidth(medium) {
    margin: 0;
  }
}

.fileCard {
  width: 100%;
}

.fileContainer {
  width: 100%;
}

.fileLinkContainer {
  margin: 0;
  width: 100%;
}

.fileSlide {
  margin-right: $contentMarginMd;
  flex: 0 0 100%;

  &:last-child {
    > * {
      display: inline-block;
      margin-right: $contentMarginLg;
    }
  }

  @include forMinScreenWidth(medium) {
    flex-basis: $cardWidth3ColumnDesktop;
  }
}

.section {
  padding-bottom: $contentMarginXxl;
  padding-top: $contentMarginXl;
}

.sectionDescription {
  max-width: 400px;
}

.sectionNotice {
  @include forMinScreenWidth(medium) {
    float: right;
    margin-top: 0;
    max-width: 350px;
  }
}
