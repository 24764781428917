@import 'OK/styles/util';

.all {
  align-items: center;
  border: 0;
  display: inline-flex;
  font-weight: inherit;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;
  position: relative;
  text-align: left;
  transition: 100ms all;
}

// Default style

.button {
  @include increaseClickableArea(2px);

  border-radius: $buttonBorderRadius;
  font-size: $fontSizeMd;
  height: $buttonHeight;
  line-height: $buttonHeight;
  padding: 0 $buttonPadding;

  // Loading state

  &.loading {
    .icon {
      animation: loading 1s linear infinite;
    }
  }

  // Inverted style
  &.inverted {
    border-style: solid;
    border-width: 1px;
  }

  // Icon only
  &.iconOnly {
    padding: 0 12px;

    .icon {
      margin: 0;
    }
  }
}

// Link style

.link {
  background: none;
  height: $minClickableHeight;
  line-height: $minClickableHeight;
  padding: 0;
  // width: 75%;

  &:disabled {
    opacity: 0.7;
  }

  // Icon only
  &.iconOnly {
    justify-content: space-around;
    padding: 0 14px;

    .icon {
      margin: 0;
    }
  }
}

// Toggle style

.toggle {
  border-width: 1px;
  border-style: solid;
  border-radius: $buttonBorderRadius;
  font-size: $fontSizeMd;
  height: $buttonHeight;
  line-height: $buttonHeight;
  padding: 0 $buttonPadding;
  overflow: hidden;

  .toggleActiveLayer {
    height: 100%;
    width: 3px;
    position: absolute;
    top: 0;
    left: 0;
    transition: width $timingShort;
    z-index: 0;
  }

  &.toggled {
    .toggleActiveLayer {
      width: 100%;
    }
  }
}

// Block

.block {
  display: flex;
  width: 100%;
}

// Icon

.icon {
  z-index: 1;
  &.flip {
    transform: rotate(180deg);
  }

  // Margin based on positioning
  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  &.left {
    margin-right: $contentMarginSm;
  }

  &.right {
    margin-left: $contentMarginSm;
  }
}

// Loading animation

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
