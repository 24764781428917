@use 'sass:color';
@import 'OK/styles/util';

.container {
  bottom: 0;
  left: 0;
  padding: $contentMarginSm $contentMarginXxl $contentMarginMd;
  position: fixed;
  transition: bottom $timingShort;
  width: 100%;
  z-index: 90;

  &.withStatusBannerOffset {
    bottom: $statusBannerHeight;
  }

  &.withMenuOffset {
    bottom: $mainMenuTabsHeightPlusBottomSafeArea;

    &.withStatusBannerOffset {
      bottom: calc(#{$mainMenuTabsHeightPlusBottomSafeArea} + #{$statusBannerHeight} + 4px);
    }
  }

  @include forMinScreenWidth(medium) {
    &.withMenuOffset {
      bottom: 0;

      &.withStatusBannerOffset {
        bottom: $statusBannerHeight;
      }
    }
  }
}

.innerContainer {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: $floatingButtonsMaxWidthMobile;

  @include forMinScreenWidth(medium) {
    max-width: $floatingButtonsMaxWidthDesktop;
  }

  > * {
    margin: 0 $contentMarginSm 0 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

@include forLightTheme() {
  .container {
    background: linear-gradient(color.change($contentBackgroundColor, $alpha: 0), $contentBackgroundColor);
  }
}

@include forDarkTheme() {
  .container {
    background: linear-gradient(color.change($contentBackgroundColorDark, $alpha: 0), $contentBackgroundColorDark);
  }
}
