@use 'sass:color';
@import 'OK/styles/util';

.buttonMenu {
  align-items: center;
  display: flex;
  padding: 0 $contentMarginLg;

  > * {
    margin-bottom: 0;
  }
}

.closeButton {
  font-weight: bold;
  margin-right: auto;
}

.deleteButton {
  margin-left: 15px;
}

.deleteFloatingButton {
  margin-left: auto;
}

.floatingButtonsContainer {
  display: flex;
  width: $floatingButtonsMaxWidthMobile;

  > * {
    margin-right: $contentMarginSm;

    &:last-child {
      margin-right: 0;
    }
  }

  @include forMinScreenWidth(medium) {
    width: $floatingButtonsMaxWidthDesktop;
  }
}

.floatingSelect {
  flex: 1 1;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1px;
  max-height: calc(100vh - 30px - 70px - 44px);
  overflow-y: scroll;

  &.sortMode {
    .media.selected {
      &::after {
        border-color: $navigationColor;
      }
    }
  }

  &.deleteMode {
    .media.selected {
      &::after {
        border-color: $alertColor;
      }
    }
  }
}

.loadingSpinner {
  display: block;
  margin: $contentMarginLg auto;
}

.media {
  background-color: $midtonesColorStatic;
  cursor: pointer;
  height: 111px;
  margin: 0 1px 1px 0;
  position: relative;
  width: 111px;

  @include forMinScreenWidth(medium) {
    height: 105px;
    width: 105px;
  }

  &.selected {
    &::after {
      border-style: solid;
      border-width: 3px;
      bottom: 2px;
      content: '';
      display: block;
      left: 2px;
      position: absolute;
      right: 2px;
      top: 2px;
    }
  }
}

.mediaGalleryPreview {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.mediaGalleryPreviewIcon {
  height: 30px;
  width: 30px;
}

.mediaPreviewAccessoryContainer {
  bottom: calc($contentMarginLg + 75px);
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.mediaPreviewTag {
  @include fontDefault();
}

.noFindingsLabel {
  margin: $contentMarginLg auto;
}

.popupContent {
  border-top-width: 0;
  padding: 0 0 $contentMarginXs;
  width: $mediaPopupMaxWidthMobile;

  @include forMinScreenWidth(medium) {
    width: $mediaPopupMaxWidthDesktop;
  }
}

.selectAllButton {
  margin: 0 $contentMarginSm 0 auto;
}

.tagIcon {
  border-radius: 6px;
  bottom: 16px;
  height: 12px;
  left: calc(50% - 6px);
  position: absolute;
  width: 12px;

  .dot {
    left: 2px;
    position: absolute;
    top: 2px;
  }
}

.toastContainer {
  position: fixed;
  bottom: 70px + $contentMarginSm;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.selectLabel {
  margin: 0;
  overflow: hidden;
}

.selectLabelIcon {
  margin-right: $contentMarginXs;
}

.thumbnailTagIndicator {
  left: 15px;
  position: absolute;
  top: 42px;
}

.thumbnailTestNumber {
  left: -2px;
  margin: 0;
  position: absolute;
  text-align: center;
  top: -$lineHeightXs;
  width: 100%;
}

@include forLightTheme() {
  .media {
    &::before {
      background-color: color.change($contentBackgroundColorCard, $alpha: 0.5);
    }
  }

  .mediaPreviewTag {
    color: $textColorLight;
  }

  .tagIcon {
    background-color: $contentBackgroundColor;
    box-shadow: #000 0 0 10px;
  }
}

@include forDarkTheme() {
  .media {
    &::before {
      background-color: color.change($contentBackgroundColorCardDark, $alpha: 0.5);
    }
  }

  .mediaPreviewTag {
    color: $textColor;
  }

  .tagIcon {
    background-color: $contentBackgroundColorDark;
    box-shadow: #fff 0 0 10px;
  }
}
