@import 'OK/styles/util';

.container {
  position: relative;
}

.clearSelectionButton {
  height: $inputHeight;
  margin: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.component {
  margin-bottom: 0;
}
