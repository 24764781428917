@import 'OK/styles/util';

.cancelButton {
  margin-left: auto;
}

.document {
  margin-bottom: 16px;
}

.errorMessage {
  position: fixed !important;
  bottom: 70px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.fileName {
  margin-bottom: 8px;
}

.floatingButtons {
  position: sticky !important;
  margin-left: -$contentMarginLg;
  margin-right: -$contentMarginLg;
  width: 100vw !important;
  z-index: 100;
}

.header {
  margin-bottom: $contentMarginLg;
}

.headerCreateButton {
  margin-left: $contentMarginMd;
}

.headerRow {
  display: flex;
}

.newDocumentTypeLabel {
  margin-bottom: $contentMarginSm;
}

.newDocumentTypeSelect {
  margin-bottom: $contentMarginSm;
}

.mediaPicker {
  height: 150px !important;
  padding: 16px !important;
  margin-bottom: 16px;
}

.popupContainer {
  width: 335px;
}

.removeLinkContainer {
  display: block;
  text-align: right;
}

.replaceMediaButton {
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: 247px;
  }
}

.resultCard {
  left: -$contentMarginMd;
  margin-right: -$contentMarginXl;
  position: relative;
  width: calc(100% + ($contentMarginMd * 2));
}

.resultCardContainer {
  flex: 0 0 100%;
  margin: 0 0 $contentMarginMd;
  width: 100%;
}

.resultCardsContainer {
  margin-top: 16px;
}

.searchForm {
  flex: 1;
  margin-right: $contentMarginMd;
  width: calc(100% - #{$contentMarginMd + $minClickableHeight});
}

.searchResultWrapper {
  cursor: pointer;
}

.selected {
  border: 2px solid $navigationColorCard;
  border-radius: $cardBorderRadius;
}

.selectedResultCardContainer {
  flex: 0 0 100%;
  width: 100%;
}

.toastMessage {
  max-width: calc(100% - ($contentMarginLg * 2));
  text-align: center;

  @include forMinScreenWidth(medium) {
    max-width: 315px;
  }
}
