@import 'OK/styles/util';

.language {
  font-weight: bold;
  text-align: left;
}

.region {
  @include fontMono();
  font-size: $fontSizeXs;
  line-height: $fontSizeXs;

  p {
    margin: 0;
  }
}

.regionButton {
  @include increaseClickableArea(2px);
  align-items: center;
  background: none;
  border: 0;
  display: flex;
  min-height: 40px;
  min-width: $minClickableHeight;
  padding: 0;

  > * {
    display: inline-block;
  }
}

.regionButtonIcon {
  margin-right: $contentMarginXs;
  vertical-align: baseline !important;
}

@include forLightTheme() {
  .regionButton {
    color: $textColor;
  }
}

@include forDarkTheme() {
  .regionButton {
    color: $textColorLight;
  }
}

// Theme overrides

.regionButton.theme_override_light {
  color: $textColor;
}

.regionButton.theme_override_dark {
  color: $textColorLight;
}
