@import 'OK/styles/util';

:global(.theme_light) {
  // Button style
  .button {
    background-color: $buttonBackgroundColor;
    color: $buttonTextColor;

    &:hover {
      background-color: $buttonBackgroundColorHover;
    }

    &:active {
      background-color: $buttonBackgroundColorActive;
    }

    &.alert {
      background-color: $alertColorFocus;

      &:hover {
        background-color: $alertColorFocusHover;
      }

      &:active {
        background-color: $alertColorFocusActive;
      }
    }

    &.creation {
      background-color: $creationColorFocus;

      &:hover {
        background-color: $creationColorFocusHover;
      }

      &:active {
        background-color: $creationColorFocusActive;
      }
    }

    &.navigation {
      background-color: $navigationColorFocus;

      &:hover {
        background-color: $navigationColorFocusHover;
      }

      &:active {
        background-color: $navigationColorFocusActive;
      }
    }

    &.notification {
      background-color: $notificationColorFocus;

      &:hover {
        background-color: $notificationColorFocusHover;
      }

      &:active {
        background-color: $notificationColorFocusActive;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColor;
      border: 1px solid $buttonSecondaryBackgroundColor;
      color: $buttonTextColorAlternate;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHover;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActive;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabled !important;
      color: $buttonTextColorAlternate !important;
    }
  }

  // Link style
  .link {
    color: $navigationColorFocus;

    &:hover {
      color: $navigationColorFocusHover;
    }

    &:active {
      color: $navigationColorFocusActive;
    }

    &.alert {
      color: $alertColorFocus;

      &:hover {
        color: $alertColorFocusHover;
      }

      &:active {
        color: $alertColorFocusActive;
      }
    }

    &.creation {
      color: $creationColorFocus;

      &:hover {
        color: $creationColorFocusHover;
      }

      &:active {
        color: $creationColorFocusActive;
      }
    }

    &.notification {
      color: $notificationColorFocus;

      &:hover {
        color: $notificationColorFocusHover;
      }

      &:active {
        color: $notificationColorFocusActive;
      }
    }

    &.secondary {
      color: $brandColor;
    }
  }

  // Toggle
  .toggle {
    background-color: $contentBackgroundColorFocus;
    border-color: $brandColor;

    .toggleActiveLayer {
      background-color: $brandColor;
    }
  }

  // Inverted style
  .button.inverted {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
    color: $buttonTextColorAlternate;

    &:hover {
      background-color: $contentBackgroundColorCard;
    }

    &:active {
      background-color: $contentBackgroundColorFocus;
    }

    // Tints

    &.alert {
      color: $alertColor;

      &:hover {
        color: $alertColorCardHover;
      }

      &:active {
        color: $alertColorCardActive;
      }
    }

    &.creation {
      color: $creationColor;

      &:hover {
        color: $creationColorCardHover;
      }

      &:active {
        color: $creationColorCardActive;
      }
    }

    &.navigation {
      color: $navigationColor;

      &:hover {
        color: $navigationColorCardHover;
      }

      &:active {
        color: $navigationColorCardActive;
      }
    }

    &.notification {
      color: $notificationColor;

      &:hover {
        color: $notificationColorCardHover;
      }

      &:active {
        color: $notificationColorCardActive;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabled !important;
      color: $buttonTextColorAlternate !important;
    }
  }
}

:global(.theme_dark) {
  // Button style
  .button {
    background-color: $buttonBackgroundColorDark;
    color: $buttonTextColorAlternate;

    &:hover {
      background-color: $buttonBackgroundColorHoverDark;
    }

    &:active {
      background-color: $buttonBackgroundColorActiveDark;
    }

    &.alert {
      background-color: $alertColorFocus;
      color: $buttonTextColor;

      &:hover {
        background-color: $alertColorFocusHoverDark;
      }

      &:active {
        background-color: $alertColorFocusActiveDark;
      }
    }

    &.creation {
      background-color: $creationColorFocus;
      color: $buttonTextColor;

      &:hover {
        background-color: $creationColorFocusHoverDark;
      }

      &:active {
        background-color: $creationColorFocusActiveDark;
      }
    }

    &.navigation {
      background-color: $navigationColorFocus;
      color: $buttonTextColor;

      &:hover {
        background-color: $navigationColorFocusHoverDark;
      }

      &:active {
        background-color: $navigationColorFocusActiveDark;
      }
    }

    &.notification {
      background-color: $notificationColorFocus;
      color: $buttonTextColor;

      &:hover {
        background-color: $notificationColorFocusHoverDark;
      }

      &:active {
        background-color: $notificationColorFocusActiveDark;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColorDark;
      border: 1px solid $buttonSecondaryBackgroundColorDark;
      color: $buttonTextColor;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHoverDark;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActiveDark;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabledDark !important;
      color: $buttonTextColor !important;
    }
  }

  // Link style
  .link {
    color: $navigationColorFocus;

    &:hover {
      color: $navigationColorFocusHoverDark;
    }

    &:active {
      color: $navigationColorFocusActiveDark;
    }

    &.alert {
      color: $alertColorFocus;

      &:hover {
        color: $alertColorFocusHoverDark;
      }

      &:active {
        color: $alertColorFocusActiveDark;
      }
    }

    &.creation {
      color: $creationColorFocus;

      &:hover {
        color: $creationColorFocusHoverDark;
      }

      &:active {
        color: $creationColorFocusActiveDark;
      }
    }

    &.notification {
      color: $notificationColorFocus;

      &:hover {
        color: $notificationColorFocusHoverDark;
      }

      &:active {
        color: $notificationColorFocusActiveDark;
      }
    }

    &.secondary {
      color: $brandColorAlternate;
    }
  }

  // Toggle
  .toggle {
    background-color: $contentBackgroundColorFocusDark;
    border-color: $brandColorAlternate;

    .toggleActiveLayer {
      background-color: $brandColorAlternate;
    }
  }

  // Inverted style
  .button.inverted {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
    color: $buttonTextColor;

    &:hover {
      background-color: $contentBackgroundColorCardDark;
    }

    &:active {
      background-color: $contentBackgroundColorFocusDark;
    }

    // Tints

    &.alert {
      color: $alertColor;

      &:hover {
        color: $alertColorCardHoverDark;
      }

      &:active {
        color: $alertColorCardActiveDark;
      }
    }

    &.creation {
      color: $creationColor;

      &:hover {
        color: $creationColorCardHoverDark;
      }

      &:active {
        color: $creationColorCardActiveDark;
      }
    }

    &.navigation {
      color: $navigationColor;

      &:hover {
        color: $navigationColorCardHoverDark;
      }

      &:active {
        color: $navigationColorCardActiveDark;
      }
    }

    &.notification {
      color: $notificationColor;

      &:hover {
        color: $notificationColorCardHoverDark;
      }

      &:active {
        color: $notificationColorCardActiveDark;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabledDark !important;
      color: $buttonTextColor !important;
    }
  }
}
