@import 'OK/styles/util';

.bulkActionButton {
  margin-bottom: 0;
}

.button {
  @include forMinScreenWidth(medium) {
    max-width: 247px;
  }
}

.carousel {
  padding-left: $contentMarginLg;
  padding-right: $contentMarginLg;
}

.carouselContainer {
  margin: 0 (-$contentMarginLg) !important;

  @include forMinScreenWidth(medium) {
    margin: 0 (-$contentMarginMd) !important;
  }
}

.linkCard {
  // margin-right: $contentMarginMd;
  width: $cardWidthMobile;

  @include forMinScreenWidth(medium) {
    width: $cardWidth4ColumnDesktop;
  }
}

.linkSlide {
  margin: 0 $contentMarginSm;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.linksTypeDescription {
  @include forMinScreenWidth(medium) {
    width: 545px;
  }
}

.linksTypeTab {
  font-weight: normal !important;
}

.linksFilterTabs {
  margin-bottom: $contentMarginLg;
}

.linksTypeTabs {
  padding: 0 15px;
}

.linksTypeTabsContainer {
  margin-bottom: $contentMarginLg;
}

.linksContainer {
  padding-bottom: $contentMarginHuge;
  padding-top: $contentMarginLg;
}

.normalFontWeight {
  font-weight: normal;
}

.searchLinksInput {
  margin-bottom: $contentMarginLg;

  @include forMinScreenWidth(medium) {
    width: 400px;
  }
}

@include forLightTheme() {
  .linksTypeTabsContainer {
    border-bottom: 1px solid $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .linksTypeTabsContainer {
    border-bottom: 1px solid $midtonesColorStaticDark;
  }
}
