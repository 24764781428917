@use "sass:math";

@import 'OK/styles/util';

$desktopInfoColumnWidth: 295px;

.basicsContainer {
  padding-bottom: $contentMarginXxl;
  padding-top: $contentMarginLg;
}

.button {
  @include forMinScreenWidth(medium) {
    max-width: $desktopInfoColumnWidth;
  }
}

.commentSection {
  padding-bottom: $contentMarginXl;
}

.commentSectionHeader {
  margin-bottom: $contentMarginLg;
  padding-top: $contentMarginLg;
  border-top-width: 1px;
  border-top-style: solid;
}

.floatingButtons {
  position: sticky;
  bottom: 0 !important;
  margin-top: -$contentMarginMd;
}

@include forLightTheme() {
  .commentSectionHeader {
    border-color: $midtonesColorStatic;
  }

  .commentSection {
    background-color: $contentBackgroundColor;
  }
}

@include forDarkTheme() {
  .commentSectionHeader {
    border-color: $midtonesColorStaticDark;
  }

  .commentSection {
    background-color: $contentBackgroundColorDark;
  }
}

.deleteFileButton {
  margin-bottom: $contentMarginSm;
}

.documentType {
  margin-bottom: $contentMarginLg;
}

.errorMessage {
  margin: -15px 0 15px;
}

.field {
  margin-bottom: $contentMarginMd;
}

.fieldHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginSm;

  @include forMinScreenWidth(medium) {
    max-width: $desktopInfoColumnWidth;
  }

  button {
    margin: 0;
    height: $lineHeightMd;
  }

  .fieldName {
    margin-bottom: 0;
  }
}

.fieldName {
  margin-bottom: $contentMarginSm;
}

.fieldValue {
  margin-bottom: 0;

  @include forMinScreenWidth(medium) {
    max-width: $desktopInfoColumnWidth;
  }
}

.filename {
  overflow-wrap: anywhere;
}

.fileDetailsContainer {
  @include forMinScreenWidth(medium) {
    display: flex;
  }
}

.fileInfoContainer {
  @include forMinScreenWidth(medium) {
    flex: 1 1 400px;
    padding-top: $contentMarginMd;
  }
}

.filePreviewContainer {
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  margin-bottom: $contentMarginLg;
  overflow: hidden;
  position: relative;

  @include forMinScreenWidth(medium) {
    width: 400px;
  }

  &.noPreview {
    align-items: center;
    display: flex;
    justify-content: center;

    > p {
      margin: 0;
    }
  }
}

.header {
  margin-bottom: $contentMarginLg;
}

.layoutContainer {
  padding-top: $mainMenuHeight + ($minClickableHeight * 2);
}

.linksSection {
  padding-bottom: $contentMarginSm !important;
}

.madeByOrganisationCard {
  margin-bottom: 0;
  margin-left: -$contentMarginMd;
  width: calc(100% + #{$contentMarginMd * 2});

  @include forMinScreenWidth(medium) {
    width: $desktopInfoColumnWidth + ($contentMarginMd * 2);
  }
}

.normalFontWeight {
  font-weight: normal;
}

.popupMenuBackground {
  position: fixed;
  z-index: 147;
  background-color: rgba(244, 244, 244, 1);
}

.topBorderPopup {
  background-color: transparent;
}

.popupMenu {
  padding-left: 0;
  position: fixed;
  z-index: 148;
  background-color: white;
  transition: 100ms all;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.refId {
  font-size: $fontSizeCardHeader !important;
  line-height: $lineHeightCardHeader !important;
  margin-left: $contentMarginSm;
  position: relative;
  top: -2px;
}

.reliabilityScore {
  font-weight: bold;
  flex: 0 0 !important;
}

.searchContainer {
  position: relative;
  z-index: 1;

  @include forMinScreenWidth(medium) {
    max-width: $desktopInfoColumnWidth;
  }
}

.searchInput {
  z-index: 1;
}

.searchSuggestions {
  position: absolute;
  top: $inputHeight - $contentMarginXs;
  left: 0;
  right: 0;
  max-height: 215px;
}

.shareToast {
  left: -(math.div(160px - $minClickableHeight, 2));
  position: absolute;
  text-align: center;
  top: $contentMarginXs;
  width: 160px;

  @include forMinScreenWidth(medium) {
    left: -(math.div(160px - 69px, 2));
  }
}

.submenu {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.submenuButton {
  flex: 1 0 $minClickableHeight;
  margin-bottom: 0;

  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginMd;
  }
}

.submenuButtonContainer {
  position: relative;

  button {
    margin-bottom: 0;
  }
}

.submenuButtons {
  align-items: center;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  height: 24px;
  margin-right: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginMd;
    padding-right: 0;
  }
}

.submenuRefId {
  margin-left: $contentMarginXs;
}

.submenuTitle {
  margin: 0 auto 0 0;
}

.tag {
  margin: 0 $contentMarginSm $contentMarginSm 0;
}

.tags {
  margin-top: $contentMarginSm;
}

@include forLightTheme() {
  .basicsContainer {
    background-color: $contentBackgroundColorCard;
  }

  .pageSubmenu {
    background-color: $contentBackgroundColor;
  }

  .filePreviewContainer {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;

    &.noPreview {
      background-color: $midtonesColorStatic;
    }
  }

  .submenuButtons {
    border-right-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .basicsContainer {
    background-color: $contentBackgroundColorCardDark;
  }

  .pageSubmenu,
  .popupMenu,
  .popupMenuBackground {
    background-color: $contentBackgroundColorDark;
  }

  .popupMenu {
    background-color: $contentBackgroundColorDark;
    border-left: 1px solid $midtonesColorStaticDark;
    border-right: 1px solid $midtonesColorStaticDark;
    box-shadow: none !important;
  }

  .popupMenu {
    border-top: 1px solid $midtonesColorStaticDark;
  }

  .filePreviewContainer {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;

    &.noPreview {
      background-color: $midtonesColorStaticDark;
    }
  }

  .submenuButtons {
    border-right-color: $midtonesColorStaticDark;
  }
}
