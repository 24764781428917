@import 'OK/styles/util';

.bold {
  font-weight: bold;
}

.text {
  &.md {
    font-size: $fontSizeMd;
    line-height: $lineHeightMd;
  }

  &.sm {
    font-size: $fontSizeSm;
    line-height: $lineHeightSm;
  }

  &.xs {
    font-size: $fontSizeXs;
    line-height: $lineHeightXs;
  }
}
