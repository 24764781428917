@import 'OK/styles/util';

.container {
  @include fontMono();
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  margin: 0;

  &.md {
    flex: 0 0 64px;
    width: 64px;
  }

  &.sm {
    flex: 0 0 58px;
    width: 58px;
  }

  &.xs {
    flex: 0 0 51px;
    width: 51px;
  }
}

.icon {
  margin-right: $contentMarginSm;
}
