@use 'sass:color';

@import 'OK/styles/util';

:global(.theme_light) {
  // Button style
  .button {
    background-color: $buttonBackgroundColor;
    color: $buttonTextColor;

    &:hover {
      background-color: $buttonBackgroundColorHover;
    }

    &:active {
      background-color: $buttonBackgroundColorActive;
    }

    &.alert {
      background-color: $alertColorCard;

      &:hover {
        background-color: $alertColorCardHover;
      }

      &:active {
        background-color: $alertColorCardActive;
      }
    }

    &.creation {
      background-color: $creationColorCard;

      &:hover {
        background-color: $creationColorCardHover;
      }

      &:active {
        background-color: $creationColorCardActive;
      }
    }

    &.navigation {
      background-color: $navigationColorCard;

      &:hover {
        background-color: $navigationColorCardHover;
      }

      &:active {
        background-color: $navigationColorCardActive;
      }
    }

    &.notification {
      background-color: $notificationColorCard;

      &:hover {
        background-color: $notificationColorCardHover;
      }

      &:active {
        background-color: $notificationColorCardActive;
      }
    }

    &.secondary {
      background-color: $contentBackgroundColor;
      border: 1px solid $contentBackgroundColor;
      color: $buttonTextColorAlternate;

      &:hover {
        background-color: color.adjust($contentBackgroundColor, $lightness: -5%);
      }

      &:active {
        background-color: color.adjust($contentBackgroundColor, $lightness: -10%);
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabled !important;
      color: $buttonTextColorAlternate !important;
    }
  }

  // Link style
  .link {
    color: $navigationColorCard;

    &:hover {
      color: $navigationColorCardHover;
    }

    &:active {
      color: $navigationColorCardActive;
    }

    &.alert {
      color: $alertColorCard;

      &:hover {
        color: $alertColorCardHover;
      }

      &:active {
        color: $alertColorCardActive;
      }
    }

    &.creation {
      color: $creationColorCard;

      &:hover {
        color: $creationColorCardHover;
      }

      &:active {
        color: $creationColorCardActive;
      }
    }

    &.notification {
      color: $notificationColorCard;

      &:hover {
        color: $notificationColorCardHover;
      }

      &:active {
        color: $notificationColorCardActive;
      }
    }

    &.secondary {
      color: $brandColor;
    }
  }

  // Toggle
  .toggle {
    background-color: $contentBackgroundColorCard;
    border-color: $brandColor;

    .toggleActiveLayer {
      background-color: $brandColor;
    }
  }

  // Inverted style
  .button.inverted {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
    color: $buttonTextColorAlternate;

    &:hover {
      background-color: $contentBackgroundColorCard;
    }

    &:active {
      background-color: $contentBackgroundColorFocus;
    }

    // Tints

    &.alert {
      color: $alertColor;

      &:hover {
        color: $alertColorCardHover;
      }

      &:active {
        color: $alertColorCardActive;
      }
    }

    &.creation {
      color: $creationColor;

      &:hover {
        color: $creationColorCardHover;
      }

      &:active {
        color: $creationColorCardActive;
      }
    }

    &.navigation {
      color: $navigationColor;

      &:hover {
        color: $navigationColorCardHover;
      }

      &:active {
        color: $navigationColorCardActive;
      }
    }

    &.notification {
      color: $notificationColor;

      &:hover {
        color: $notificationColorCardHover;
      }

      &:active {
        color: $notificationColorCardActive;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabled !important;
      color: $buttonTextColorAlternate !important;
    }
  }
}

:global(.theme_dark) {
  // Button style
  .button {
    background-color: $buttonBackgroundColorDark;
    color: $buttonTextColorAlternate;

    &:hover {
      background-color: $buttonBackgroundColorHoverDark;
    }

    &:active {
      background-color: $buttonBackgroundColorActiveDark;
    }

    &.alert {
      background-color: $alertColorCard;
      color: $buttonTextColor;

      &:hover {
        background-color: $alertColorCardHoverDark;
      }

      &:active {
        background-color: $alertColorCardActiveDark;
      }
    }

    &.creation {
      background-color: $creationColorCard;
      color: $buttonTextColor;

      &:hover {
        background-color: $creationColorCardHoverDark;
      }

      &:active {
        background-color: $creationColorCardActiveDark;
      }
    }

    &.navigation {
      background-color: $navigationColorCard;
      color: $buttonTextColor;

      &:hover {
        background-color: $navigationColorCardHoverDark;
      }

      &:active {
        background-color: $navigationColorCardActiveDark;
      }
    }

    &.notification {
      background-color: $notificationColorCard;
      color: $buttonTextColor;

      &:hover {
        background-color: $notificationColorCardHoverDark;
      }

      &:active {
        background-color: $notificationColorCardActiveDark;
      }
    }

    &.secondary {
      background-color: $contentBackgroundColorDark;
      border: 1px solid $contentBackgroundColorDark;
      color: $buttonTextColor;

      &:hover {
        background-color: color.adjust($contentBackgroundColorDark, $lightness: 5%);
      }

      &:active {
        background-color: color.adjust($contentBackgroundColorDark, $lightness: 10%);
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabledDark !important;
      color: $buttonTextColor !important;
    }
  }

  // Link style
  .link {
    color: $navigationColorCard;

    &:hover {
      color: $navigationColorCardHoverDark;
    }

    &:active {
      color: $navigationColorCardActiveDark;
    }

    &.alert {
      color: $alertColorCard;

      &:hover {
        color: $alertColorCardHoverDark;
      }

      &:active {
        color: $alertColorCardActiveDark;
      }
    }

    &.creation {
      color: $creationColorCard;

      &:hover {
        color: $creationColorCardHoverDark;
      }

      &:active {
        color: $creationColorCardActiveDark;
      }
    }

    &.notification {
      color: $notificationColorCard;

      &:hover {
        color: $notificationColorCardHoverDark;
      }

      &:active {
        color: $notificationColorCardActiveDark;
      }
    }

    &.secondary {
      color: $brandColorAlternate;
    }
  }

  // Toggle
  .toggle {
    background-color: $contentBackgroundColorCardDark;
    border-color: $brandColorAlternate;

    .toggleActiveLayer {
      background-color: $brandColorAlternate;
    }
  }

  // Inverted style
  .button.inverted {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
    color: $buttonTextColor;

    &:hover {
      background-color: $contentBackgroundColorCardDark;
    }

    &:active {
      background-color: $contentBackgroundColorFocusDark;
    }

    // Tints

    &.alert {
      color: $alertColor;

      &:hover {
        color: $alertColorCardHoverDark;
      }

      &:active {
        color: $alertColorCardActiveDark;
      }
    }

    &.creation {
      color: $creationColor;

      &:hover {
        color: $creationColorCardHoverDark;
      }

      &:active {
        color: $creationColorCardActiveDark;
      }
    }

    &.navigation {
      color: $navigationColor;

      &:hover {
        color: $navigationColorCardHoverDark;
      }

      &:active {
        color: $navigationColorCardActiveDark;
      }
    }

    &.notification {
      color: $notificationColor;

      &:hover {
        color: $notificationColorCardHoverDark;
      }

      &:active {
        color: $notificationColorCardActiveDark;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabledDark !important;
      color: $buttonTextColor !important;
    }
  }
}
