@import 'OK/styles/util';

.action {
  float: right;
  margin-left: $contentMarginSm;
}

.container {
  border-radius: 0 0 $inputBorderRadius $inputBorderRadius;
  overflow: overlay;
  padding: $contentMarginSm 0 $contentMarginSm 0;
}

.container::-webkit-scrollbar {
  width: 1;
}

.iconBackground {
  background-position: center center;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.iconContainer {
  align-items: center;
  border-radius: 20px;
  display: flex;
  float: left;
  height: 40px;
  justify-content: center;
  margin: 2px $contentMarginSm 0 0;
  overflow: hidden;
  position: relative;
  width: 40px;
}

.iconInnerContainer {
  font-size: 0;
  z-index: 1;
}

.mark {
  background: none;
  color: inherit;
  font-weight: bold;
}

.message {
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  margin: $contentMarginSm $contentMarginSm 0;
}

.noResults {
  color: $notificationColor;
  font-size: $fontSizeMd;
  line-height: $lineHeightMd;
}

.subtitle {
  display: flex;
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  margin: 0;
}

.subtitleContainer {
  margin-right: $contentMarginSm;
}

.highlightString {
  direction: rtl;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.highlightStringContainer {
  @include fontDefault();

  align-items: center;
  display: flex;
  flex: 1 1 50%;
  font-size: $fontSizeSm;
  justify-content: flex-end;
  line-height: $lineHeightSm;
  margin-left: auto;
  overflow: hidden;
}

.highlightStringIcon {
  flex: 0 0 1em;
  margin-right: $contentMarginXs;
}

.suggestion {
  cursor: pointer;
  display: block;
  padding: 0 $contentMarginSm;
}

.suggestionContent {
  display: flex;
  justify-content: space-between;
  padding: $contentMarginSm 0;
}

.title {
  margin: 0 $contentMarginLg 0 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@include forLightTheme() {
  .container {
    background-color: $contentBackgroundColor;
    box-shadow: 0 0 15px $shadowColor;
  }

  .highlightStringContainer {
    color: $navigationColor;
  }

  .iconBackground {
    background-color: $midtonesColorStatic;

    &::after {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .suggestion {
    color: $textColor;
    margin: 0;

    &:hover {
      background-color: $contentBackgroundColorCard;
    }

    &:not(:last-child) {
      .suggestionContent {
        border-bottom: 1px solid $midtonesColorStatic;
      }
    }
  }
}

@include forDarkTheme() {
  .container {
    background-color: $contentBackgroundColorDark;
    box-shadow: 0 0 15px $shadowColorDark;
  }

  .highlightStringContainer {
    color: $navigationColor;
  }

  .iconBackground {
    background-color: $midtonesColorStaticDark;

    &::after {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .suggestion {
    color: $textColorLight;

    &:hover {
      background-color: $contentBackgroundColorCardDark;
    }

    &:not(:last-child) {
      .suggestionContent {
        border-bottom: 1px solid $midtonesColorStaticDark;
      }
    }
  }
}
