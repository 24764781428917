@import 'OK/styles/util';

.containerInner {
  display: flex;
  flex-direction: column;
}

.identifierTag {
  margin: 0 $contentMarginSm $contentMarginSm 0;
}

.identifiersSection {
  margin-bottom: $contentMarginSm;

  h4 {
    margin-bottom: $contentMarginSm;
  }
}

.lotResults {
  display: flex;
  flex-direction: column;
  margin-bottom: $contentMarginMd;
}

.media {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  float: right;
  height: 78px;
  margin-left: $contentMarginSm;
  overflow: hidden;
  position: relative;
  width: 78px;
}

.sampleResults {
  margin-bottom: $contentMarginMd;
  max-height: 263px;

  @include forMinScreenWidth(medium) {
    flex-grow: 1;
  }
}

.severityLevelIcon {
  margin: 0 $contentMarginXs;
  position: relative;
  top: -3px;
}

.severityLevelLabel {
  font-size: $fontSizeSm;
  font-weight: normal;
  position: relative;
  top: -2px;
}

.testHeader {
  display: inline-block;
  margin-bottom: $contentMarginXs;
}

.timeSpentSection {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;

  > * {
    margin: 0;
  }
}

.whatToLookForPreview {
  height: $lineHeightMd * 2;
}

@include forLightTheme() {
  .media {
    background-color: $midtonesColorStatic;
    border-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .media {
    background-color: $midtonesColorStaticDark;
    border-color: $midtonesColorStaticDark;
  }
}
