@use "sass:math";
@use 'sass:color';
@import 'OK/styles/util';

$popupWidth: 335px;

.dateNotice {
  margin-bottom: $contentMarginMd;
  margin-left: -$contentMarginMd;
  margin-right: -$contentMarginMd;
  padding: $contentMarginMd $contentMarginMd $contentMarginLg;

  :last-child {
    margin-bottom: 0;
  }
}

.errorMessage {
  margin: $contentMarginXs 0 15px;
}

.fineprint {
  margin: $contentMarginLg 0 0 0;
}

.headerContainer {
  background: linear-gradient(
    to bottom,
    color.change($contentBackgroundColorCard, $alpha: 0.9) 25%,
    transparent 50%,
    color.change($contentBackgroundColorCard, $alpha: 0.9) 75%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $contentMarginMd $contentMarginLg;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hostContainer {
  display: flex;
}

.hostImage {
  height: 88px;
  margin-right: $contentMarginMd;
  width: 88px;
}

.hostName {
  margin-bottom: $contentMarginSm;
}
.hostOrganisation {
  margin-bottom: 0;
}
.hostJobTitle {
  margin-bottom: 0;
}

.inlineLink {
  display: inline;
  margin: 0;
}

.mainContainer {
  padding: $contentMarginLg $contentMarginLg $contentMarginXl;
}

.marketingOptInCheckbox {
  margin-right: 15px;
}

.marketingOptInContainer {
  display: flex;
  margin-bottom: $contentMarginLg;
}

.marketingOptInLabel {
  margin-bottom: $contentMarginSm;
  margin-top: 4px;
}

.marketingOptInOptionalLabel {
  margin-bottom: 0;
}

.mediaContainer {
  background-position: center;
  background-size: cover;
  height: $popupWidth;
  position: relative;
}

.noWebinarFoundMessage {
  margin: $contentMarginLg 0;
  text-align: center;
}

.shareButton {
  margin: 0;
}

.shareButtonFloatingContainer {
  .shareToast {
    left: -(math.div(190px - 99px, 2));
    top: calc(0px - 40px - 53px - 10px);
  }
}

.shareButtonHeaderContainer {
  margin: 0 15px 0 auto;

  .shareToast {
    left: -(math.div(190px - 69px, 2));
    top: $contentMarginXs;
  }
}

.shareToast {
  position: absolute;
  text-align: center;
  width: 190px !important;
}

.titleContainer {
  align-items: center;
  display: flex;

  > * {
    margin-bottom: 0;
  }
}

.popup {
  overflow: hidden;
  padding: 0;
  width: $popupWidth;
}

.registerButton {
  margin-bottom: 0;
}

.registerContainer {
  padding: $contentMarginLg $contentMarginLg $contentMarginXl;
}

.registrationInput {
  margin-bottom: $contentMarginMd;
}

.registrationLabel {
  margin-bottom: $contentMarginSm;
}

.webinarLoadingSpinner {
  display: block;
  margin: $contentMarginLg auto;
}

@include forLightTheme() {
  .registerContainer {
    background-color: $contentBackgroundColorCard;
  }
}

@include forDarkTheme() {
  .registerContainer {
    background-color: $contentBackgroundColorCardDark;
  }
}
