@import 'OK/styles/util';

@include forLightTheme() {
  .alert {
    color: $alertColorCard;
  }

  .creation {
    color: $creationColorCard;
  }

  .navigation {
    color: $navigationColorCard;
  }

  .notification {
    color: $notificationColorCard;
  }
}

@include forDarkTheme() {
  .alert {
    color: $alertColorCardDark;
  }

  .creation {
    color: $creationColorCardDark;
  }

  .navigation {
    color: $navigationColorCardDark;
  }

  .notification {
    color: $notificationColorCardDark;
  }
}
