@import 'OK/styles/util';

.actionButtonUpper {
  margin-top: -5px;
}

.inputField {
  margin-bottom: $contentMarginMd;
}

.inputFieldLast {
  margin-bottom: $contentMarginLg;
}

.header {
  display: flex;
  justify-content: space-between;
}

.organisationCard {
  margin-bottom: 0;
  margin-left: -$contentMarginMd;
  margin-right: -$contentMarginMd;
}

.organisationCardContainer {
  cursor: pointer;
  margin-bottom: $contentMarginMd;
}
