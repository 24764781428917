@use 'sass:color';
@import 'OK/styles/util';

.section {
  padding-bottom: $contentMarginXxl;
  padding-top: $contentMarginXl;
}

@include forDarkTheme() {
  .section {
    background-color: $contentBackgroundColorDark;
  }
}

.contactCard {
  height: 100%;
  width: 100%;
}

.contactSlide {
  margin: 0 $contentMarginSm;
  width: 100%;
  flex: 0 0 100%;
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;

    > * {
      display: inline-block;
      margin-right: $contentMarginLg;
    }
  }

  @include forMinScreenWidth(medium) {
    width: $cardWidthDesktop;
    flex: 0 0 $cardWidthDesktop;
  }
}

.contactsCarouselInner {
  padding-left: $contentMarginLg;
  padding-right: $contentMarginLg;
}

.contactsCarouselOuter {
  margin: 0 (-$contentMarginLg);

  @include overlayScrollbarsDefaultScrollbarPadding();

  @include forMinScreenWidth(medium) {
    margin: 0;
  }
}
