@import 'OK/styles/util';

.checkbox {
  height: 16px;
  margin-right: $contentMarginSm;
  width: 16px;
}

.checkboxContainer {
  align-items: center;
  display: flex;
}

.confirmChangeButton {
  margin-bottom: 0;
}

.label {
  display: block;
  font-weight: bold;
  margin-bottom: $contentMarginMd;
}

.labelError {
  font-weight: normal;
  margin: $contentMarginXs 0 (-$contentMarginXs);
}

.saveButton {
  margin: $contentMarginLg 0 $contentMarginSm 0;
}

.select {
  margin-top: $contentMarginSm;
}
