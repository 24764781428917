@import 'OK/styles/util';

:global(.theme_light) {
  .link {
    color: $navigationColor;

    &:hover {
      color: $navigationColorHover;
    }

    &:active {
      color: $navigationColorActive;
    }

    &.alert {
      color: $alertColor;

      &:hover {
        color: $alertColorHover;
      }

      &:active {
        color: $alertColorActive;
      }
    }

    &.creation {
      color: $creationColor;

      &:hover {
        color: $creationColorHover;
      }

      &:active {
        color: $creationColorActive;
      }
    }

    &.notification {
      color: $notificationColor;

      &:hover {
        color: $notificationColorHover;
      }

      &:active {
        color: $notificationColorActive;
      }
    }
  }

  .button {
    background-color: $buttonBackgroundColor;
    color: $buttonTextColor;

    &:hover {
      background-color: $buttonBackgroundColorHover;
      color: $buttonTextColor;
    }

    &:active {
      background-color: $buttonBackgroundColorActive;
      color: $buttonTextColor;
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabled;
      color: $buttonTextColor;
    }

    &.alert {
      background-color: $alertColor;

      &:hover {
        background-color: $alertColorHover;
      }

      &:active {
        background-color: $alertColorActive;
      }
    }

    &.creation {
      background-color: $creationColor;

      &:hover {
        background-color: $creationColorHover;
      }

      &:active {
        background-color: $creationColorActive;
      }
    }

    &.navigation {
      background-color: $navigationColor;

      &:hover {
        background-color: $navigationColorHover;
      }

      &:active {
        background-color: $navigationColorActive;
      }
    }

    &.notification {
      background-color: $notificationColor;

      &:hover {
        background-color: $notificationColorHover;
      }

      &:active {
        background-color: $notificationColorActive;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColor;
      color: $buttonTextColorAlternate;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHover;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActive;
      }
    }
  }
}

:global(.theme_dark) {
  .link {
    color: $navigationColor;

    &:hover {
      color: $navigationColorHoverDark;
    }

    &:active {
      color: $navigationColorActiveDark;
    }

    &.alert {
      color: $alertColor;

      &:hover {
        color: $alertColorHoverDark;
      }

      &:active {
        color: $alertColorActiveDark;
      }
    }

    &.creation {
      color: $creationColor;

      &:hover {
        color: $creationColorHoverDark;
      }

      &:active {
        color: $creationColorActiveDark;
      }
    }

    &.notification {
      color: $notificationColor;

      &:hover {
        color: $notificationColorHoverDark;
      }

      &:active {
        color: $notificationColorActiveDark;
      }
    }
  }

  .button {
    background-color: $buttonBackgroundColorDark;
    color: $buttonTextColorAlternate;

    &:hover {
      background-color: $buttonBackgroundColorHoverDark;
    }

    &:active {
      background-color: $buttonBackgroundColorActiveDark;
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabledDark;
    }

    &.alert {
      background-color: $alertColor;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $alertColorHoverDark;
      }

      &:active {
        background-color: $alertColorActiveDark;
      }
    }

    &.creation {
      background-color: $creationColor;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $creationColorHoverDark;
      }

      &:active {
        background-color: $creationColorActiveDark;
      }
    }

    &.navigation {
      background-color: $navigationColor;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $navigationColorHoverDark;
      }

      &:active {
        background-color: $navigationColorActiveDark;
      }
    }

    &.notification {
      background-color: $notificationColor;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $notificationColorHoverDark;
      }

      &:active {
        background-color: $notificationColorActiveDark;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColorDark;
      color: $buttonTextColor;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHoverDark;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActiveDark;
      }
    }
  }
}
