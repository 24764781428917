@use 'sass:math';
@import 'OK/styles/util';

.contentContainer {
  padding-top: $mainMenuHeight + ($minClickableHeight * 2);

  .refId {
    font-size: $fontSizeCardHeader;
    height: 40px;
    line-height: 40px;
    margin-left: $contentMarginSm;
    padding: 0 $contentMarginSm;
  }
}

.contentContainerPopup {
  .refId {
    font-size: $fontSizeCardHeader;
    height: 40px;
    line-height: 40px;
    margin-left: $contentMarginSm;
    padding: 0 $contentMarginSm;
  }
}

.commentSectionHeader {
  margin-bottom: $contentMarginLg;
  padding-top: $contentMarginLg;
  border-top-width: 1px;
  border-top-style: solid;
}

.commentSection {
  padding-bottom: $contentMarginXl;
}

@include forLightTheme() {
  .commentSectionHeader {
    border-color: $midtonesColorStatic;
  }

  .commentSection {
    background-color: $contentBackgroundColor;
  }
}

@include forDarkTheme() {
  .commentSectionHeader {
    border-color: $midtonesColorStaticDark;
  }

  .commentSection {
    background-color: $contentBackgroundColorDark;
  }
}

.contactsSection {
  padding-bottom: $contentMarginXxl;
  margin-top: -$contentMarginLg;
}

.headerSection {
  padding-top: $contentMarginLg;
}

.layoutContainer {
  padding-top: $mainMenuHeight + ($minClickableHeight * 2);
}

.okQualityTooltip {
  @include fontDefault();
  left: -19px;
  line-height: $lineHeightXs;
  padding: 2px 5px;
  top: 4px;
  white-space: nowrap;
}

.pageHeader {
  margin-bottom: 0;
}

.shareToast {
  left: -(math.div(160px - $minClickableHeight, 2));
  position: absolute;
  text-align: center;
  top: $contentMarginXs;
  width: 160px;

  @include forMinScreenWidth(medium) {
    left: -(math.div(160px - 79px, 2));
  }
}

.submenu {
  align-items: center;
  display: flex;
  width: 100%;

  button {
    margin-bottom: 0;
    margin-left: $contentMarginSm;
  }
}

.popupMenuBackground {
  position: fixed;
  z-index: 147;
  background-color: rgba(244, 244, 244, 1);
}

.topBorderPopup {
  background-color: transparent;
}

.popupMenu {
  padding-left: 0;
  position: fixed;
  z-index: 148;
  background-color: white;
  transition: 100ms all;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.submenuButtonContainer {
  position: relative;

  button {
    margin-bottom: 0;
  }
}

.submenuRefId {
  display: block;
  margin: 0 auto 0 $contentMarginXs;
}

.submenuTitle {
  margin-bottom: 0;
}

.submenuButton {
  flex-basis: $minClickableHeight;
  flex-shrink: 0;
  height: $minClickableHeight;
  margin: 0;
  justify-content: center;
  position: relative;

  @include forMinScreenWidth(medium) {
    flex-basis: auto;
    margin-right: 5px;
  }

  button {
    margin: 0;
  }
}

.summarySection {
  padding: $contentMarginLg 0 $contentMarginXxl;
}

.toastContainer {
  position: fixed;
  bottom: $contentMarginLg;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

@include forLightTheme() {
  .headerSection,
  .section:nth-child(even) {
    background-color: $contentBackgroundColorCard;
  }

  .pageSubmenu {
    background-color: $contentBackgroundColor;
  }
}

@include forDarkTheme() {
  .headerSection,
  .section:nth-child(even) {
    background-color: $contentBackgroundColorCardDark;
  }

  .pageSubmenu,
  .popupMenu,
  .popupMenuBackground {
    background-color: $contentBackgroundColorDark;
  }

  .popupMenu {
    background-color: $contentBackgroundColorDark;
    border-left: 1px solid $midtonesColorStaticDark;
    border-right: 1px solid $midtonesColorStaticDark;
    box-shadow: none !important;
  }

  .popupMenu {
    border-top: 1px solid $midtonesColorStaticDark;
  }
}
