@import 'OK/styles/util';

.basicsSection {
  @include forMinScreenWidth(medium) {
    width: 100%;
  }
}

.field {
  margin-bottom: $contentMarginLg;
}
