@import 'OK/styles/util';

.container {
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  margin: 0 auto;
  padding: $contentMarginSm $contentMarginSm $contentMarginMd;
  width: fit-content;

  > p {
    margin: 0;
  }
}

@include forLightTheme() {
  .container {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;

    // Tints
    &.alert {
      color: $alertColor;
    }

    &.base {
      color: $textColor;
    }
  }
}

@include forDarkTheme() {
  .container {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;

    // Tints
    &.alert {
      color: $alertColor;
    }

    &.base {
      color: $textColorLight;
    }
  }
}
