@import 'OK/styles/util';

.footnotes {
  counter-reset: footnote-counter;
  list-style: none;
  margin: 0;
  padding: 0 0 0 $contentMarginMd;

  .footnote {
    counter-increment: footnote-counter;
    margin-bottom: $contentMarginSm;
    position: relative;

    > p {
      margin: 0;
    }

    &::before {
      // content: counter(footnote-counter);
      font-size: 65%;
      font-weight: bold;
      left: -20px;
      position: absolute;
      top: -5px;
    }
  }
}
