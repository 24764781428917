@import 'OK/styles/util';

.menu {
  pointer-events: auto;
}

.popupMenu {
  background-color: rgba(244, 244, 244, 0.9) !important;
}

@include forDarkTheme() {
  .popupMenu {
    background-color: $contentBackgroundColorDark !important;
  }
}