@import 'OK/styles/util';

.approvedLabel {
  margin-bottom: 4px;
}

.content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-bottom: 0 !important;
}
.dateText {
  margin-bottom: 0;
  height: $lineHeightMd !important;
}

.header {
  margin-bottom: 5px;
}

.nameText {
  height: $lineHeightMd !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0 !important;
  width: 100%;
}

.okidFooter {
  flex-direction: row !important;
}

.productInfo {
  margin-bottom: 4px;
}

.productTag {
  top: 0;
}

.seeProductLink {
  margin: 0 0 0 $contentMarginXs;
}

.testResults {
  margin-top: auto;
}

.timestamp {
  margin-bottom: 5px;
  @include fontDefault();
}

.selector {
  margin-bottom: 0;
  height: $lineHeightMd !important;
  position: relative;
}

.stageIcon {
  margin-right: $contentMarginSm;
}

.stageButton {
  height: 14px;
  margin-bottom: 0 !important;
  z-index: 5 !important;
}
