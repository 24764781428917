@import 'OK/styles/util';

.container {
  width: 100%;
}

.cycleCamerasButton {
  margin: 0 auto 0 0;
}

.instructions {
  p {
    margin: 0;
  }
}

.instructionsContainer {
  display: flex;
  padding: $contentMarginMd $contentMarginLg;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.instructionsIcon {
  flex: 0 0 44px;
  height: 44px;
  margin-right: $contentMarginMd;
  width: 44px;
}

.nav {
  display: flex;
  height: $minClickableHeight;
  justify-content: space-between;
  padding: 0 $contentMarginLg;
}

.noCameraContainer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.noCameraContent {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.outerContainer {
  align-items: center;
  background-color: $contentBackgroundColorFocus;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;
}

.reportButton {
  margin: 0;
}

.scanGuidelines {
  bottom: 0;
  height: 150px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 150px;
}

.scanner {
  display: block;
  min-height: 100%;
  min-width: 100%;
  position: relative;
}

.scannerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.search {
  bottom: 0;
  height: $inputHeight;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.searchInput {
  margin: 0 auto;
  width: 260px;
  z-index: 1;
}

.searchLink {
  height: inherit;
  line-height: inherit;
  margin: 0;
}

.toastContainer {
  position: absolute;
  width: 100%;
}

.toast {
  &.alignSide {
    margin: 0 $contentMarginMd 0 auto;
  }
}

.uploadButton {
  display: block;
  margin: $contentMarginMd auto;
}

@include forLightTheme() {
  .instructions,
  .instructionsContainer {
    background-color: $contentBackgroundColorFocus;
  }

  .nav {
    background-color: $contentBackgroundColor;
  }
}

@include forDarkTheme() {
  .instructions,
  .instructionsContainer {
    background-color: $contentBackgroundColorFocusDark;
  }

  .nav {
    background-color: $contentBackgroundColorDark;
  }
}
