@import 'OK/styles/util';

.cancelButton {
  margin: 0;
}

.cancelHeaderButton {
  float: right;
  margin: 0;
  position: relative;
  top: -4px;
}

.createButton {
  margin-bottom: $contentMarginSm;
}

.createErrorMessage {
  margin: -$contentMarginXs 0 $contentMarginXs 0;
}

.errorMessage {
  margin: -15px 0 15px 0;
}

.newOKIDsFinePrint {
  margin: $contentMarginSm 0 0;
}

.newOKIDsNotice {
  font-size: $fontSizeSectionHeader;
  font-weight: bold;
  line-height: $lineHeightSectionHeader;
  margin: 0;
}

.popupContent {
  min-height: 90vh;
  overflow: hidden;
  padding-left: $contentMarginSm;
  padding-right: $contentMarginSm;
  position: relative;
  width: 335px;
}

.productOrItemCard {
  margin: 0 0 0 (-$contentMarginMd);
}

.rerunLabelCard {
  margin-left: -$contentMarginMd;
}

.sectionButtons {
  margin-bottom: $contentMarginMd;
  width: 100%;
}

.sectionHeaderContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;
  width: 100%;

  > * {
    margin-bottom: 0;
  }
}

.sourceTypeCard {
  margin: 0 (-$contentMarginMd);
}

.templateCard {
  height: 100%;
}

.templateSlide {
  margin-left: $contentMarginSm;
  margin-right: $contentMarginSm;

  &:last-child {
    > * {
      margin-right: $contentMarginSm;
    }
  }
}

.templatesCarousel {
  align-items: stretch;
}

.templatesCarouselContainer {
  margin: 0 (-$contentMarginSm) $contentMarginSm;
}
