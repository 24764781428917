@import 'OK/styles/util';

.data {
  margin: 0;
}

.footnotes {
  margin-top: $contentMarginMd !important;
}

.modeToggle {
  @include fontDefault();
  cursor: pointer;
  margin: $contentMarginMd $contentMarginSm 0 0;
  padding: 7px 12px;
}

.table {
  table-layout: fixed;
  width: 100%;

  td,
  th {
    text-align: right;
    width: 65px;

    &:first-child {
      text-align: left;
      width: auto;
    }
  }

  thead {
    th {
      padding-bottom: $contentMarginMd;
    }
  }
}

@include forLightTheme() {
  .modeToggle {
    background-color: $midtonesColorStatic;

    &:hover {
      background-color: $midtonesColorStaticHover;
    }

    &:active {
      background-color: $midtonesColorStaticActive;
    }

    &.active {
      background-color: $navigationColor;
      color: $textColorLight;
    }
  }
}

@include forDarkTheme() {
  .modeToggle {
    background-color: $midtonesColorStaticDark;

    &:hover {
      background-color: $midtonesColorStaticDarkHover;
    }

    &:active {
      background-color: $midtonesColorStaticDarkActive;
    }

    &.active {
      background-color: $navigationColor;
      color: $textColorLight;
    }
  }
}
