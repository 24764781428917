@import 'OK/styles/util';

.borderBottom {
  display: block;
  height: 1px;
  margin-top: 15px;
  padding: 0 15px 0;
}

.container {
  padding: 0;
}

.image {
  height: 40px;
  margin-right: $contentMarginSm;
  width: 40px;

  &.organisation {
    border-radius: 2px;
  }

  &.user {
    border-radius: 20px;
  }
}

.name {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.okid {
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  margin-bottom: 0;

  @include fontMono();
}

.permissionButton {
  border: none;
  border-radius: 2px;
  font-size: $fontSizeXs;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  margin-bottom: 0;
  margin-right: $contentMarginSm;
  padding: 5px 9px;

  @include increaseClickableArea(7px, 0px);
}

.removeButton {
  flex: 0 0 60px;
  height: $lineHeightMd;
  margin: 0;

  @include increaseClickableArea(10px, 0px);
}

.sharedToInfo {
  display: flex;
  margin-right: $contentMarginSm;
  width: calc(100% - 70px);
}

.sharedToNameContainer {
  overflow: hidden;
}

.topRow {
  display: flex;
  margin-bottom: $contentMarginMd;
}

@include forLightTheme() {
  .borderBottom {
    background-color: $midtonesColorStatic;
  }

  .permissionButton {
    &.active {
      color: $textColorLight;
    }

    &:not(.active) {
      background-color: $midtonesColorStatic;
      color: $textColor;
    }

    &:not(.active):hover {
      background-color: #d6d6d6;
    }
  }
}

@include forDarkTheme() {
  .borderBottom {
    background-color: $midtonesColorStaticDark;
  }

  .permissionButton {
    color: $textColorLight;

    &:not(.active) {
      background-color: $midtonesColorStaticDark;
    }

    &:not(.active):hover {
      background-color: #505050;
    }
  }
}
