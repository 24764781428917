@use "sass:math";

@import 'OK/styles/util';

$desktopDescriptionWidth: 400px;

.sectionDescription {
  @include forMinScreenWidth(medium) {
    width: $desktopDescriptionWidth;
  }
}

.recentInspectionLogCard {
  margin-bottom: 0;
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: auto;
  }
}

.recentInspectionLogCardInner {
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: auto;
  }
}

.recentInspectionLogSlide {
  margin: 0 $contentMarginSm;
  width: 100%;
  flex: 0 0 100%;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    > * {
      margin-right: $contentMarginSm;
    }
  }

  @include forMinScreenWidth(medium) {
    width: auto;
    flex: 0 0 auto;

    &:first-child {
      margin-left: $contentMarginSm;
    }

    &:last-child {
      > * {
        margin-right: $contentMarginSm;
      }
    }
  }
}

.recentInspectionsCarousel {
  margin: 0 (-$contentMarginLg) !important;

  @include forMinScreenWidth(medium) {
    margin: 0 (-$contentMarginXxl) !important;
  }
}

.recentInspectionsCarouselInner {
  padding: 0 $contentMarginSm;
  :global(.os-scrollbar-horizontal) {
    padding: 0 $contentMarginXxl !important;
  }
  @include forMinScreenWidth(medium) {
    :global(.os-scrollbar-horizontal) {
      padding: 0 $contentMarginXl !important;
    }
  }
}
