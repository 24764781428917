@import 'OK/styles/util';

.button,
.searchInput {
  margin: 0;
}

.isoTag {
  margin-right: $contentMarginSm;
}

.searchSuggestionsContainer {
  position: relative;
}

.searchSuggestions {
  max-height: 215px;
  position: absolute;
  width: 100%;
  z-index: 3;
}

.selectCaretIcon {
  position: relative;
  top: -2px;
  transform: rotate(90deg);
}

.selectedLanguageTag {
  margin-right: $contentMarginXs;
}

.suggestionHighlight {
  background: none;
  color: inherit;
  font-weight: bold;
}
