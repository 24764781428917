@import 'OK/styles/util';

.accordion {
  &.open {
    .header {
      margin-bottom: $contentMarginMd;
    }
  }
}

.editButton {
  margin: 0 $contentMarginLg 0 0;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  transition: margin ease $timingShort;
}

.headerHide {
  cursor: pointer;
}

.title {
  margin: 0;
}

.toggleButton {
  margin: 0;
}

.toggleIcon {
  position: relative;
  top: 4px;
  transform: rotate(-90deg);
  transition: all $timingShort ease;

  &.closed {
    top: -2px;
    transform: rotate(90deg);
  }
}
