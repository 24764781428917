@import 'OK/styles/util';

.addButton,
.searchInput {
  margin: 0;
}

.button {
  white-space: nowrap;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.isoTag {
  margin-right: $contentMarginSm;
}

.searchSuggestionsContainer {
  position: relative;
  z-index: 2;
}

.searchSuggestions {
  max-height: 215px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.selectCaretIcon {
  position: relative;
  top: -2px;
  transform: rotate(90deg);
}

.suggestionHighlight {
  background: none;
  color: inherit;
  font-weight: bold;
}
