@import 'OK/styles/util';

.editButton {
  margin-bottom: $contentMarginSm;
}

.header {
  display: inline-block;
  margin: 0 $contentMarginSm $contentMarginSm 0;
}

.inputAccessoryButton {
  padding-right: 15px;
}

.inspectionPartnersError,
.testError {
  margin: $contentMarginXs 0 $contentMarginXs;
}

.links {
  margin-top: $contentMarginMd;
}

.nameHeader {
  margin-bottom: $contentMarginSm;
}

.organisation {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin: $contentMarginSm 0 0;
  padding-bottom: $contentMarginMd;

  &:last-child {
    border-bottom-style: none;
  }
}

.organisationCountryCode {
  margin-left: $contentMarginXs;
}

.organisationName {
  margin: 0;
}

.organisationPhoto {
  border-radius: $cardBorderRadius;
  float: right;
  height: 50px;
  width: 50px;
}

.points {
  float: right;
}

.pointsHeader {
  margin-bottom: $contentMarginSm;
}

p.pointsLabel {
  margin-bottom: 0;
}

.publishError {
  margin: -15px 0 15px;
}

p.publishLabel {
  margin: 0 auto 0 0;
}

.publishNotice {
  font-size: $fontSizeMd !important;
  line-height: $lineHeightMd !important;
  margin-bottom: $contentMarginMd !important;
  margin-top: 0 !important;
}

.publishRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;
}

.publishedToggle {
  margin-left: $contentMarginSm;
}

.refId {
  float: right;
  position: relative;
  top: 3px;
}

.refIdStyle {
  @include fontMono();
}

.reorderButton {
  @include increaseClickableArea(10px, 0);
  height: 24px;
  margin: 0;

  &.left {
    img {
      transform: rotate(180deg);
    }
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}

.reorderLabel {
  margin: 0 auto;
}

.reorderView {
  align-items: center;
  display: flex;
}

.revokeButton {
  margin: 0;
}

.searchContainer {
  position: relative;
}

.searchInput {
  z-index: 1;
}

.searchSuggestionButton {
  height: $lineHeightMd;
  margin: 0;
}

.searchSuggestions {
  position: absolute;
  top: $inputHeight - $contentMarginXs;
  width: 100%;
}

.section {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: $contentMarginMd;
  padding-bottom: $contentMarginSm;

  &:last-child {
    border-bottom-width: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.testContainer {
  margin-bottom: $contentMarginSm;
}

.unlinkButton {
  margin: 0;
}

.unlinkError {
  margin: $contentMarginXs 0 0 0;
}

.unsaved {
  border-style: dashed;
  border-width: 2px;
}

@include forLightTheme() {
  .organisation {
    border-color: $midtonesColorStatic;
  }

  .section {
    border-color: $midtonesColorStatic;
  }

  .unsaved {
    background-color: $contentBackgroundColor;
    border-color: $brandColor;
  }
}

@include forDarkTheme() {
  .organisation {
    border-color: $midtonesColorStaticDark;
  }

  .section {
    border-color: $midtonesColorStaticDark;
  }

  .unsaved {
    background-color: $contentBackgroundColorDark;
    border-color: $brandColorAlternate;
  }
}
