@import 'OK/styles/util';

.comment {
  margin-top: $contentMarginMd;
  transition: margin $timingShort ease;

  &:first-child {
    margin-top: 0;
  }
}

.loadMoreButton {
  &:not(.inputSpacing) {
    margin-bottom: 0;
  }
}

.newCommentButton {
  margin: 0;
}

.positioningContainer {
  transition: border-width $timingShort ease, margin $timingShort ease, padding $timingShort ease;

  &.nested {
    border-left-width: 3px;
    border-left-style: solid;
    margin-bottom: $contentMarginMd;
    margin-left: $contentMarginSm;
    margin-top: $contentMarginSm;
    padding-left: $contentMarginLg;
    padding-top: $contentMarginSm;
  }

  &:not(.nested) {
    .comment.lastChild {
      margin-bottom: $contentMarginMd;
    }
  }
}

@include forLightTheme() {
  .positioningContainer {
    &.nested {
      border-left-color: $midtonesColorStatic;
    }
  }
}

@include forDarkTheme() {
  .positioningContainer {
    &.nested {
      border-left-color: $midtonesColorStaticDark;
    }
  }
}
