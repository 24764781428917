@import 'OK/styles/util';

button.container {
  align-items: center;
  display: flex !important;
  flex: 0 0 $minClickableHeight;
  height: $minClickableHeight;
  margin: 0 $contentMarginMd;
  padding: 0;

  .checkbox {
    height: 16px;
    width: 16px;
  }
}

.label {
  margin: 0 0 0 $contentMarginMd;
}

.radio {
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  height: 16px;
  width: 16px;

  .filled {
    background-color: $navigationColor;
    border-radius: 50%;
    height: 8px;
    margin: 2px;
    width: 8px;
  }
}

@include forLightTheme() {
  button.container {
    color: $brandColor;
  }

  .radio {
    border-color: $brandColor;
  }
}

@include forDarkTheme() {
  button.container {
    color: $brandColorAlternate;
  }

  .radio {
    border-color: $brandColorAlternate;
  }
}
