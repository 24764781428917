@use 'sass:color';
@import 'OK/styles/util';

/* Variables */

$fadeInSpeed: $timingShort;
$fadeInSpeedFast: $timingInstant;
$fadeOutSpeed: $timingLong;
$fadeOutSpeedFast: $timingShort;

/* Mixins */

@mixin hide {
  opacity: 0;
  pointer-events: none;
}

@mixin hidePlaybackButtons {
  .playbackButtons button {
    @include hide();
  }
}

@mixin hidePlaybackTimestamp {
  .playbackTimestamp {
    @include hide();
  }
}

@mixin hideVideoMenu {
  .videoMenuButtons {
    @include hide();
  }
}

@mixin hideVolume {
  .volume {
    @include hide();
  }
}

@mixin show {
  opacity: 1;
  pointer-events: auto;
}

@mixin showPlaybackButtons {
  .playbackButtons button {
    @include show();
  }
}

@mixin showPlaybackTimestamp {
  .playbackTimestamp {
    @include show();
  }
}

@mixin showPlayPauseButton {
  .playPauseButton {
    @include show();
  }
}

@mixin showProgressHandle {
  .progress .played::before {
    opacity: 1;
  }
}

@mixin showVideoMenu {
  .videoMenuButtons {
    @include show();
  }
}

@mixin showVolume {
  .volume {
    @include show();
  }
}

@mixin videoControlsActive {
  .videoMenuBackground {
    opacity: 1;
    transition: opacity $fadeInSpeedFast;
  }
}

/* Styles */

.layout {
  height: 0;
  padding-top: 100%;
  position: relative;
  width: 100%;

  &.simulatedFullscreenLayout {
    background-color: $tutorialVideoBackgroundColor;
    left: 0;
    margin: 0;
    padding-top: 0;
    position: fixed;
    top: 0;
    z-index: 300;
  }
}

.container {
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateX(0);
  width: 100%;

  :global(.video-js) {
    @include fontDefault();
    border-radius: $cardBorderRadius;
    height: 100%;
    outline: none;
    overflow: hidden;
    padding: 0;
    width: 100%;
  }

  .fastForwardButton,
  .rewindButton {
    @include increaseClickableArea(6px);
  }

  .hoverTimestamp {
    bottom: $contentMarginMd;
    margin: 0;
    width: 50px;
  }

  .loadingSpinner {
    animation: loading 1s linear infinite;
    height: 64px;
    left: calc(50% - 32px);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: calc(50% - 32px);
    width: 64px;
  }

  .playbackButtons {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    top: calc(50% - 32px);
    width: 100%;

    button {
      @include hide();
      background: none;
      border: 0;
      margin: 0;
      padding: 0;
      transition: opacity $fadeOutSpeedFast;
    }

    .playPauseButton {
      margin: 0 $contentMarginLg;
    }
  }

  .playbackTimestamp {
    @include hide();
    bottom: $contentMarginLg;
    font-size: $fontSizeMd;
    line-height: $lineHeightMd;
    margin: 0;
    position: absolute;
    text-align: center;
    transition: opacity $fadeOutSpeedFast;
    width: 100%;
  }

  .progress {
    bottom: -8px;
    cursor: pointer;
    height: $minClickableHeight + 8px;
    overflow: hidden;
    position: absolute;
    transition: opacity $fadeOutSpeedFast;
    width: 100%;

    // Hover over progress
    &:hover {
      .hoverTimestamp {
        opacity: 1;
        transition: opacity $fadeInSpeedFast;
      }

      .played {
        &::before {
          height: 20px;
          right: -10px;
          top: -8px;
          width: 20px;
        }
      }
    }
  }

  .progressBar {
    background-color: color.change($brandColor, $alpha: 0.75);
    border-bottom-left-radius: $cardBorderRadius;
    border-bottom-right-radius: $cardBorderRadius;
    bottom: 8px;
    height: 5px;
    position: absolute;
    width: 100%;

    .loaded {
      background-color: color.change($brandColorAlternate, $alpha: 0.75);
      border-bottom-left-radius: $cardBorderRadius;
      border-bottom-right-radius: $cardBorderRadius;
      height: 100%;
      position: absolute;
      transition: width $fadeInSpeedFast;
    }

    .played {
      background-color: $navigationColor;
      border-bottom-left-radius: $cardBorderRadius;
      border-bottom-right-radius: $cardBorderRadius;
      height: 100%;
      position: absolute;

      &::before {
        background-color: $navigationColor;
        border-radius: 50%;
        content: '';
        height: 11px;
        opacity: 0;
        position: absolute;
        right: -5px;
        top: -3px;
        transition: height $fadeInSpeedFast, opacity $fadeInSpeed, right $fadeInSpeedFast, top $fadeInSpeedFast,
          width $fadeInSpeedFast;
        width: 11px;
      }
    }
  }

  .reportEmailInput {
    margin: 0 $contentMarginMd;
  }

  .reportError {
    color: $alertColor;
    font-size: $fontSizeSm;
    line-height: $lineHeightSm;
    margin: $contentMarginXs $contentMarginMd 0;
  }

  .reportTerms {
    font-size: $fontSizeXs;
    font-weight: bold;
    line-height: $lineHeightXs;
    margin: $contentMarginSm $contentMarginMd 0;
  }

  .tooltip {
    border-radius: 2px;
    display: inline-block;
    font-size: $fontSizeSm;
    font-weight: bold;
    line-height: $lineHeightSm;
    opacity: 0;
    padding: 0 $contentMarginXs;
    position: absolute;
    text-align: center;
    transition: opacity $fadeOutSpeedFast;
    user-select: none;
  }

  .videoControls {
    background: none;
    border-radius: $cardBorderRadius;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background $fadeOutSpeed;
    width: 100%;

    > *:not(.videoMenuBackground) {
      z-index: 1;
    }
  }

  .videoMenu {
    z-index: 2;
  }

  .videoMenuBackground {
    border-radius: $cardBorderRadius;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity $fadeOutSpeedFast;
    width: 100%;
  }

  .videoMenuButton {
    background: none;
    border: 0;
    font-size: $fontSizeXs;
    font-weight: bold;
    height: $minClickableHeight;
    line-height: $lineHeightXs;
    padding: 0;
    width: $minClickableHeight;

    img {
      display: block;
      height: 16px;
      margin: 0 auto $contentMarginXs;
      width: 16px;
    }
  }

  .videoMenuButtons {
    @include hide();
    display: flex;
    justify-content: flex-end;
    margin: $contentMarginMd;
    transition: opacity $fadeOutSpeedFast;
  }

  .videoMenuButtonMenu {
    margin-right: auto;
  }

  .volume {
    @include hide();
    align-items: center;
    bottom: calc(#{$contentMarginLg + $contentMarginMd} + #{$lineHeightMd});
    display: flex;
    justify-content: center;
    position: absolute;
    transition: opacity $fadeOutSpeedFast;
    width: 100%;
  }

  .volumeBar {
    @include increaseClickableArea(20px, 0);
    cursor: pointer;
    height: 5px;
    left: -4px;
    width: 100px;

    &:hover {
      .volumeHoverValue {
        @include show();
      }

      .volumeLevel {
        &::before {
          height: 20px;
          right: -10px;
          top: -8px;
          width: 20px;
        }
      }
    }
  }

  .volumeHoverValue {
    top: -20px - 10px - $contentMarginXs; // Move up distance of volume bar padding + 1/2 of handle + margin
    width: 46px;
  }

  .volumeIcon {
    @include increaseClickableArea(14px);
    cursor: pointer;
    display: block;
    height: 16px;
    left: -4px;
    margin: 0 15px 0 0;
    padding: 0;
    position: relative;
    width: 16px;
  }

  .volumeLevel {
    background-color: $alertColor;
    height: 100%;
    position: relative;

    &::before {
      background-color: $alertColor;
      border-radius: 50%;
      content: '';
      display: block;
      height: 11px;
      position: absolute;
      right: -4px;
      top: -3px;
      transition: height $fadeInSpeedFast, opacity $fadeInSpeed, right $fadeInSpeedFast, top $fadeInSpeedFast,
        width $fadeInSpeedFast;
      width: 11px;
    }
  }

  .watchLabels {
    @include hide();
    font-size: $fontSizeMd;
    line-height: $lineHeightMd;
    margin: auto 0 $contentMarginLg;
    text-align: center;
    transition: opacity $fadeOutSpeedFast;

    p {
      margin: 0;
    }

    .duration {
      font-size: $fontSizeSm;
      height: $lineHeightSm;
      line-height: $lineHeightSm;
    }
  }

  // State-based styles

  // Fullscreen layout
  &.fullscreen {
    .playbackTimestamp {
      bottom: $contentMarginLg + $contentMarginXxl;
    }

    .progress {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      bottom: $contentMarginXxl;
      left: $contentMarginXxl;
      right: $contentMarginXxl;
      width: auto;

      .loaded,
      .played {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .videoMenuButton {
      font-size: $fontSizeMd;
      height: $minClickableHeight * 2;
      line-height: $lineHeightMd;
      width: $minClickableHeight * 2;

      img {
        height: 24px;
        margin-bottom: $contentMarginSm;
        width: 24px;
      }
    }

    .volume {
      bottom: calc(#{$contentMarginLg + $contentMarginMd + $contentMarginXxl} + #{$lineHeightMd});
    }

    // And video is playing
    &.videoIsPlaying {
      .progress {
        @include hide();
      }
    }

    &.userIsActive {
      .progress {
        @include show();
      }
    }

    :global(.vjs-tech) {
      display: block;
      height: 100%;
      margin: 0 auto;
    }
  }

  // Video has not started
  &:not(.videoHasStarted) {
    @include showPlayPauseButton();
    @include videoControlsActive();

    .progress {
      @include hide();
    }

    .watchLabels {
      @include show();
    }
  }

  // Video is paused
  &.videoIsPaused {
    @include videoControlsActive();
    // After initial start
    &.videoHasStarted {
      @include showPlaybackButtons();
      @include showPlaybackTimestamp();
      @include showProgressHandle();
      @include showVideoMenu();
      @include showVolume();
    }
  }

  // User active state
  &.userIsActive {
    @include showProgressHandle();
    @include videoControlsActive();

    // After video has started
    &.videoHasStarted {
      @include showPlaybackButtons();
      @include showPlaybackTimestamp();
      @include showVideoMenu();
      @include showVolume();
    }

    // User changing volume
    &.userIsChangingVolume {
      @include hidePlaybackButtons();
      @include hidePlaybackTimestamp();
      @include hideVideoMenu();
    }
  }

  // Video has ended
  &.videoHasEnded {
    // Video is stopped
    &.videoIsPaused {
      @include hidePlaybackTimestamp();
      @include hideVolume();
      @include showVideoMenu();
      @include videoControlsActive();

      button.fastForwardButton,
      button.rewindButton,
      .videoMenuButtonCast,
      .videoMenuButtonMenu {
        @include hide();
      }

      .watchLabels {
        @include show();
      }
    }
  }

  // Buffering
  :global(.video-js.vjs-waiting) {
    @include videoControlsActive();

    .loadingSpinner {
      opacity: 1;
    }
  }
}

@include forLightTheme() {
  .container {
    :global(.video-js) {
      color: $brandColor;
    }

    // State-related
    &.userIsChangingVolume {
      .volume {
        .volumeLevel {
          background-color: $alertColorActive;

          &::before {
            background-color: $alertColorActive;
          }
        }
      }
    }

    // User scrubbing state
    &.userIsScrubbing {
      .progress {
        .played {
          background-color: $navigationColorActive;

          &::before {
            background-color: $navigationColorActive;
          }
        }
      }
    }
  }

  .progress {
    // Hover over progress
    &:hover {
      .played {
        background-color: $navigationColorHover;

        &::before {
          background-color: $navigationColorHover;
        }
      }
    }
  }

  .tooltip {
    background-color: $brandColor;
    color: $brandColorAlternate;
  }

  .volumeBar {
    background-color: color.change($brandColor, $alpha: 0.75);

    &:hover {
      .volumeLevel {
        background-color: $alertColorHover;

        &::before {
          background-color: $alertColorHover;
        }
      }
    }
  }

  .videoMenuBackground {
    background-color: color.change($contentBackgroundColor, $alpha: 0.7);
  }

  .videoMenuButton {
    color: $brandColor;
  }
}

@include forDarkTheme() {
  .container {
    :global(.video-js) {
      color: $brandColorAlternate;
    }

    // State-related
    &.userIsChangingVolume {
      .volume {
        .volumeLevel {
          background-color: $alertColorActiveDark;

          &::before {
            background-color: $alertColorActiveDark;
          }
        }
      }
    }

    // User scrubbing state
    &.userIsScrubbing {
      .progress {
        .played {
          background-color: $navigationColorActiveDark;

          &::before {
            background-color: $navigationColorActiveDark;
          }
        }
      }
    }
  }

  .progress {
    // Hover over progress
    &:hover {
      .played {
        background-color: $navigationColorHoverDark;

        &::before {
          background-color: $navigationColorHoverDark;
        }
      }
    }
  }

  .tooltip {
    background-color: $brandColorAlternate;
    color: $brandColor;
  }

  .volumeBar {
    background-color: color.change($brandColorAlternate, $alpha: 0.75);

    &:hover {
      .volumeLevel {
        background-color: $alertColorHoverDark;

        &::before {
          background-color: $alertColorHoverDark;
        }
      }
    }
  }

  .videoMenuBackground {
    background-color: color.change($contentBackgroundColorDark, $alpha: 0.7);
  }

  .videoMenuButton {
    color: $brandColorAlternate;
  }
}

// Loading animation

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
