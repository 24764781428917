@import 'OK/styles/util';

// Base style

.all {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;
  position: relative;
  transition: 100ms all;
}

// Default style

.link {
  &.block {
    height: $minClickableHeight;
    line-height: $minClickableHeight;
  }
}

// Button style

.button {
  @include increaseClickableArea(2px);
  border-radius: $buttonBorderRadius;
  height: $buttonHeight;
  line-height: $buttonHeight;
  padding: 0 $buttonPadding 0;
}

// Block

.block {
  display: flex;
  width: 100%;
}

// Icon

.icon {
  &.right {
    margin-left: $contentMarginSm;
  }

  &.left {
    margin-right: $contentMarginSm;
  }
}

// Disabled

.disabled {
  opacity: 0.5;
}
