@import 'OK/styles/util';

.accordion {
  padding-bottom: $contentMarginLg;
}

.accordion,
.container {
  padding-top: $contentMarginLg;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 5px;
}

.addSlideButton {
  align-items: center;
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  flex-direction: column;
  flex: 0 0 100%;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  min-height: 350px;
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: $cardWidthDesktop;
    flex: 0 0 $cardWidthDesktop;
  }

  .addSlideIcon {
    color: $creationColor;
    margin-bottom: $contentMarginSm;
  }
}

.cardContainer {
  display: block;
  width: 100%;
  margin-bottom: 0 !important;

  @include forMinScreenWidth(medium) {
    display: inline-block;
  }
}

.carousel {
  padding: 0 $contentMarginSm;
  :global(.os-scrollbar-horizontal) {
    padding: 0 $contentMarginXxl !important;
  }
  @include forMinScreenWidth(medium) {
    :global(.os-scrollbar-horizontal) {
      padding: 0 $contentMarginXl !important;
    }
  }
}

.carouselContainer {
  margin: 0 (-$contentMarginLg) !important;

  @include forMinScreenWidth(medium) {
    margin: 0 (-$contentMarginXxl) !important;
  }
}

.document {
  height: 100%;
  width: 100%;
}

.documentSlide {
  margin: 0 $contentMarginSm;
  width: 100%;
  flex: 0 0 100%;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;

    > * {
      margin-right: $contentMarginSm;
    }
  }

  @include forMinScreenWidth(medium) {
    &:first-child {
      margin-left: $contentMarginSm;
    }

    &:last-child {
      margin-right: 0;

      > * {
        margin-right: $contentMarginLg;
      }
    }

    width: $cardWidthDesktop;
    flex: 0 0 $cardWidthDesktop;
  }
}

.documentSlideSecondary {
  margin: 0 $contentMarginSm;
  width: 100%;
  flex: 0 0 100%;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    > * {
      margin-left: -$contentMarginSm;
      margin-right: $contentMarginSm;
    }
  }

  @include forMinScreenWidth(medium) {
    width: auto;
    flex: 0 0 auto;

    &:first-child {
      margin-left: $contentMarginSm;
    }

    &:last-child {
      > * {
        margin-left: 0;
        margin-right: $contentMarginSm;
      }
    }
  }
}

.documentCarouselSecondary {
  margin: 0 (-$contentMarginLg) !important;

  @include forMinScreenWidth(medium) {
    margin: 0 (-$contentMarginXxl) !important;
  }
}

.documentCarouselInnerSecondary {
  padding: 0 $contentMarginSm;
  :global(.os-scrollbar-horizontal) {
    padding: 0 $contentMarginXxl !important;
  }
  @include forMinScreenWidth(medium) {
    :global(.os-scrollbar-horizontal) {
      padding: 0 $contentMarginXl !important;
    }
  }
}

.edit {
  border: 1px solid $navigationColor !important;
}

.errorMessage {
  margin: -15px 0 15px 0;
}

.header {
  margin-left: 0;
  font-size: 24px;
  font-weight: bold;
}

.headerAccordion {
  margin-bottom: 0 !important;
  padding: 0 $contentMarginLg;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  @include forMinScreenWidth(medium) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

.icon {
  margin-bottom: 5px;
}

.normalWeight {
  font-weight: normal;
}

.resultCardSecondary {
  margin-bottom: 0;
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: auto;
  }
}

.resultCardContainerSecondary {
  flex: 0 0 100%;
  margin: 0 !important;
  width: 100%;

  @include forMinScreenWidth(medium) {
    flex: 1 0 0;
  }
}

.searchInput {
  z-index: 1;
}

.searchInputContainer {
  margin-bottom: $contentMarginMd;
  position: relative;
  z-index: 1;
}

.searchNewButton {
  @include increaseClickableArea(2px, 0, absolute);
  height: $buttonHeight;
  margin: 0;
  right: 30px + $contentMarginSm;
  top: 0;
  width: 44px;
  z-index: 3;
}

.searchResults {
  margin-top: -$contentMarginXs;
  position: absolute;
  width: 100%;
}

.searchResultButton {
  height: $lineHeightMd;
  margin: 0;

  img {
    margin-left: $contentMarginSm;
    width: 1em;
  }
}

.searchResultOKID {
  @include fontMono();
}

.sectionIntro {
  @include forMinScreenWidth(medium) {
    max-width: 400px;
  }
}

.sectionNotice {
  margin-left: -$contentMarginMd;
  margin-right: -$contentMarginMd;

  @include forMinScreenWidth(medium) {
    float: right;
    margin: -$contentMarginMd 0 0 0;
    width: 267px;
  }
}

.swipeSidewaysMessage {
  margin-top: 6px;
}

.toggleButton {
  margin-bottom: $contentMarginSm !important;

  @include forMinScreenWidth(medium) {
    margin-bottom: 0 !important;
  }
}

@include forLightTheme() {
  .accordion,
  .container {
    background-color: $contentBackgroundColor;
  }

  .addSlideButton {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
    color: $textColor;

    &:hover {
      .addSlideIcon {
        color: $creationColorHover;
      }
    }

    &:active {
      .addSlideIcon {
        color: $creationColorActive;
      }
    }
  }
}

@include forDarkTheme() {
  .accordion,
  .container {
    background-color: $contentBackgroundColorDark;
  }

  .addSlideButton {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
    color: $textColorLight;

    &:hover {
      .addSlideIcon {
        color: $creationColorHoverDark;
      }
    }

    &:active {
      .addSlideIcon {
        color: $creationColorActiveDark;
      }
    }
  }
}
