@import 'OK/styles/util';

.card {
  border-radius: $cardBorderRadius;
  display: flex;
  overflow: hidden;
}

.info {
  flex: 1 1;
  overflow: hidden;
  padding: $contentMarginSm;
}

.media {
  flex: 0 0 63px;
  height: 63px;
  position: relative;
  width: 63px;
}

.reorderIcon {
  height: 41px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 41px;
}

.testCardPhoto {
  border-radius: 5px 0 0 5px;
  float: left;
  height: 100%;
  width: 100%;
}

.tags {
  display: flex;

  > * {
    margin-right: $contentMarginXs;
    top: 0;
  }
}

.title {
  margin-bottom: $contentMarginXs;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unlinkButton {
  @include increaseClickableArea(13px);
  font-size: $fontSizeXs;
  font-weight: bold;
  height: 18px;
  margin: 0 0 0 auto;
  padding: 0;

  > img {
    vertical-align: top;
  }
}

@include forLightTheme() {
  .info {
    background-color: $contentBackgroundColor;
  }

  .media {
    background-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .info {
    background-color: $contentBackgroundColorDark;
  }

  .media {
    background-color: $midtonesColorStaticDark;
  }
}
