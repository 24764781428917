@import 'OK/styles/util';

.caretIcon {
  margin-left: $contentMarginSm;
  position: relative;
  top: -3px;
  transform: rotate(90deg);
  transition: transform $timingShort, top $timingShort;
}

.tab {
  cursor: pointer;
  margin-bottom: 0;
  white-space: nowrap;

  &.button {
    border-style: solid;
    border-width: 1px;
    height: $buttonHeight;
    line-height: $buttonHeight - 2px;
    padding: 0 $contentMarginSm;
    text-align: center;

    &:not(:first-child) {
      margin-left: -1px;
    }

    &:first-child {
      border-radius: $buttonBorderRadius 0 0 $buttonBorderRadius;
    }

    &:last-child {
      border-radius: 0 $buttonBorderRadius $buttonBorderRadius 0;
    }

    &.active {
      z-index: 1;
    }
  }

  &.caret {
    align-items: center;
    display: inline-flex;
    margin-right: $contentMarginLg;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: $navigationColor;

      .caretIcon {
        top: 3px;
        transform: rotate(270deg);
      }
    }
  }

  &.tag {
    border-radius: 2px;
    font-size: $fontSizeXs;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    margin-right: $contentMarginSm;
    text-align: center;
    width: 80px;
  }

  &.underline {
    font-weight: bold;
    padding: $contentMarginSm 7px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@include forLightTheme() {
  .tab {
    &.button {
      border-color: #707070;

      &.active {
        background-color: $brandColor;
        border-color: $brandColor;
        color: #707070;
      }
    }

    &.tag {
      background-color: #707070;

      &.active {
        background-color: $brandColor;
        color: #707070;
      }
    }

    &.underline {
      color: #707070;

      &.active {
        color: $brandColor;
      }
    }
  }
}

@include forDarkTheme() {
  .tab {
    &.button {
      border-color: #707070;

      &.active {
        background-color: $brandColorAlternate;
        border-color: $brandColorAlternate;
        color: #707070;
      }
    }

    &.tag {
      background-color: #707070;

      &.active {
        background-color: $brandColorAlternate;
        color: #707070;
      }
    }

    &.underline {
      color: #707070;

      &.active {
        color: $brandColorAlternate;
      }
    }
  }
}
