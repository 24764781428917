@import 'OK/styles/util';

.checkbox {
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  display: block;
  height: 100%;
  width: 100%;
}

.container {
  display: block;
  height: 32px;
  padding: 1px;
  position: relative;
  width: 32px;

  &:hover {
    padding: 0;

    .checkbox {
      border-width: 2px;
    }
  }
}

.original {
  opacity: 0;
  position: absolute;

  &:checked {
    ~ .checkbox {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 75%;
    }
  }
}

@include forLightTheme() {
  .checkbox {
    background: $contentBackgroundColor;
    border-color: $brandColor;
  }

  .container {
    &.error {
      .checkbox {
        border-color: $alertColor;
      }
    }
  }

  .original {
    &:checked {
      ~ .checkbox {
        background-image: url('/icons/check_green.svg');
      }
    }
  }
}

@include forDarkTheme() {
  .checkbox {
    background: $contentBackgroundColorDark;
    border-color: $brandColorAlternate;
  }

  .container {
    &.error {
      .checkbox {
        border-color: $alertColor;
      }
    }
  }

  .original {
    &:checked {
      ~ .checkbox {
        background-image: url('/icons/check_green.svg');
      }
    }
  }
}
