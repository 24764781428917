@import 'OK/styles/util';

.container {
  display: flex;
}

.label {
  margin: 0;
  text-align: center;
}

.number {
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  margin-bottom: $contentMarginSm;
  text-align: center;
}

.unitContainer {
  flex: 1 1 25%;
  margin-right: $contentMarginSm;

  &:last-child {
    margin-right: 0;
  }
}

@include forLightTheme() {
  .number {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
  }
}
