@import 'OK/styles/util';

.fineprint {
  margin-bottom: 0;
  margin-top: auto;
}

.header {
  margin-bottom: $contentMarginSm;
}
