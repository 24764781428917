.flip {
  transform: scaleX(-1);
}

.icon {
  vertical-align: middle;
}

.inheritColor {
  fill: currentColor;
}

.inline {
  height: 1em;
  position: relative;
  top: -2px;
}

.spin {
  animation: spin 1s linear infinite;
}

// Loading animation
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
