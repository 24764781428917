@import 'OK/styles/util';

.myOrganisationTagIcon {
  top: -1px;
}

.quantityInput {
  margin-bottom: $contentMarginSm;
}

.quantityLabel {
  margin-bottom: $contentMarginXs;
}
