@import 'OK/styles/util';

.group {
  display: inline-flex;
  justify-content: stretch;
}

// Default style

.default {
  .button {
    align-items: center;
    border-style: solid;
    border-width: 1px;
    display: inline-flex;
    flex: 1 0 $minClickableHeight;
    justify-content: center;
    padding: 9px;
    transition: all $timingInstant ease;
    white-space: nowrap;

    &:first-child {
      border-bottom-left-radius: $buttonBorderRadius;
      border-top-left-radius: $buttonBorderRadius;
    }

    &:last-child {
      border-bottom-right-radius: $buttonBorderRadius;
      border-top-right-radius: $buttonBorderRadius;
    }
  }
}

// Separate style

.separate {
  .button {
    border-radius: 15px;
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    line-height: 12px;
    padding: 5px 10px;
    white-space: nowrap;
  }
}
