@import 'OK/styles/util';

@include forLightTheme() {
  .tooltip {
    background-color: $brandColor;
    color: $textColorLight;

    &.above {
      &::before {
        border-top-color: $brandColor;
      }
    }

    &.below {
      &::before {
        border-bottom-color: $brandColor;
      }
    }

    &.alert {
      background-color: $alertColor;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $alertColor;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $alertColor;
        }
      }
    }

    &.creation {
      background-color: $creationColor;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $creationColor;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $creationColor;
        }
      }
    }

    &.navigation {
      background-color: $navigationColor;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $navigationColor;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $navigationColor;
        }
      }
    }

    &.notification {
      background-color: $notificationColor;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $notificationColor;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $notificationColor;
        }
      }
    }
  }
}

@include forDarkTheme() {
  .tooltip {
    background-color: $brandColorAlternate;
    color: $textColor;

    &.above {
      &::before {
        border-top-color: $brandColorAlternate;
      }
    }

    &.below {
      &::before {
        border-bottom-color: $brandColorAlternate;
      }
    }

    &.alert {
      background-color: $alertColor;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $alertColor;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $alertColor;
        }
      }
    }

    &.creation {
      background-color: $creationColor;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $creationColor;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $creationColor;
        }
      }
    }

    &.navigation {
      background-color: $navigationColor;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $navigationColor;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $navigationColor;
        }
      }
    }

    &.notification {
      background-color: $notificationColor;
      color: $textColorLight;

      &.above {
        &::before {
          border-top-color: $notificationColor;
        }
      }

      &.below {
        &::before {
          border-bottom-color: $notificationColor;
        }
      }
    }
  }
}
