@use 'sass:color';
@import 'OK/styles/util';

$desktopDescriptionMaxWidth: 400px;

.profilePhoto {
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  height: 0;
  margin-bottom: $contentMarginMd;
  overflow: hidden;
  padding-top: calc(100% - 2px);
  position: relative;
  width: 100%;

  @include forMinScreenWidth(medium) {
    padding-top: $cardWidth3ColumnDesktop - 2px;
    width: 277px;
  }
}

.profilePhotoButton {
  margin-bottom: 0;

  // &:first-child {
  //   margin-bottom: $contentMarginSm;
  // }

  &:last-child {
    margin-bottom: $contentMarginMd;
  }
}

.profilePhotoContainer {
  margin-bottom: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginMd;
    width: $cardWidth3ColumnDesktop;
  }
}

.profilePhotoErrorMessage {
  margin: -15px 0 $contentMarginSm 0;
}

.searchInput {
  margin-bottom: $contentMarginLg;

  @include forMinScreenWidth(medium) {
    max-width: $desktopDescriptionMaxWidth;
  }
}

.searchModeButtons {
  margin-bottom: $contentMarginMd;
}

.sectionDescription {
  @include forMinScreenWidth(medium) {
    max-width: $desktopDescriptionMaxWidth;
  }
}

.actionButton {
  border-radius: 2px;
  font-size: $fontSizeSm;
  font-weight: bold;
  line-height: $lineHeightXs;
  padding: 0 $contentMarginSm;
  margin-right: $contentMarginSm;
  margin-bottom: 0;
  margin-top: 10px;
}

.bottomText {
  font-size: 0.75rem;
}

.buttonCancel {
  margin-right: $contentMarginMd;
  margin-bottom: $contentMarginSm !important;
}

.buttonSave {
  margin-bottom: $contentMarginSm !important;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
}

.container {
  margin: auto;
  max-width: 335px;
}

.header {
  align-items: center;
  display: flex;
  margin: 0 0 0 0;
  justify-content: space-between;
  height: auto;
}

.linkCard {
  width: $cardWidthMobile;

  @include forMinScreenWidth(medium) {
    width: $cardWidth4ColumnDesktop;
  }
}

.linkSlide {
  margin: 0 $contentMarginSm;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.textAvatar {
  margin-bottom: 10px;
}

.nameInput {
  margin-top: 15px;
}

.shareToast {
  position: absolute;
  text-align: center;
  bottom: $contentMarginSm;
  width: 175px;
}

.toastContainer {
  position: fixed;
  bottom: 70px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.fieldName {
  margin-bottom: $contentMarginSm;
}

.field {
  margin-bottom: $contentMarginMd;
}

.unbold {
  font-weight: normal;
}
