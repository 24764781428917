@import 'OK/styles/util';

.barContainer {
  @include increaseClickableArea(5px);
  cursor: pointer;
  height: 5px;
  user-select: none;
  width: 100%;
}

.handle {
  background-color: $navigationColor;
  border-radius: 8px;
  content: '';
  display: flex;
  height: 16px;
  justify-content: center;
  position: absolute;
  right: -8px;
  top: -5px;
  width: 16px;
}

.minMaxValue {
  margin: 0;
}

.minMaxValues {
  display: flex;
  justify-content: space-between;
  margin-top: $contentMarginSm;
}

.positionBar {
  background-color: $navigationColor;
  height: 100%;
  position: relative;
}

.valueTooltip {
  padding: 0 $contentMarginXs;
  position: relative;
  top: 18px;
  white-space: nowrap;

  &::before {
    left: calc(50% - 6px);
  }
}

@include forLightTheme() {
  .barContainer {
    background-color: $brandColor;
  }
}

@include forDarkTheme() {
  .barContainer {
    background-color: $brandColorAlternate;
  }
}
