@import 'OK/styles/util';

.certificateNotice {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;

  > p {
    margin: 0;
  }
}

.container {
  width: $cardWidthMobile;
}

.description {
  margin-bottom: 0;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;

  > * {
    margin: 0;
  }
}

.recognitionDetailsContainer {
  display: flex;
}

.recognitionImage {
  margin-right: $contentMarginSm;
}

.recognitionName {
  margin-bottom: $contentMarginSm;
}
