@import 'OK/styles/util';

.addMarginTop {
  margin: $contentMarginMd 0 0 0;
}

.closeButton {
  float: right;
  position: relative;
  top: -2px;
}

.cookiesNotice {
  margin: 0 (-$contentMarginSm) !important;
  padding-bottom: $contentMarginLg;

  @include forMinScreenWidth(medium) {
    margin: (-$contentMarginMd) (-$contentMarginSm) 0 0 !important;
  }
}

.header {
  display: inline-block;
}

.headerWithSubtext {
  margin-bottom: $contentMarginSm;
}

.noticeLink {
  height: auto !important;
  line-height: inherit !important;
  margin: 0 !important;
}

.normalWeight {
  font-weight: normal;
}

.popupColumnContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include forMinScreenWidth(medium) {
    flex-direction: row;
  }

  > div {
    flex: 1 1 50%;

    @include forMinScreenWidth(medium) {
      &:first-child {
        padding-right: 25px;
      }
      &:last-child {
        padding-left: 25px;
      }
    }
  }
}

.saveButton {
  margin-left: $contentMarginMd;
}

.section {
  margin-bottom: $contentMarginLg;

  @include forMinScreenWidth(medium) {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.toggleRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginSm;

  p {
    margin: 0;
  }
}
