@import 'OK/styles/util';

.buttonContainer {
  margin-top: $contentMarginLg;
  position: relative;
}

.explanation {
  margin-bottom: $contentMarginSm;
}

.inlineCogIcon {
  top: 0;
}

.mouse {
  position: absolute;
  right: 0;
  bottom: -65px;
}

.rejectButton {
  margin-bottom: $contentMarginSm;
}
