// doiuse-disable calc

@use 'sass:color';

@import 'OK/styles/util';

/* Mobile variation (default) */

.awards {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  margin: 0;
  width: 30px;
}

.badge {
  align-items: center;
  border-radius: 0 0 $cardBorderRadius $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 1px 4px;
  position: absolute;
  top: -1px;

  &.awardsBadge {
    right: 19px;
  }

  &.refIdBadge {
    left: 19px;
  }
}

.card {
  overflow: hidden;
  position: relative;

  &.fixedWidth {
    width: $cardWidthMobile;
  }
}

.cents {
  font-size: $fontSizeXs;
  line-height: $lineHeightXs;
  vertical-align: super;
}

.currency {
  margin-bottom: $contentMarginXs;
}

.dollars {
  font-size: $fontSizeSectionHeader;
  line-height: 27px;
}

.footerText {
  font-size: $fontSizeXs;
  font-weight: bold;
  line-height: $lineHeightXs;
  margin: 0 auto 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footerRow {
  align-items: center;
  display: flex;
  height: 24px;
  padding: $contentMarginXs $contentMarginMd;

  &:last-child {
    border-radius: 0 0 $cardBorderRadius $cardBorderRadius;
  }
}

.grade {
  align-items: center;
  display: flex;
  flex: 0 0 49px;
  margin: 0 0 0 $contentMarginSm;
  width: 49px;

  &.allowCollapse {
    flex-shrink: 1;
  }
}

.gradeIcon {
  flex: 0 0 12px;
  margin-right: 8px;
}

.grades {
  display: flex;
  flex: 0 0;
  justify-content: flex-end;
}

.gradeScore {
  @include fontMono();
  display: inline-block;
  text-align: right;
  width: 29px;
}

.header {
  align-items: center;
  display: flex;
  height: 17px;
  margin-bottom: $contentMarginXs;
  width: 100%;
}

.headerTag {
  display: inline-block;
  margin-left: $contentMarginXs;
  text-align: center;
  width: 32px;
}

.actionIndicator {
  border-radius: 0 $cardBorderRadius $cardBorderRadius 0;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 3px;
}

.itemPrice {
  margin: 0 0 0 auto;
}

.mainContent {
  padding: $contentMarginSm $contentMarginMd $contentMarginSm $contentMarginSm;
  width: 100%;
}

.mainSection {
  display: flex;
}

.managerOption {
  &:not(:last-child) {
    margin-bottom: $contentMarginMd;
  }

  button {
    margin: 0;
  }
}

.managerOptionLabel {
  margin: 0;
}

.managerOptions {
  border-top-style: solid;
  border-top-width: 2px;
  padding: $contentMarginMd $contentMarginMd $contentMarginLg;
}

.managerOptionsErrorMessage {
  margin: -15px 0 15px;
}

.mediaGallery {
  height: 100%;
  width: 100%;
}

.mediaGalleryContainer {
  border-top-left-radius: $cardBorderRadius;
  flex: 0 0 130px;
  height: 130px;
  margin: 1px 0 0 1px;
  overflow: hidden;
  position: relative;
  width: 130px;
}

.mediaGalleryPreviews {
  border-radius: $cardBorderRadius 0 0 0 !important;

  @include forMinScreenWidth(medium) {
    border-radius: $cardBorderRadius $cardBorderRadius 0 0 !important;
  }
}

.mediaOverlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.mediaPlaceholderIcon {
  display: block;
  margin: 45px;
  position: relative;
}

.okid {
  margin-bottom: 0;
}

.okidIcon {
  margin-right: $contentMarginXs;
  top: -1px;
}

.pdfPreview {
  height: 100%;
}

.price {
  font-weight: bold;
  margin: 0 0 0 auto;
}

.priceRow {
  display: flex;
}

.publishOption {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.refId {
  @include fontMono();
}

.reorderButton {
  margin: 0;

  &.reorderButtonRight {
    margin-left: auto;
  }
}

.reorderFooter {
  display: flex;
  justify-content: space-between;
  margin: 0 (-$contentMarginMd) (-$contentMarginLg);
  padding: 0 $contentMarginMd;
}

.reorderLeftIcon {
  transform: rotate(180deg);
}

.stock {
  margin-bottom: 0;
}

.stockRow {
  display: flex;
  height: 17px;
}

.testLevelColor {
  margin-right: 4px;
}

.testRemarksIcon {
  margin-left: auto;
  position: relative;
}

.testResult {
  margin-right: 8px;
  width: 38px;
}

.testResults {
  @include fontMono();
  align-items: center;
  display: flex;
  margin: 0;
  width: 100%;
}

.title {
  height: 40px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.type {
  display: inline;
  margin: 0 auto 0 0;
}

/* Desktop variation */

.desktop {
  &.fixedWidth {
    width: $cardWidth4ColumnDesktop;
  }

  .footerRow {
    &:last-child {
      align-items: flex-start;
      flex-direction: column;
      height: 48px;
      justify-content: space-between;

      .grades {
        width: 100%;
      }
    }
  }

  .mainContent {
    height: 131px;
    padding: $contentMarginSm $contentMarginMd;
  }

  .mainSection {
    flex-direction: column;
  }

  .mediaGallery {
    border-radius: $cardBorderRadius $cardBorderRadius 0 0 !important;
  }

  .mediaGalleryContainer {
    border-top-right-radius: $cardBorderRadius;
    height: 0;
    padding-top: calc(100% - 2px);
    width: calc(100% - 2px);
  }

  .mediaGalleryInnerContainer {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@include forLightTheme() {
  .badge {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
  }

  .card {
    color: $textColor;
  }

  .footerRow {
    background-color: $midtonesColorStatic;

    &:first-child {
      color: $textColor;
    }

    &.backgroundTintNavigation {
      background-color: $navigationColor;
      color: $textColorLight;
    }

    &.textTintNavigation {
      color: $navigationColorCard;
    }
  }

  .managerOptions {
    border-top-color: $midtonesColorStatic;
  }

  .mediaOverlay {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .reorderFooter {
    background-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .badge {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
  }

  .card {
    color: $textColorLight;
  }

  .footerRow {
    background-color: $midtonesColorStaticDark;

    &:first-child {
      color: $textColorLight;
    }

    &.backgroundTintNavigation {
      background-color: $navigationColor;
      color: $textColor;
    }

    &.textTintNavigation {
      color: $navigationColorCardDark;
    }
  }

  .managerOptions {
    border-top-color: $midtonesColorStaticDark;
  }

  .mediaOverlay {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .reorderFooter {
    background-color: $midtonesColorStaticDark;
  }
}

.zeroOpacity {
  opacity: 0;
}

.stageIcon {
  margin-right: $contentMarginSm;
}

/* ColumnCard */

.columnCard {
  background-color: white;
  border: #e2e2e2 1px solid;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: relative;
  width: 100%;

  @include forMinScreenWidth(medium) {
    max-width: 291px;
    min-width: 190px;
  }

  @include forMinScreenWidth(large) {
    max-width: 254px;
    min-width: 216px;
  }
}

.columnCardAssetID {
  background-image: linear-gradient(
    to top,
    rgba(255, 0, 0, 0) 0%,
    rgba(255, 0, 0, 0) 40%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  color: #767575;
  font-family: 'Noto Mono';
  font-size: 12px;
  padding: 0 10px;
  position: absolute;
  bottom: -12px;
  left: 10px;
  z-index: 100;
}

.columnCardAssetType {
  background-color: transparent;
  color: #767575;
  font-size: 12px;
}

.columnCardBottomContainer {
  color: #727272;
  font-family: 'Noto Sans';
  font-size: 12px;
  width: 100%;
  height: 70px;
  background-image: linear-gradient(to bottom, rgba(248, 248, 248, 0.7), rgba(255, 255, 255, 1) 100%);
  padding: 12px 20px;
  position: absolute;
  bottom: -60px;
  z-index: 0;
}

.columnCardBottomContainerItem {
  align-items: center;
  display: flex;
  white-space: nowrap;
}

.columnCardBottomContainerRow {
  display: flex;
  gap: 10px;
  margin-top: 8px;
}

.columnCardButton {
  border-radius: 2px;
  font-size: 10px;
  height: 19px;
  margin-bottom: 0;
}

.columnCardButtonIcon {
  width: 10px;
}

.columnCardContainer {
  position: relative;
  margin-bottom: 70px;
  flex-basis: 33%;
}

.columnCardImage {
  width: 100%;
  display: flex;
  color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e2e2e2;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @include forMinScreenWidth(large) {
    max-width: 291px;
    min-width: 216px;
  }
}

.columnCardInformation {
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
}

.columnCardOrganisationImage {
  width: 24px;
  height: 24px;
  background-color: white;
  padding: 1px;
  position: absolute;
  right: -30px;
  bottom: 0px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1a1a1a !important;
}

.columnCardOrganisationLink {
  display: block;
  font-size: 12px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  line-height: 1.5em;
  max-height: 1.5em;
  max-width: 97px;

  @include forMinScreenWidth(medium) {
    max-width: 176px;
  }
}

.columnCardSubtitle {
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: #767575;

  @include forMinScreenWidth(large) {
    max-width: 90px;
    width: 90px;
  }
}

.columnCardTitle {
  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
  color: #1a1a1a;
  max-width: 97px;

  @include forMinScreenWidth(large) {
    max-width: 176px;
  }
}

.columnGradeIcon {
  margin-right: 8px;
}

.columnGradeScore {
  @include fontMono();
  display: inline-block;
}

@include forDarkTheme() {
  .columnCard {
    background-color: transparent;
    border: 1px solid #767575;
  }

  .columnCardAssetID {
    background-color: #010101;
    background-image: none;
  }

  .columnCardAssetType {
    background-color: transparent;
  }

  .columnCardBottomContainer {
    background-image: none;
  }

  .columnCardImage {
    color: #f4f4f4;
  }

  .columnCardOrganisationImage {
    background-color: #454646;
    color: #f4f4f4 !important;
  }

  .columnCardTitle {
    color: white;
  }
}

/* RowCard */

.rowCard {
  background-color: white;
  border: #e2e2e2 1px solid;
  border-radius: 5px;
  width: 100%;
  height: 131px;
  display: flex;
  position: relative;
  justify-content: space-between;
  z-index: 2;

  @include forMinScreenWidth(medium) {
    max-width: 452px;
    min-width: 295px;
  }

  @include forMinScreenWidth(large) {
    max-width: 527px;
    min-width: 452px;
    height: 152px;
  }
}

.rowCardAssetID {
  background-image: linear-gradient(
    to top,
    rgba(255, 0, 0, 0) 0%,
    rgba(255, 0, 0, 0) 40%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  color: #767575;
  font-family: 'Noto Mono';
  font-size: 12px;
  padding: 0 10px;
  position: absolute;
  bottom: -12px;
  left: 10px;
  z-index: 100;
}

.rowCardAssetType {
  background-color: white;
  color: #767575;
  font-size: 12px;
  padding: 0 10px;
  position: absolute;
  top: -12px;
  left: 10px;
}

.rowCardBottomContainer {
  color: #727272;
  font-family: 'Noto Sans';
  font-size: 12px;
  width: 100%;
  height: 45px;
  background-image: linear-gradient(to bottom, rgba(248, 248, 248, 0.7), rgba(255, 255, 255, 1) 100%);
  padding: 12px 20px;
  position: absolute;
  bottom: -42px;
  z-index: 0;
}

.rowCardContainer {
  position: relative;
  margin-bottom: 20px;
}

.rowCardInformation {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rowCardImage {
  height: 129px;
  width: 100%;
  max-width: 129px;
  display: flex;
  color: black;
  background-color: #e2e2e2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;

  @include forMinScreenWidth(large) {
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    width: 150px;
  }
}

.rowCardOrganisationImage {
  width: 24px;
  height: 24px;
  background-color: white;
  padding: 1px;
  position: absolute;
  right: 119px;
  bottom: 15px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #1a1a1a;

  @include forMinScreenWidth(medium) {
    right: 117px;
  }

  @include forMinScreenWidth(large) {
    right: 137px;
  }
}

.rowCardSubtitle {
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  color: #767575;

  @include forMinScreenWidth(large) {
    max-width: 90px;
    width: 90px;
  }
}

.rowCardTitle {
  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
  color: #010101;
  max-width: 110px;

  @include forMinScreenWidth(large) {
    max-width: 260px;
  }
}

@include forDarkTheme() {
  .rowCard {
    background-color: transparent;
    border: 1px solid #767575;
  }

  .rowCardAssetID {
    background-color: #010101;
    background-image: none;
  }

  .rowCardAssetType {
    background-color: #010101;
  }

  .rowCardBottomContainer {
    background-image: none;
  }

  .rowCardImage {
    color: white;
  }

  .rowCardOrganisationImage {
    background-color: #454646;
    border: 1px solid #767575;
    color: #f4f4f4 !important;
  }

  .rowCardTitle {
    color: white;
  }
}

/* listCard */

.listCard {
  background-color: white;
  border: #e2e2e2 1px solid;
  border-radius: 5px;
  width: 100%;
  height: 68px;
  display: flex;
  position: relative;
  z-index: 2;

  @include forMinScreenWidth(medium) {
    height: 47px;
  }

  @include forMinScreenWidth(large) {
    height: 34px;
  }
}

.listCardAssetID {
  background-image: linear-gradient(
    to top,
    rgba(255, 0, 0, 0) 0%,
    rgba(255, 0, 0, 0) 40%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  color: #767575;
  font-family: 'Noto Mono';
  font-size: 12px;
  padding: 0 10px;
  position: absolute;
  bottom: -6px;
  left: 10px;
  z-index: 100;
  line-height: 1em;
}

.listCardAssetType {
  background-color: white;
  color: #767575;
  font-size: 12px;
  padding: 0 10px;
  position: absolute;
  top: -6px;
  left: 10px;
  line-height: 1em;
}

.listCardBottomContainer {
  color: #727272;
  font-family: 'Noto Sans';
  font-size: 12px;
  width: 100%;
  height: 45px;
  background-image: linear-gradient(to bottom, rgba(248, 248, 248, 0.7), rgba(255, 255, 255, 1) 100%);
  padding: 12px 20px;
  position: absolute;
  bottom: -42px;
  z-index: 0;
}

.listCardContainer {
  width: 100%;
  position: relative;
  margin-bottom: 10px;

  @include forMinScreenWidth(large) {
    margin-bottom: -35px;
  }
}

.listCardIcon {
  color: #1a1a1a;
  margin-right: 20px;
}

.listCardInformation {
  width: 100%;
  padding: 15px 20px;

  @include forMinScreenWidth(medium) {
    padding: 15px 20px;
  }

  @include forMinScreenWidth(large) {
    padding: 10px 20px;
  }
}

.listCardInformationText {
  display: flex;
  gap: 2px;
  flex-direction: column;

  @include forMinScreenWidth(medium) {
    flex-direction: row;
    gap: 10px;
  }
}

.listCardImage {
  height: 66px;
  width: 100%;
  max-width: 68px;
  display: flex;
  color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e2e2e2;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;

  @include forMinScreenWidth(medium) {
    height: 45px;
    max-width: 45px;
  }

  @include forMinScreenWidth(large) {
    height: 32px;
    max-width: 32px;
  }
}

.listCardOrganisationImage {
  width: 24px;
  height: 24px;
  background-color: white;
  padding: 1px;
  position: absolute;
  right: -30px;
  bottom: 0px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1a1a1a !important;

  @include forMinScreenWidth(medium) {
    bottom: -4px;
  }

  @include forMinScreenWidth(large) {
    bottom: 0px;
  }
}

.listCardOrganisationLink {
  display: block;
  font-size: 12px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  line-height: 1.5em;
  max-height: 1.5em;

  @include forMinScreenWidth(medium) {
    line-height: 1.2em;
  }

  @include forMinScreenWidth(large) {
    line-height: 2em;
  }
}

.listCardSubtitle {
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  color: #767575;
  line-height: 1.2em;
  width: 100%;

  @include forMinScreenWidth(medium) {
    max-width: 90px;
    width: 90px;
  }

  @include forMinScreenWidth(large) {
    line-height: 2em;
  }
}

.listCardSubtitle:empty {
  width: 0;
}

.listCardSubtitle::after {
  content: '';
  width: 0px;
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 1) 28%);
  height: 1.5em;
  position: absolute;
  left: 713px;

  @include forMinScreenWidth(large) {
    width: 22px;
  }
}

.listCardTitle {
  white-space: nowrap;
  font-size: 12px;
  overflow: hidden;
  color: #010101;
  max-width: 190px;
  width: 190px;
  line-height: 1.2em;

  @include forMinScreenWidth(medium) {
    line-height: 1.2em;
  }

  @include forMinScreenWidth(large) {
    line-height: 1.5em;
    max-width: 160px;
    width: 160px;
    font-size: 14px;
  }
}

.listCardTitle::after {
  content: '';
  width: 22px;
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 1) 28%);
  height: 1.5em;
  position: absolute;
  left: 197px;

  @include forMinScreenWidth(large) {
    left: 203px;
  }
}

@include forDarkTheme() {
  .listCard {
    background-color: transparent;
    border: 1px solid #767575;
  }

  .listCardAssetID {
    background-color: #010101;
    background-image: none;
  }

  .listCardAssetType {
    background-color: #010101;
  }

  .listCardBottomContainer {
    background-image: none;
  }

  .listCardIcon {
    color: white;
  }

  .listCardImage {
    color: white;
  }

  .listCardOrganisationImage {
    background-color: #454646;
    color: #f4f4f4 !important;
  }

  .listCardOrganisationLink {
    background-color: #010101;
    color: #0079d2;
  }

  .listCardTitle {
    color: white;
  }

  .listCardTitle::after {
    background-image: linear-gradient(to right, transparent, rgba(1, 1, 1, 1) 28%);
  }

  .listCardSubtitle {
    color: white;
  }

  .listCardSubtitle::after {
    background-image: linear-gradient(to right, transparent, rgba(1, 1, 1, 1) 28%);
  }
}

.cardOrganisationImage {
  width: 24px;
  height: 24px;
  background-color: white;
  padding: 1px;
  position: absolute;
  left: -12px;
  bottom: 15px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardOrganisationImageAsset {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.cardOrganisationLink {
  display: block;
  font-size: 12px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  line-height: 1.5em;
  max-height: 1.5em;
}

@include forDarkTheme() {
  .cardOrganisationLink {
    color: #0079d2;
  }
}
