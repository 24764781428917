@import 'OK/styles/util';

.checkBox {
  accent-color: $navigationColor !important;
  pointer-events: none;
}

.container {
  padding: $contentMarginSm 15px $contentMarginSm !important;
  border-radius: 5px;
  max-width: 275px;
  position: relative;
  padding-left: 1px;
  border: 1px solid;
}

.container:hover {
  border-color: $navigationColor !important;
}
.text {
  margin-bottom: $contentMarginSm;
}

.selected {
  border: 2px solid $navigationColor !important;
}

.radio {
  position: absolute;
  right: $contentMarginMd;

  input {
    margin-right: 0 !important;
  }
}

.readOnly {
  pointer-events: none;
}

@include forLightTheme {
  .container {
    border-color: $midtonesColorStatic;
  }

  .selected {
    background-color: rgb(229, 241, 251) !important;
  }

  .readOnly {
    background-color: $midtonesColorStatic;
  }
}

@include forDarkTheme {
  .container {
    border-color: $midtonesColorStaticDark;
  }

  .selected {
    background-color: rgb(0, 103, 178) !important;
  }
  .readOnly {
    background-color: $midtonesColorStaticDark;
  }
}
