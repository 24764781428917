@import 'OK/styles/util';

.addSlideButton {
  align-items: center;
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  flex-direction: column;
  flex: 0 0 100%;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  min-height: 350px;
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: $cardWidthDesktop;
    flex: 0 0 $cardWidthDesktop;
  }

  .addSlideIcon {
    color: $creationColor;
    margin-bottom: $contentMarginSm;
  }
}

.carousel {
  padding-left: $contentMarginLg;
  padding-right: $contentMarginLg;
}

.carouselContainer {
  margin: 0 (-$contentMarginLg);

  @include overlayScrollbarsDefaultScrollbarPadding();

  @include forMinScreenWidth(medium) {
    margin: 0;
  }
}

.document {
  height: 100%;
  width: 100%;
}

.documentSlide {
  margin: 0 $contentMarginSm;
  width: 100%;
  flex: 0 0 100%;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;

    > * {
      margin-right: $contentMarginLg;
    }
  }

  @include forMinScreenWidth(medium) {
    width: $cardWidthDesktop;
    flex: 0 0 $cardWidthDesktop;
  }
}

.errorMessage {
  margin: -15px 0 15px 0;
}

.normalWeight {
  font-weight: normal;
}

.searchInput {
  z-index: 1;
}

.searchInputContainer {
  margin-bottom: $contentMarginMd;
  position: relative;
  z-index: 1;
}

.searchNewButton {
  @include increaseClickableArea(2px, 0, absolute);
  height: $buttonHeight;
  margin: 0;
  right: 30px + $contentMarginSm;
  top: 0;
  width: 44px;
  z-index: 3;
}

.searchResults {
  margin-top: -$contentMarginXs;
  position: absolute;
  width: 100%;
}

.searchResultButton {
  height: $lineHeightMd;
  margin: 0;

  img {
    margin-left: $contentMarginSm;
    width: 1em;
  }
}

.searchResultOKID {
  @include fontMono();
}

.sectionIntro {
  @include forMinScreenWidth(medium) {
    max-width: 400px;
  }
}

.sectionNotice {
  @include forMinScreenWidth(medium) {
    float: right;
    margin: -$contentMarginMd 0 0 0;
    width: 267px;
  }
}

.swipeSidewaysMessage {
  margin-top: 6px;
}

.subSectionHeader {
  border-top-style: solid;
  border-top-width: 1px;
  margin-top: $contentMarginLg;
  padding-top: $contentMarginLg;
}

@include forLightTheme() {
  .addSlideButton {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
    color: $textColor;

    &:hover {
      .addSlideIcon {
        color: $creationColorHover;
      }
    }

    &:active {
      .addSlideIcon {
        color: $creationColorActive;
      }
    }
  }

  .subSectionHeader {
    border-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .addSlideButton {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
    color: $textColorLight;

    &:hover {
      .addSlideIcon {
        color: $creationColorHoverDark;
      }
    }

    &:active {
      .addSlideIcon {
        color: $creationColorActiveDark;
      }
    }
  }

  .subSectionHeader {
    border-color: $midtonesColorStaticDark;
  }
}
