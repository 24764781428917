@use "sass:math";

@import 'OK/styles/util';

// doiuse-disable css-sticky

$desktopDescriptionWidth: 400px;

.accordion {
  padding-top: $contentMarginLg;
  padding-bottom: $contentMarginLg;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 5px;
}

.highlightedAccordion {
  border: 1px solid $navigationColor !important;
}

.basicsSection {
  @include forMinScreenWidth(medium) {
    padding-bottom: $contentMarginSm !important;
  }
}

.basicsSectionContent {
  margin-top: -120px;
  padding-bottom: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: $contentMarginMd;
  }
}

.buttonGroup {
  margin-bottom: $contentMarginMd;
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: $cardWidth3ColumnDesktop;
  }
}

.checkinIndicator {
  display: flex;
  justify-content: space-between;
  background-color: black;
  border-radius: 5px;
  color: white;
  margin-top: 10px;
  padding: 5px 10px;
  min-width: 275px;
}

.checkinSystemButton {
  background-color: black;
  border-radius: 5px;;
  color: white;
  // max-width: 275px;
  // padding: 5px 10px;
  // position: absolute;
  // left: 50%;
  // top: 96px;
  // transform: translateX(-50%);
}

.commentSection {
  padding-top: $contentMarginLg;
}

.commentSectionContent {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.commentSectionHeader {
  margin-bottom: $contentMarginLg;
}

.sectionCard {
  &:last-child {
    padding-bottom: 100px;
  }
}

.detailsTabsContainer {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  position: sticky;
  transition: top ease $timingShort;
  z-index: 10;
  width: 100%;
  margin: 0;
}

.detailsTabsContainerPopup {
  position: fixed;
  z-index: 148;
  background-color: white;
  transition: 100ms all;
}

.sectionCardContent {
  padding-top: $contentMarginLg;
}

.downCaret {
  position: relative;
  top: -2px;
  transform: rotate(90deg);
}

.fileContainer {
  margin-bottom: $contentMarginMd;
  width: 100%;

  @include forMinScreenWidth(medium) {
    display: inline-block;
    margin-right: $contentMarginMd;
    width: $cardWidth3ColumnDesktop;
  }
}

.floatingButtons {
  display: flex;
  position: sticky !important;
  justify-content: space-between;
  margin-top: -70px;
}

.cardContainer {
  display: block;
  width: 100%;
  margin-bottom: 0 !important;

  @include forMinScreenWidth(medium) {
    display: inline-block;
  }
}

.grade {
  margin-right: $contentMarginMd;
}

.gradeBox {
  margin-bottom: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    flex: 1 0 $cardWidth3ColumnDesktop;
    margin-right: $contentMarginMd;

    &:last-child {
      margin-right: 0;
    }
  }
}

.gradeBoxesContainer {
  @include forMinScreenWidth(medium) {
    display: flex;
  }
}

.gradesTab {
  font-weight: normal !important;
}

.gradesTabs {
  margin: 0 -10px;
}

.gradesTabsContainer {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: $contentMarginLg;
  position: sticky;
  transition: top ease $timingShort;
  z-index: 1;
}

.header {
  margin-left: 0;
  font-size: 24px;
  font-weight: bold;
}

.headerAccordion {
  margin-bottom: 0 !important;
  padding: 0 $contentMarginLg;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: 10px;

  @include forMinScreenWidth(medium) {
    flex-direction: row;
    align-items: center;
  }
}

.learnAboutGradesButton {
  margin: 0;
}

.mediaGallery {
  margin-bottom: $contentMarginSm;
  border: 2px solid $contentBackgroundColor;
  border-radius: 5px;

  @include forMinScreenWidth(medium) {
    order: 1;
  }
}

.mediaGalleryEditDone {
  position: absolute;
  top: -35px;
  right: $contentMarginMd;
  z-index: 5;
}

.mediaGalleryPreview {
  margin-bottom: -$contentMarginSm;
}

.mediaGalleryPlaceholder {
  background-color: $midtonesColorStatic;
  border: 2px solid $contentBackgroundColor;
  border-radius: 5px;
  margin-bottom: $contentMarginSm;
  overflow: hidden;
  width: 100%;
  height: fit-content;

  @include forMinScreenWidth(medium) {
    order: 1;
  }
}

.mediaGalleryPlaceholderImage {
  height: 100%;
  width: 100%;
  border-radius: 0 !important;
}

.note {
  height: 100%;
  width: 100%;
}

.noteSlide {
  flex: 0 0 calc(100% - #{$contentMarginLg * 2});
  margin: 0 $contentMarginSm;

  &:first-child {
    margin-left: $contentMarginLg;
  }

  &:last-child {
    > * {
      display: inline-block;
      margin-right: $contentMarginLg;
    }
  }

  @include forMinScreenWidth(medium) {
    flex-basis: $cardWidth3ColumnDesktop;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      > * {
        margin-right: 0;
      }
    }
  }
}

.notesCarousel {
  margin-left: -$contentMarginLg;
  margin-right: -$contentMarginLg;

  @include overlayScrollbarsDefaultScrollbarPadding();
}

.okid {
  @include fontMono();
  margin-bottom: $contentMarginMd !important;
}

.okidNotice {
  font-size: $fontSizeMd;
  line-height: $lineHeightMd;
  margin-bottom: $contentMarginSm;
  margin-top: 0;

  @include forMinScreenWidth(medium) {
    width: 267px;
  }
}

.organisationLogo {
  width: 20px;
  height: 20px;
  margin-right: $contentMarginXs;
}

.organisationName {
  margin-bottom: 0;
  vertical-align: middle;
}

.organisationTag {
  margin-left: $contentMarginXs;
}

.outerSection {
  height: 224px;
}

.outerSectionPopup {
  height: 118px;
}

.partCard {
  display: block !important;
  width: 100%;
}

.partCardInner {
  width: 100% !important;
}

.partSlide {
  padding: 0 $contentMarginSm;
  width: auto;
  flex: 0 0 100%;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    > * {
      margin-right: $contentMarginSm;
    }
  }

  @include forMinScreenWidth(medium) {
    width: auto;
    flex: 0 0 $cardWidth4ColumnDesktop;
  }
}

.partSlideLast {
  margin-right: $contentMarginSm;
}

.partsCarousel {
  padding: 0;
  width: 100%;
}

.partsCarouselContainer {
  @include overlayScrollbarsDefaultScrollbarPadding();
  @include forMinScreenWidth(medium) {
    margin: 0 !important;
  }
}

.partsDescription {
  margin-bottom: $contentMarginLg;
}

.popupMenu {
  padding-left: 0;
  position: fixed;
  z-index: 148;
  background-color: white;
  transition: 100ms all;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.popupMenuBackground {
  position: fixed;
  z-index: 147;
  background-color: rgba(244, 244, 244, 1);
}

.topBorderPopup {
  background-color: transparent;
}

.productDetails {
  order: 2;
  position: relative;

  @include forMinScreenWidth(medium) {
    flex: 1 0;
    margin-left: $contentMarginMd;
  }
}

.productDetailsCard {
  margin-bottom: 0;
  order: 2;
  border-style: solid;
  border-width: 0;
  margin-bottom: -$contentMarginXl;
  position: relative;

  @include forMinScreenWidth(medium) {
    flex: 1 0;
    margin: 0;
  }
}

.productDetailsCardContent {
  padding-bottom: $contentMarginMd !important;
}

.productGrades {
  margin-bottom: $contentMarginSm;
}

.productImageContainer {
  position: relative;
}

.productImageEditButton {
  border: 1px solid $navigationColor !important;
}

.productImageEditButtonContainer {
  position: absolute;
  top: $contentMarginMd;
  right: $contentMarginMd;
  z-index: 1;
}

.productInfoEditButton {
  border: 1px solid $navigationColor !important;
}

.productInfoEditButtonContainer {
  position: absolute;
  top: $contentMarginMd;
  right: $contentMarginMd;
  z-index: 1;

  @include forMinScreenWidth(medium) {
    top: $contentMarginMd;
    right: $contentMarginMd;
  }
}

.productInformationFieldIcon {
  display: inline-block;
  margin-left: $contentMarginSm;
  vertical-align: top !important;
}

.productInformationRow {
  display: flex;
  justify-content: space-between;
}

.productInformationRowFieldWithIcon {
  display: flex;
  align-items: center;
}

.productInformationRowFieldWithIconLast {
  display: flex;
  align-items: center;
  margin-bottom: $contentMarginSm;
}

.recentInspectionLogCard {
  margin-bottom: 0;
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: auto;
  }
}

.recentInspectionLogCardInner {
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: auto;
  }
}

.qrButton {
  margin-top: -5px !important;
  margin-bottom: $contentMarginSm;
  font-family: 'Noto Sans';
  justify-content: flex-end;
  font-size: 14px;
}

.section {
  padding-bottom: $contentMarginLg;
  padding-top: $contentMarginLg;
}

.sectionCard {
  padding: $contentMarginMd 0 0;
  overflow: hidden;
}

.sectionDescription {
  @include forMinScreenWidth(medium) {
    width: $desktopDescriptionWidth;
  }
}

.sectionsSubmenu {
  margin-left: 0;
  padding: 0 !important;
}

.sectionsSubmenuContainer {
  position: sticky;
  top: 0;
  z-index: 5;
  transition: all $timingShort ease-in-out;
}

.sectionsSubmenuContainerMenu {
  top: 105px !important;
}

.sectionsSubmenu::after {
  top: 0;
  right: 2%;
  margin: auto;
  width: 40px;
  height: 44px;

  @include forMinScreenWidth(medium) {
    content: '';
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0%), rgba(0, 0, 0, 1) 0%);
    width: 0;
  }
}

.sectionsTab {
  padding: $contentMarginSm !important;
  font-weight: normal !important;
}

.seeGradesButton {
  font-weight: bold;
  margin: 0;
}

.seeOrganisationProfileButton {
  display: block;
  margin-bottom: $contentMarginSm;

  @include forMinScreenWidth(medium) {
    width: 275px;
  }
}

.shareToast {
  left: -(math.div(160px - $minClickableHeight, 2));
  position: absolute;
  text-align: center;
  top: $contentMarginXs;
  width: 160px;

  @include forMinScreenWidth(medium) {
    left: -(math.div(160px - 69px, 2));
  }
}

.sku {
  margin-bottom: $contentMarginSm;
}

.skuSeparator {
  width: 235px;
}

.submenu {
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.submenuButton {
  padding: 0 !important;
}

.submenuButtonContainer {
  flex-basis: $minClickableHeight;
  flex-shrink: 0;
  height: $minClickableHeight;
  margin: 0;
  justify-content: center;
  position: relative;

  @include forMinScreenWidth(medium) {
    flex-basis: auto;
  }

  button {
    margin: 0;
  }
}

.subMenuContainer {
  border: 0;
}

.submenuGrade {
  margin-right: 15px;
  width: auto !important;

  &:last-child {
    margin-right: 0;
  }
}

.submenuGrades {
  border-left-style: solid;
  border-left-width: 1px;
  display: flex;
  flex: 1 0 164px;
  justify-content: flex-end;
  margin-left: $contentMarginXs;
  padding-left: $contentMarginMd;
  width: 164px;
}

.submenuProductName {
  flex: 1 1 100%;
  margin: 0 auto 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subSection {
  margin-bottom: $contentMarginLg;

  &:not(:last-child) {
    &::after {
      content: '';
      display: block;
      margin: $contentMarginLg $contentMarginMd 0;
      height: 2px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.visualSupplyChainComingSoonText {
  margin: -100% 0 0 0;
  font-size: $fontSizePageHeader;
  font-weight: bold;

  @include forMinScreenWidth(medium) {
    margin: 0;
  }
}

.visualSupplyChainPreview {
  align-items: center;
  background-image: url('/img/visual_supply_chain_coming_soon.jpg');
  background-position: center;
  background-size: cover;
  border-radius: $cardBorderRadius;
  display: flex;
  height: 0;
  justify-content: center;
  padding-top: 100%;
  position: relative;
  width: 100%;

  @include forMinScreenWidth(medium) {
    padding-top: 0;
    height: 400px;
  }
}

@include forLightTheme() {
  .basicsSection,
  .gradesSection,
  .gradesTabsContainer {
    background-color: $contentBackgroundColor;
  }

  .topMenuContainer {
    background-color: $contentBackgroundColor !important;
  }

  .checkinIndicator {
    background-color: black;
    color: white;
  }

  .detailsTabsContainer {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
    z-index: 10;
  }

  .file,
  .outerSection {
    background-color: $contentBackgroundColorCard;
  }

  .accordion {
    background-color: $contentBackgroundColor;
  }

  .gradesTabsContainer {
    border-bottom-color: $midtonesColorStatic;
  }

  .mediaGallery {
    background-color: $contentBackgroundColor;
  }

  .detailsContainer,
  .sectionCard {
    background-color: $contentBackgroundColorCard;
  }

  .submenuGrades {
    border-left-color: $midtonesColorStatic;
  }

  .subSection {
    &:not(:last-child) {
      &::after {
        background-color: $midtonesColorStatic;
      }
    }
  }
}

@include forDarkTheme() {
  .outerSection,
  .sectionCard,
  .detailsContainer,
  .gradesSection,
  .gradesTabsContainer {
    background-color: $contentBackgroundColorCardDark;
  }

  .checkinIndicator {
    background-color: white;
    color: black;
  }

  .topMenuContainer {
    background-color: $contentBackgroundColorDark !important;
  }

  .detailsTabsContainer,
  .detailsTabsContainerPopup,
  .popupMenuBackground,
  .mediaGalleryPlaceholder {
    background-color: $contentBackgroundColorDark;
    border-color: $contentBackgroundColorDark;
  }

  .popupMenu,
  .detailsTabsContainerPopup {
    background-color: $contentBackgroundColorDark;
    border-left: 1px solid $midtonesColorStaticDark;
    border-right: 1px solid $midtonesColorStaticDark;
    box-shadow: none !important;
  }

  .popupMenu {
    border-top: 1px solid $midtonesColorStaticDark;
  }

  .file,
  .accordion {
    background-color: $contentBackgroundColorDark;
  }

  .gradesTabsContainer {
    border-bottom-color: $midtonesColorStaticDark;
  }

  .mediaGallery {
    border: 2px solid $contentBackgroundColorDark;
    background-color: $contentBackgroundColorDark;
  }

  .basicsSection {
    background-color: $contentBackgroundColorDark !important;
  }

  .submenuGrades {
    border-left-color: $midtonesColorStaticDark;
  }

  .subSection {
    &:not(:last-child) {
      &::after {
        background-color: $midtonesColorStaticDark;
      }
    }
  }
}

.searchInput {
  z-index: 1;
}

.searchInputContainer {
  margin-bottom: $contentMarginMd;
  position: relative;
}

.searchNewButton {
  @include increaseClickableArea(2px, 0, absolute);
  height: $buttonHeight;
  margin: 0;
  right: 30px + $contentMarginSm;
  top: 0;
  width: 44px;
  z-index: 3;
}

.searchResults {
  margin-top: -$contentMarginXs;
  position: absolute;
  width: 100%;
}

.searchResultButton {
  height: $lineHeightMd;
  margin: 0;

  img {
    margin-left: $contentMarginSm;
    width: 1em;
  }
}

.searchResultOKID {
  @include fontMono();
}

.searchSuggestionAction {
  height: $lineHeightMd;
  margin: 0;
}

.supportedFileTypesNotice {
  margin: $contentMarginMd 0 0;
}

.videoIcon {
  position: relative;
  left: 2px;
}
