@import 'OK/styles/util';

.container {
  flex: 1 1;
  height: $inputHeight;
  position: relative;

  button {
    align-items: center;
    border-radius: $inputBorderRadius;
    border-style: solid;
    border-width: 1px;
    bottom: 0;
    display: flex;
    height: $buttonHeight;
    left: 0;
    padding: 0 16px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;

    .caretIcon {
      position: relative;
      top: -2px;
      transform: rotate(90deg);
    }

    &.caret_left {
      .caretIcon {
        margin-right: $contentMarginSm;
      }
    }

    &.caret_right {
      .caretIcon {
        margin-left: $contentMarginSm;
      }
    }

    .transparentSelect {
      border-radius: 0 !important;
      border-style: none !important;
      border-width: 0px !important;
      background: transparent !important;
    }
  }

  select {
    min-height: 100%;
    opacity: 0.01;
    width: 100%;

    &:not(:disabled):hover {
      cursor: pointer;
    }

    &:not(:disabled):focus {
      ~ button {
        border-width: 2px !important;
        padding: 0 15px;
      }
    }
  }
}

.text {
  flex: 1;
  line-height: $buttonHeight;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}

.transparentButton {
  border-radius: 0 !important;
  border-style: none !important;
  border-width: 0px !important;
  background: transparent !important;
  padding: 0 !important;
  top: -13px !important;

  @include increaseClickableArea(20px);
}

.transparentSelect {
  min-width: 120px;
}
