@import 'OK/styles/util';

:global(.theme_light) {
  .link {
    color: $navigationColorMidtone;

    &:hover {
      color: $navigationColorMidtoneHover;
    }

    &:active {
      color: $navigationColorMidtoneActive;
    }

    &.alert {
      color: $alertColorMidtone;

      &:hover {
        color: $alertColorMidtoneHover;
      }

      &:active {
        color: $alertColorMidtoneActive;
      }
    }

    &.creation {
      color: $creationColorMidtone;

      &:hover {
        color: $creationColorMidtoneHover;
      }

      &:active {
        color: $creationColorMidtoneActive;
      }
    }

    &.notification {
      color: $notificationColorMidtone;

      &:hover {
        color: $notificationColorMidtoneHover;
      }

      &:active {
        color: $notificationColorMidtoneActive;
      }
    }
  }

  .button {
    background-color: $buttonBackgroundColor;
    color: $buttonTextColor;

    &:hover {
      background-color: $buttonBackgroundColorHover;
      color: $buttonTextColor;
    }

    &:active {
      background-color: $buttonBackgroundColorActive;
      color: $buttonTextColor;
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabled;
      color: $buttonTextColor;
    }

    &.alert {
      background-color: $alertColorMidtone;

      &:hover {
        background-color: $alertColorMidtoneHover;
      }

      &:active {
        background-color: $alertColorMidtoneActive;
      }
    }

    &.creation {
      background-color: $creationColorMidtone;

      &:hover {
        background-color: $creationColorMidtoneHover;
      }

      &:active {
        background-color: $creationColorMidtoneActive;
      }
    }

    &.navigation {
      background-color: $navigationColorMidtone;

      &:hover {
        background-color: $navigationColorMidtoneHover;
      }

      &:active {
        background-color: $navigationColorMidtoneActive;
      }
    }

    &.notification {
      background-color: $notificationColorMidtone;

      &:hover {
        background-color: $notificationColorMidtoneHover;
      }

      &:active {
        background-color: $notificationColorMidtoneActive;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColor;
      color: $buttonTextColorAlternate;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHover;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActive;
      }
    }
  }
}

:global(.theme_dark) {
  .link {
    color: $navigationColorMidtoneDark;

    &:hover {
      color: $navigationColorMidtoneHoverDark;
    }

    &:active {
      color: $navigationColorMidtoneActiveDark;
    }

    &.alert {
      color: $alertColorMidtoneDark;

      &:hover {
        color: $alertColorMidtoneHoverDark;
      }

      &:active {
        color: $alertColorMidtoneActiveDark;
      }
    }

    &.creation {
      color: $creationColorMidtoneDark;

      &:hover {
        color: $creationColorMidtoneHoverDark;
      }

      &:active {
        color: $creationColorMidtoneActiveDark;
      }
    }

    &.notification {
      color: $notificationColorMidtoneDark;

      &:hover {
        color: $notificationColorMidtoneHoverDark;
      }

      &:active {
        color: $notificationColorMidtoneActiveDark;
      }
    }
  }

  .button {
    background-color: $buttonBackgroundColorDark;
    color: $buttonTextColorAlternate;

    &:hover {
      background-color: $buttonBackgroundColorHoverDark;
    }

    &:active {
      background-color: $buttonBackgroundColorActiveDark;
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabledDark;
    }

    &.alert {
      background-color: $alertColorMidtoneDark;

      &:hover {
        background-color: $alertColorMidtoneHoverDark;
      }

      &:active {
        background-color: $alertColorMidtoneActiveDark;
      }
    }

    &.creation {
      background-color: $creationColorMidtoneDark;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $creationColorMidtoneHoverDark;
      }

      &:active {
        background-color: $creationColorMidtoneActiveDark;
      }
    }

    &.navigation {
      background-color: $navigationColorMidtoneDark;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $navigationColorMidtoneHoverDark;
      }

      &:active {
        background-color: $navigationColorMidtoneActiveDark;
      }
    }

    &.notification {
      background-color: $notificationColorMidtoneDark;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $notificationColorMidtoneHoverDark;
      }

      &:active {
        background-color: $notificationColorMidtoneActiveDark;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColorDark;
      color: $buttonTextColor;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHoverDark;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActiveDark;
      }
    }
  }
}
