@import 'OK/styles/util';

.content {
  display: flex;
  flex-direction: column;
}

.manageButton {
  margin: 0;
}

.pointsRow {
  flex-direction: row !important;
}

.tag {
  margin-right: $contentMarginXs;
}

.tagRow {
  margin-bottom: 0;
  margin-top: $contentMarginXs;
}

.testLevel {
  @include fontDefault();
}

.title {
  margin-bottom: auto;
}

.titleLanguageTag {
  margin-right: $contentMarginXs;
}
