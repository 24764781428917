@import 'OK/styles/util';

@include forLightTheme() {
  .alert {
    color: $alertColorMidtone;
  }

  .creation {
    color: $creationColorMidtone;
  }

  .navigation {
    color: $navigationColorMidtone;
  }

  .notification {
    color: $notificationColorMidtone;
  }
}

@include forDarkTheme() {
  .alert {
    color: $alertColorMidtoneDark;
  }

  .creation {
    color: $creationColorMidtoneDark;
  }

  .navigation {
    color: $navigationColorMidtoneDark;
  }

  .notification {
    color: $notificationColorMidtoneDark;
  }
}
