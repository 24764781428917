@import 'OK/styles/util';

.content {
  display: flex;
  flex-direction: column;
}

.tag {
  margin-right: $contentMarginXs;
  margin-top: $contentMarginXs;
}

.tagRow {
  margin-bottom: 0;
  max-height: 48px;
  overflow: hidden;
}

.title {
  height: $lineHeightSm * 2;
  margin-bottom: auto;
}
