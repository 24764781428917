@import 'OK/styles/util';

.section {
  height: 2px;
  margin: $contentMarginLg 0;
}

.subSection {
  height: 1px;
  margin: $contentMarginSm 0 $contentMarginMd;
}

@include forLightTheme() {
  .separator {
    background-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .separator {
    background-color: $midtonesColorStaticDark;
  }
}
