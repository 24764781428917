@import 'OK/styles/util';

.effectContainer {
  opacity: 0;
  position: relative;
}

.appear,
.appearDone,
.enter,
.enterDone {
  opacity: 1;
}

.exit,
.exitDone {
  opacity: 0;
}
