@import 'OK/styles/util';

.cancelButton {
  margin-bottom: 0;
  margin-top: $contentMarginSm;
}

.errorMessage {
  margin-bottom: 0;
  margin-top: $contentMarginXs;
}

.input {
  margin-bottom: $contentMarginSm;
}

.removeButton {
  margin-bottom: $contentMarginSm;
}

.saveButton {
  margin-bottom: 0;
}

.tag {
  margin-bottom: 0 !important;

  @include fontMono();
}

.primaryButton {
  margin-bottom: $contentMarginSm;
  margin-left: $contentMarginMd;
}

.primaryTag {
  margin-left: $contentMarginSm;
}
