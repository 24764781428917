@import 'OK/styles/util';

.closeButton {
  margin: 0;
}

.findings {
  margin-bottom: 0;
}

.findingsContainer {
  flex: 1 0 105px;
}

.findingsLabel {
  margin-bottom: $contentMarginXs;
}

.grade {
  margin: 0 $contentMarginMd 0 0 !important;

  &:last-child {
    margin-right: 0 !important;
  }
}

.header {
  align-items: center;
  display: flex;
  margin: $contentMarginLg $contentMarginLg 0;
}

.logsLink {
  margin: 0;
}

.okid {
  margin: 0 auto 0 0;
}

.organisationInfoLink {
  display: block;
  margin: 0;
}

.organisationName {
  margin-bottom: $contentMarginXs;
}

.organisationTag {
  margin-left: $contentMarginXs;
}

.productDetails {
  flex: 1 1 50%;
  order: 1;

  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginMd;
  }
}

.productDetailsContainer {
  padding: $contentMarginMd $contentMarginLg $contentMarginXl;

  @include forMinScreenWidth(medium) {
    display: flex;
  }
}

.productImage {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  float: right;
  height: 78px;
  margin-left: $contentMarginMd;
  width: 78px;

  @include forMinScreenWidth(medium) {
    flex: 0 0 245px;
    float: none;
    height: 245px;
    margin-left: $contentMarginLg;
    order: 2;
    width: 245px;
  }
}

.productName {
  margin-bottom: $contentMarginSm;
  min-height: 48px;
}

.productNameAndImageContainer {
  margin-bottom: 15px;
}

.productStats {
  display: flex;
}

.productStatsContainer {
  display: flex;
  margin-bottom: 15px;
}

.sku {
  margin-bottom: $contentMarginXs;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.skuAndGrades {
  flex: 1 1 145px;
  overflow: hidden;

  &.sideMargin {
    border-right-style: solid;
    border-right-width: 1px;
    margin-right: $contentMarginSm;
    padding-right: $contentMarginSm - 1px;
  }
}

@include forLightTheme() {
  .productImage {
    border-color: $midtonesColorStatic;
  }

  .skuAndGrades {
    border-right-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .productImage {
    border-color: $midtonesColorStaticDark;
  }

  .skuAndGrades {
    border-right-color: $midtonesColorStaticDark;
  }
}
