@import './util';
@import './components/slider';

/* Global resets and default styles */

:root {
  @include propertyWithFallbackValue(--safeAreaInsetBottom, env(safe-area-inset-bottom, 0), 0);
  @include propertyWithFallbackValue(--safeAreaInsetLeft, env(safe-area-inset-left, 0), 0);
  @include propertyWithFallbackValue(--safeAreaInsetRight, env(safe-area-inset-right, 0), 0);
  @include propertyWithFallbackValue(--safeAreaInsetTop, env(safe-area-inset-top, 0), 0);
  --tabBarHeight: #{$mainMenuTabsHeightPlusBottomSafeArea};
}

body {
  font-family: 'Noto Sans', Arial, sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  color: $navigationColor;
}

a:hover {
  color: $navigationColorHover;
  cursor: pointer;
}

a:active {
  color: $navigationColorActive;
}

body {
  font-size: 16px;
  line-height: $lineHeightMd;
}

button {
  background: none;
  border: 0;

  &:not(:disabled) {
    cursor: pointer;
  }
}

button:focus,
input:focus {
  outline: none;
}

input::placeholder {
  line-height: normal;
}

img {
  vertical-align: middle;
}

/* Text styles */

a {
  text-decoration: none;
}

sub,
sup {
  font-size: 65%;
  line-height: inherit;
}

sub {
  bottom: -0.16rem;
  vertical-align: bottom;
}

sup {
  top: -0.15rem;
  vertical-align: top;
}

/* Default margins for text */

h1,
h2 {
  margin: 0 0 $contentMarginMd;
}

h3,
h4,
h5,
h6,
p {
  margin: 0 0 $contentMarginMd 0;
}

/* Header default styles */

h1 {
  font-size: $fontSizeFocal;
  line-height: $lineHeightFocal;
}

h2 {
  font-size: $fontSizePageHeader;
  line-height: $lineHeightPageHeader;
}

h3 {
  font-size: $fontSizeCardHeader;
  line-height: $lineHeightCardHeader;
}

h4 {
  font-size: $fontSizeSectionHeader;
  line-height: $lineHeightSectionHeader;
}

h5 {
  font-size: $fontSizeSubsectionHeader;
  line-height: $lineHeightSubsectionHeader;
}

h6 {
  font-size: 1em;
  line-height: 1.5em;
}

/* Ionic */

ion-toggle {
  --handle-width: 16px;
  height: 20px;
  width: 40px;
}

// Hide Samsung scroll-to-top button
#scroll-btn {
  display: none;
}

/* Theme */

// Light theme

.theme_light {
  // React Pure Carousel
  .carousel__dot-group button {
    background-color: $brandColor;
    border-color: $brandColor;

    &:not(:disabled) {
      background: $brandColor;
    }
  }
}

// Dark theme

.theme_dark {
  // React Pure Carousel
  .carousel__dot-group button {
    background-color: $brandColorAlternate;
    border-color: $brandColorAlternate;

    &:not(:disabled) {
      background: none;
    }
  }
}
