@import 'OK/styles/util';

:global(.theme_light) {
  // Button style
  .button {
    background-color: $buttonBackgroundColor;
    color: $buttonTextColor;

    &:hover {
      background-color: $buttonBackgroundColorHover;
    }

    &:active {
      background-color: $buttonBackgroundColorActive;
    }

    &.alert {
      background-color: $alertColorMidtone;

      &:hover {
        background-color: $alertColorMidtoneHover;
      }

      &:active {
        background-color: $alertColorMidtoneActive;
      }
    }

    &.creation {
      background-color: $creationColorMidtone;

      &:hover {
        background-color: $creationColorMidtoneHover;
      }

      &:active {
        background-color: $creationColorMidtoneActive;
      }
    }

    &.navigation {
      background-color: $navigationColorMidtone;

      &:hover {
        background-color: $navigationColorMidtoneHover;
      }

      &:active {
        background-color: $navigationColorMidtoneActive;
      }
    }

    &.notification {
      background-color: $notificationColorMidtone;

      &:hover {
        background-color: $notificationColorMidtoneHover;
      }

      &:active {
        background-color: $notificationColorMidtoneActive;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColor;
      border: 1px solid $buttonSecondaryBackgroundColor;
      color: $buttonTextColorAlternate;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHover;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActive;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabled !important;
      color: $buttonTextColorAlternate !important;
    }
  }

  // Link style
  .link {
    color: $navigationColorMidtone;

    &:hover {
      color: $navigationColorMidtoneHover;
    }

    &:active {
      color: $navigationColorMidtoneActive;
    }

    &.alert {
      color: $alertColorMidtone;

      &:hover {
        color: $alertColorMidtoneHover;
      }

      &:active {
        color: $alertColorMidtoneActive;
      }
    }

    &.creation {
      color: $creationColorMidtone;

      &:hover {
        color: $creationColorMidtoneHover;
      }

      &:active {
        color: $creationColorMidtoneActive;
      }
    }

    &.notification {
      color: $notificationColorMidtone;

      &:hover {
        color: $notificationColorMidtoneHover;
      }

      &:active {
        color: $notificationColorMidtoneActive;
      }
    }

    &.secondary {
      color: $brandColor;
    }
  }

  // Toggle
  .toggle {
    background-color: $midtonesColorStatic;
    border-color: $brandColor;

    .toggleActiveLayer {
      background-color: $brandColor;
    }
  }

  // Inverted style
  .button.inverted {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
    color: $buttonTextColorAlternate;

    &:hover {
      background-color: $contentBackgroundColorCard;
    }

    &:active {
      background-color: $contentBackgroundColorFocus;
    }

    // Tints

    &.alert {
      color: $alertColor;

      &:hover {
        color: $alertColorCardHover;
      }

      &:active {
        color: $alertColorCardActive;
      }
    }

    &.creation {
      color: $creationColor;

      &:hover {
        color: $creationColorCardHover;
      }

      &:active {
        color: $creationColorCardActive;
      }
    }

    &.navigation {
      color: $navigationColor;

      &:hover {
        color: $navigationColorCardHover;
      }

      &:active {
        color: $navigationColorCardActive;
      }
    }

    &.notification {
      color: $notificationColor;

      &:hover {
        color: $notificationColorCardHover;
      }

      &:active {
        color: $notificationColorCardActive;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabled !important;
      color: $buttonTextColorAlternate !important;
    }
  }
}

:global(.theme_dark) {
  // Button style
  .button {
    background-color: $buttonBackgroundColorDark;
    color: $buttonTextColorAlternate;

    &:hover {
      background-color: $buttonBackgroundColorHoverDark;
    }

    &:active {
      background-color: $buttonBackgroundColorActiveDark;
    }

    &.alert {
      background-color: $alertColorMidtone;
      color: $buttonTextColor;

      &:hover {
        background-color: $alertColorMidtoneHoverDark;
      }

      &:active {
        background-color: $alertColorMidtoneActiveDark;
      }
    }

    &.creation {
      background-color: $creationColorMidtone;
      color: $buttonTextColor;

      &:hover {
        background-color: $creationColorMidtoneHoverDark;
      }

      &:active {
        background-color: $creationColorMidtoneActiveDark;
      }
    }

    &.navigation {
      background-color: $navigationColorMidtone;
      color: $buttonTextColor;

      &:hover {
        background-color: $navigationColorMidtoneHoverDark;
      }

      &:active {
        background-color: $navigationColorMidtoneActiveDark;
      }
    }

    &.notification {
      background-color: $notificationColorMidtone;
      color: $buttonTextColor;

      &:hover {
        background-color: $notificationColorMidtoneHoverDark;
      }

      &:active {
        background-color: $notificationColorMidtoneActiveDark;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColorDark;
      border: 1px solid $buttonSecondaryBackgroundColorDark;
      color: $buttonTextColor;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHoverDark;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActiveDark;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabledDark !important;
      color: $buttonTextColor !important;
    }
  }

  // Link style
  .link {
    color: $navigationColorMidtoneDark;

    &:hover {
      color: $navigationColorMidtoneHoverDark;
    }

    &:active {
      color: $navigationColorMidtoneActiveDark;
    }

    &.alert {
      color: $alertColorMidtoneDark;

      &:hover {
        color: $alertColorMidtoneHoverDark;
      }

      &:active {
        color: $alertColorMidtoneActiveDark;
      }
    }

    &.creation {
      color: $creationColorMidtoneDark;

      &:hover {
        color: $creationColorMidtoneHoverDark;
      }

      &:active {
        color: $creationColorMidtoneActiveDark;
      }
    }

    &.notification {
      color: $notificationColorMidtoneDark;

      &:hover {
        color: $notificationColorMidtoneHoverDark;
      }

      &:active {
        color: $notificationColorMidtoneActiveDark;
      }
    }

    &.secondary {
      color: $brandColorAlternate;
    }
  }

  // Toggle
  .toggle {
    background-color: $midtonesColorStaticDark;
    border-color: $brandColorAlternate;

    .toggleActiveLayer {
      background-color: $brandColorAlternate;
    }
  }

  // Inverted style
  .button.inverted {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
    color: $buttonTextColor;

    &:hover {
      background-color: $contentBackgroundColorCardDark;
    }

    &:active {
      background-color: $contentBackgroundColorFocusDark;
    }

    // Tints

    &.alert {
      color: $alertColor;

      &:hover {
        color: $alertColorCardHoverDark;
      }

      &:active {
        color: $alertColorCardActiveDark;
      }
    }

    &.creation {
      color: $creationColor;

      &:hover {
        color: $creationColorCardHoverDark;
      }

      &:active {
        color: $creationColorCardActiveDark;
      }
    }

    &.navigation {
      color: $navigationColor;

      &:hover {
        color: $navigationColorCardHoverDark;
      }

      &:active {
        color: $navigationColorCardActiveDark;
      }
    }

    &.notification {
      color: $notificationColor;

      &:hover {
        color: $notificationColorCardHoverDark;
      }

      &:active {
        color: $notificationColorCardActiveDark;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabledDark !important;
      color: $buttonTextColor !important;
    }
  }
}
