@import 'OK/styles/util';

.accessoryView {
  border-radius: 0 0 $cardBorderRadius $cardBorderRadius;
  flex: 0 0;
  padding: $contentMarginSm $contentMarginMd;
}

.card {
  display: flex;
  flex-direction: column;

  &.fixedWidth {
    width: $cardWidthMobile;

    @include forMinScreenWidth(medium) {
      width: $cardWidthDesktop;
    }
  }

  &.padded {
    > .children {
      padding: $contentMarginMd $contentMarginMd $contentMarginLg;
    }
  }

  &.rounded {
    border-radius: $cardBorderRadius;
  }
}

.children {
  flex: 1 1;
}

// Light theme
:global(.theme_light) {
  .accessoryView {
    background-color: $midtonesColorStatic;
  }

  .card {
    background-color: $contentBackgroundColorCard;

    &.invert {
      background-color: $contentBackgroundColor;
    }
  }
}

// Dark theme
:global(.theme_dark) {
  .accessoryView {
    background-color: $midtonesColorStaticDark;
  }

  .card {
    background-color: $contentBackgroundColorCardDark;

    &.invert {
      background-color: $contentBackgroundColorDark;
    }
  }
}
