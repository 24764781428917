@import 'OK/styles/util';

.container {
  max-width: 355px;
  :last-child {
    margin-bottom: 0;
  }
}

.codeInput {
  height: auto;

  input {
    font-size: 2rem;
    font-weight: bold;
  }

  // Hide browser arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
  }

  input[type='number'] {
    appearance: textfield;
  }
}

.errorMessage {
  margin-top: -15px;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;

  > * {
    margin: 0;
  }
}

.input {
  margin-bottom: $contentMarginMd;
}
