@import 'OK/styles/util';

.appear,
.appearActive,
.appearDone {
  position: absolute;
  left: 100%;
}

.transitionContainer {
  position: absolute;
  transition: all $timingShort ease-in-out;
  width: 100%;

  &.fromLeft {
    right: 100%;
  }

  &.fromRight {
    left: 100%;
  }
}
