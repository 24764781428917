@import 'OK/styles/util';

.addTranslationButton {
  margin: $contentMarginLg 0 0;
}

.input {
  margin-top: $contentMarginSm;
}

.inputMessage {
  color: $notificationColor;
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  margin: $contentMarginXs 0 (-$contentMarginXs) 0;
}

.label {
  display: block;
  margin-bottom: $contentMarginMd;
}

.labelText {
  align-items: center;
  display: flex;
}

.removeLanguageButton {

  @include increaseClickableArea(10px, 0);
  height: $lineHeightMd;
  margin: 0 0 0 auto;
}

.searchInput {
  margin-top: $contentMarginLg;
}
