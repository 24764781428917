@use 'sass:color';
@import 'OK/styles/util';

.background {
  height: 100%;
  left: 0;
  overflow-y: scroll;
  padding: 57px $contentMarginSm 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 150;

  @media (min-width: 690px) {
    padding: 57px 28px 0;
  }

  @media (min-width: 1155px) {
    padding: 57px $contentMarginSm 0;
  }

  // Fade in transition

  opacity: 0;
  transition: opacity $timingShort ease;

  &.enter {
    opacity: 1;
  }

  &.exit {
    opacity: 0;
  }
}

.buttonsContainer {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: $contentMarginSm 0 $contentMarginMd;
  position: fixed;
  width: 100%;
  z-index: 150;

  button {
    margin: 0 $contentMarginSm 0 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.menu {
  top: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: $contentMarginSm 0 $contentMarginMd;
  position: fixed;
  width: 100%;
  z-index: 150;
}

.popup {
  border-radius: $cardBorderRadius;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  margin: 0 auto;
  min-width: $cardWidthMobile;
  overflow-x: hidden;
  overflow-y: scroll;
  max-width: 450px;

  @include forMinScreenWidth(medium) {
    max-width: 1090px;
  }

  &.reducedSidePadding {
    padding-left: $contentMarginSm;
    padding-right: $contentMarginSm;
  }

  // Slide up transition

  transform: translateY(50px);
  transition: transform $timingShort ease;

  &.enter {
    transform: translateY(0px);
  }

  &.exit {
    transform: translateY(50px);
  }
}

@include forLightTheme() {
  .background {
    background-color: color.change($midtonesColorStatic, $alpha: 0.7);
  }

  .buttonsContainer {
    background: linear-gradient(color.change($contentBackgroundColor, $alpha: 0), $contentBackgroundColor);
  }

  .popup {
    background-color: $contentBackgroundColor;
  }
}

@include forDarkTheme() {
  .background {
    background-color: color.change($midtonesColorStaticDark, $alpha: 0.7);
  }

  .buttonsContainer {
    background: linear-gradient(color.change($contentBackgroundColorDark, $alpha: 0), $contentBackgroundColorDark);
  }

  .popup {
    background-color: $contentBackgroundColorDark;
  }
}
