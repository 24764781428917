@import 'OK/styles/util';

.otpgroup {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 7px;
}

.container {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;

  &.focused {
    border-width: 2px;
  }

  // Error state
  &.error {
    border-color: $alertColorCard !important;

    .input {
      color: $alertColorCard;
    }
  }

  // Read-only state
  &.readOnly {
    border: 0;

    .input {
      font-weight: bold;
    }
  }

  // Disabled state
  &.disabled {
    opacity: 0.5;
  }
}

// Light theme

@include forLightTheme() {
  .container {
    background-color: $contentBackgroundColor;
    border-color: $inputBorderColorInactive;
    color: $textColor;

    &::placeholder {
      color: $midtonesColorText;
    }

    &.hasValue,
    &.focused {
      border-color: $inputBorderColorActive;
    }

    &.hovered {
      border-color: $navigationColor;
    }

    &.readOnly {
      background-color: $midtonesColorStatic;

      .input {
        background-color: $midtonesColorStatic;
      }
    }
  }
}

// Dark theme

@include forDarkTheme() {
  .container {
    background-color: $contentBackgroundColorDark;
    border-color: $inputBorderColorInactiveDark;
    color: $textColorLight;

    &::placeholder {
      color: $midtonesColorTextDark;
    }

    &.hovered {
      border-color: $navigationColor;
    }

    &.hasValue,
    &.focused {
      border-color: $inputBorderColorActiveDark;
    }

    &.readOnly {
      background-color: $midtonesColorStaticDark;

      .input {
        background-color: $midtonesColorStaticDark;
      }
    }
  }
}
