@import 'OK/styles/util';

@include forLightTheme() {
  .default {
    .button {
      background-color: $contentBackgroundColorCard;
      border-color: $midtonesColorStatic;
      color: $textColor;

      &.active {
        background-color: $navigationColor;
        border-color: $navigationColor;
        color: $textColorLight;
      }
    }
  }

  .separate {
    .button {
      background-color: $midtonesColorStatic;
      color: $textColor;

      &.active {
        background-color: #141515;
        color: $textColorLight;
      }
    }
  }

  .separateOutline {
    .button {
      background-color: transparent;
      border: 1px solid #767676;
      color: #767676;
      font-weight: normal;

      &.active {
        border: 1px solid #141515;
        color: #141515;
      }
    }
  }
}

@include forDarkTheme() {
  .default {
    .button {
      background-color: $contentBackgroundColorCardDark;
      border-color: $midtonesColorStaticDark;
      color: $textColorLight;

      &.active {
        background-color: $navigationColor;
        border-color: $navigationColor;
        color: $textColorLight;
      }
    }
  }

  .separate {
    .button {
      background-color: $midtonesColorStaticDark;
      color: $textColorLight;

      &.active {
        background-color: #f4f4f4;
        color: $textColor;
      }
    }
  }

  .separateOutline {
    .button {
      background-color: transparent;
      border: 1px solid #454646;
      color: #454646;
      font-weight: normal;

      &.active {
        border: 1px solid #f4f4f4;
        color: #f4f4f4;
      }
    }
  }
}
