@import 'OK/styles/util';

$buttonMaxWidthDesktop: 247px;
$paragraphMaxWidthDesktop: 400px;

.basicsContainer {
  padding-bottom: $contentMarginXl;
  padding-top: $minClickableHeight * 2;
}

.basicsFieldsContainer {
  @include forMinScreenWidth(medium) {
    display: flex;
    justify-content: space-between;

    > div {
      flex-basis: 50%;
    }
  }
}

.blockButton {
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: $buttonMaxWidthDesktop;
  }
}

.commentSectionHeader {
  margin-bottom: $contentMarginLg;
  margin-top: $contentMarginXl;
  padding-top: $contentMarginLg;
  border-top-width: 1px;
  border-top-style: solid;
}

.commentSection {
  padding-bottom: $contentMarginXl;
}

@include forLightTheme() {
  .commentSectionHeader {
    border-color: $midtonesColorStatic;
  }

  .commentSection {
    background-color: $contentBackgroundColor;
  }
}

@include forDarkTheme() {
  .commentSectionHeader {
    border-color: $midtonesColorStaticDark;
  }

  .commentSection {
    background-color: $contentBackgroundColorDark;
  }
}

.confirmDeleteButton {
  margin-bottom: $contentMarginSm;
}

.deleteButtonContainer {
  display: inline-block;
  margin-left: auto;
  position: relative;

  button {
    margin-bottom: 0;
  }
}

.fieldError {
  margin-bottom: 15px;
  margin-top: $contentMarginXs;
}

.floatingButtons {
  position: sticky;
  bottom: 0 !important;
  margin-top: -70px;
}

.header {
  align-items: center;
  display: flex;
  margin-bottom: $contentMarginMd;

  > * {
    margin-bottom: 0;
  }
}

.headerRefId {
  margin-left: $contentMarginSm;
  font-size: $fontSizePageHeader !important;
  line-height: $lineHeightPageHeader !important;
}

.identifiersContainer {
  @include forMinScreenWidth(medium) {
    order: 2;
    padding-left: $contentMarginLg;
  }
}

.identifierTag {
  margin: 0 $contentMarginSm $contentMarginSm 0;
}

.input {
  width: 100%;
}

.internalTabs {
  padding-left: $contentMarginSm + 5px;
}

.internalMenu {
  position: sticky !important;
}

.absoluteMenu {
  position: sticky;
  top: 0;
  z-index: 2;
}

@include forLightTheme() {
  .absoluteMenu {
    background-color: $contentBackgroundColor;
  }
}

@include forDarkTheme() {
  .absoluteMenu {
    background-color: $contentBackgroundColorDark;
  }
}

.linksContainer {
  padding: $contentMarginLg 0 $contentMarginXl;
}

.menuTitle {
  margin: 0;
}

.nameContainer {
  margin-top: $contentMarginLg;

  @include forMinScreenWidth(medium) {
    margin-top: 0;
    order: 1;
    padding-right: $contentMarginLg;
  }
}

.linkSection {
  padding-top: $contentMarginLg;
}

.pageSubmenu {
  background-color: white;
}

.popupMenu {
  padding-left: 0;
  position: fixed;
  z-index: 148;
  background-color: white;
  transition: 100ms all;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.popupMenuBackground {
  position: fixed;
  z-index: 147;
  background-color: rgba(244, 244, 244, 1);
}

.topBorderPopup {
  background-color: transparent;
}

.searchContainer {
  position: relative;
  z-index: 1;
}

.searchInput {
  z-index: 1;
}

.searchSuggestions {
  position: absolute;
  top: $inputHeight - $contentMarginXs;
  left: 0;
  width: 100%;

  // @include forMinScreenWidth(medium) {
  //   width: $buttonMaxWidthDesktop;
  // }
}

.sectionDescription {
  @include forMinScreenWidth(medium) {
    width: $paragraphMaxWidthDesktop;
  }
}

.shareToast {
  position: absolute;
  right: -$contentMarginMd;
  text-align: center;
  top: $contentMarginXs;
  width: 160px;
}

.submenu {
  align-items: center;
  display: flex;
  width: 100%;
}

.tabs {
  margin-left: -7px;
}

.tabsContainer {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.recentInspectionLogCard {
  width: 100%;
}

.recentInspectionLogCardInner {
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: auto;
  }
}

.recentInspectionLogSlide {
  margin-right: $contentMarginMd;
  flex: 1 0 calc(100% - #{$contentMarginLg * 2});

  &:first-child {
    margin-left: $contentMarginLg;
  }

  &:last-child > * {
    margin-right: $contentMarginLg;
  }

  @include forMinScreenWidth(medium) {
    flex-basis: $cardWidth4ColumnDesktop;
    flex-grow: 0;

    &:first-child,
    &:last-child > * {
      margin-left: 0;
    }
  }
}

.recentInspectionsCarousel {
  margin-left: -$contentMarginLg;
  margin-right: -$contentMarginLg;

  @include forMinScreenWidth(medium) {
    margin-left: 0;
    margin-right: 0;
  }

  @include overlayScrollbarsDefaultScrollbarPadding();
}

.unbold {
  font-weight: normal;
}

@include forLightTheme() {
  .basicsContainer {
    background-color: $contentBackgroundColorCard;
  }

  .tabsContainer {
    border-bottom-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .basicsContainer,
  .popupMenu,
  .pageSubmenu {
    background-color: $contentBackgroundColorCardDark;
  }

  .popupMenuBackground,
  .pageSubmenu {
    background-color: $contentBackgroundColorDark;
  }

  .popupMenu {
    background-color: $contentBackgroundColorDark;
    border-left: 1px solid $midtonesColorStaticDark;
    border-right: 1px solid $midtonesColorStaticDark;
    box-shadow: none !important;
  }

  .popupMenu {
    border-top: 1px solid $midtonesColorStaticDark;
  }

  .tabsContainer {
    border-bottom-color: $midtonesColorStaticDark;
  }
}
