@import 'OK/styles/util';


.toggleRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $contentMarginMd;
  
    p {
      margin: 0;
    }
  }
  