@import 'OK/styles/util';

:global(.theme_light) {
  .link {
    color: $navigationColorCard;

    &:hover {
      color: $navigationColorCardHover;
    }

    &:active {
      color: $navigationColorCardActive;
    }

    &.alert {
      color: $alertColorCard;

      &:hover {
        color: $alertColorCardHover;
      }

      &:active {
        color: $alertColorCardActive;
      }
    }

    &.creation {
      color: $creationColorCard;

      &:hover {
        color: $creationColorCardHover;
      }

      &:active {
        color: $creationColorCardActive;
      }
    }

    &.notification {
      color: $notificationColorCard;

      &:hover {
        color: $notificationColorCardHover;
      }

      &:active {
        color: $notificationColorCardActive;
      }
    }
  }

  .button {
    background-color: $buttonBackgroundColor;
    color: $buttonTextColor;

    &:hover {
      background-color: $buttonBackgroundColorHover;
    }

    &:active {
      background-color: $buttonBackgroundColorActive;
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabled;
    }

    &.alert {
      background-color: $alertColorCard;

      &:hover {
        background-color: $alertColorCardHover;
      }

      &:active {
        background-color: $alertColorCardActive;
      }
    }

    &.creation {
      background-color: $creationColorCard;

      &:hover {
        background-color: $creationColorCardHover;
      }

      &:active {
        background-color: $creationColorCardActive;
      }
    }

    &.navigation {
      background-color: $navigationColorCard;

      &:hover {
        background-color: $navigationColorCardHover;
      }

      &:active {
        background-color: $navigationColorCardActive;
      }
    }

    &.notification {
      background-color: $notificationColorCard;

      &:hover {
        background-color: $notificationColorCardHover;
      }

      &:active {
        background-color: $notificationColorCardActive;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColor;
      color: $buttonTextColorAlternate;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHover;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActive;
      }
    }
  }
}

:global(.theme_dark) {
  .link {
    color: $navigationColorCardDark;

    &:hover {
      color: $navigationColorCardHoverDark;
    }

    &:active {
      color: $navigationColorCardActiveDark;
    }

    &.alert {
      color: $alertColorCardDark;

      &:hover {
        color: $alertColorCardHoverDark;
      }

      &:active {
        color: $alertColorCardActiveDark;
      }
    }

    &.creation {
      color: $creationColorCardDark;

      &:hover {
        color: $creationColorCardHoverDark;
      }

      &:active {
        color: $creationColorCardActiveDark;
      }
    }

    &.notification {
      color: $notificationColorCardDark;

      &:hover {
        color: $notificationColorCardHoverDark;
      }

      &:active {
        color: $notificationColorCardActiveDark;
      }
    }
  }

  .button {
    background-color: $buttonBackgroundColorDark;
    color: $buttonTextColorAlternate;

    &:hover {
      background-color: $buttonBackgroundColorHoverDark;
    }

    &:active {
      background-color: $buttonBackgroundColorActiveDark;
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabledDark;
    }

    &.alert {
      background-color: $alertColorCardDark;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $alertColorCardHoverDark;
      }

      &:active {
        background-color: $alertColorCardActiveDark;
      }
    }

    &.creation {
      background-color: $creationColorCardDark;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $creationColorCardHoverDark;
      }

      &:active {
        background-color: $creationColorCardActiveDark;
      }
    }

    &.navigation {
      background-color: $navigationColorCardDark;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $navigationColorCardHoverDark;
      }

      &:active {
        background-color: $navigationColorCardActiveDark;
      }
    }

    &.notification {
      background-color: $notificationColorCardDark;
      color: $buttonTextColorDark;

      &:hover {
        background-color: $notificationColorCardHoverDark;
      }

      &:active {
        background-color: $notificationColorCardActiveDark;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColorDark;
      color: $buttonTextColor;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHoverDark;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActiveDark;
      }
    }
  }
}
