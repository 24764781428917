@use 'sass:color';
@import 'OK/styles/util';

.actionButton {
  border-radius: 2px;
  font-size: $fontSizeSm;
  font-weight: bold;
  line-height: $lineHeightXs;
  padding: 0 $contentMarginSm;
  margin-right: $contentMarginSm;
  margin-bottom: 0;
  margin-top: 10px;
}

.avatarImage {
  border-width: 2px;
  border-style: solid;
  border-radius: 59px;
  border-color: $contentBackgroundColor;
  height: 118px;
  width: 118px;

  &:hover {
    border-color: $navigationColor !important;
    border-width: 1px;
  }
}

@include forDarkTheme() {
  .avatarImage {
    border-color: $contentBackgroundColorDark;
  }
}
.selectedAvatarImage {
  border-width: 2px;
  border-style: solid;
  border-radius: 59px;
  border-color: $contentBackgroundColorDark;
  height: 118px;
  width: 118px;
}
@include forDarkTheme() {
  .selectedAvatarImage {
    border-color: $contentBackgroundColor;
  }
}

.bottomText {
  font-size: 0.75rem;
}

.buttonCancel {
  margin-bottom: 0;
}

.buttonSave {
  margin-left: $contentMarginMd;
  margin-bottom: 0;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
}
.container {
  margin: auto;
}

.header {
  align-items: center;
  display: flex;
  margin: 0 0 0 0;
  justify-content: space-between;
  height: auto;
}

.carousel {
  padding-left: $contentMarginLg;
  padding-right: $contentMarginLg;
}

.carouselContainer {
  margin: $contentMarginMd (-$contentMarginLg) !important;

  :global(.os-scrollbar-horizontal) {
    padding-left: $contentMarginLg !important;
    padding-right: $contentMarginLg !important;
  }

  @include forMinScreenWidth(medium) {
    margin: $contentMarginMd (-$contentMarginMd) !important;

    :global(.os-scrollbar-horizontal) {
      padding-left: $contentMarginXl !important;
      padding-right: $contentMarginXl !important;
    }
  }
}

.linkCard {
  width: $cardWidthMobile;

  @include forMinScreenWidth(medium) {
    width: $cardWidth4ColumnDesktop;
  }
}

.linkSlide {
  margin: 0 $contentMarginSm;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.textAvatar {
  margin-bottom: 10px;
}
