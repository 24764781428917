@import 'OK/styles/util';

.dismissBannerButton {
  @include increaseClickableArea(11px, 11px, absolute);
  color: inherit;
  height: 22px;
  padding: 0;
  right: 11px;
  top: 11px;
  width: 22px;
}

.dismissBannerButtonIcon {
  position: relative;
  right: -11px;
  top: -12px;
}

.notificationsContainer {
  align-items: flex-end;
  bottom: var(--tabBarHeight);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  padding: $contentMarginMd;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: bottom $timingShort;
  z-index: 3;

  @include forMinScreenWidth(medium) {
    bottom: 0;
  }
}

.statusBanner {
  bottom: -$statusBannerHeight;
  height: $statusBannerHeight;
  left: 0;
  padding: $contentMarginSm $contentMarginMd;
  position: fixed;
  text-align: center;
  transition: background-color $timingShort, bottom $timingShort;
  width: 100%;
  z-index: 1;

  .message {
    margin: 0;
  }
}

.withStatusBanner {
  --tabBarHeight: calc(#{$mainMenuTabsHeightPlusBottomSafeArea} + $statusBannerHeight);
}

@include forLightTheme() {
  .statusBanner {
    background-color: $contentBackgroundColorFocus;
    color: $textColor;

    &.alert,
    &.creation,
    &.navigation,
    &.notification {
      color: $textColorLight;
    }

    &.alert {
      background-color: $alertColor;
    }

    &.creation {
      background-color: $creationColor;
    }

    &.navigation {
      background-color: $navigationColor;
    }

    &.notification {
      background-color: $notificationColor;
    }
  }
}

@include forDarkTheme() {
  .statusBanner {
    background-color: $contentBackgroundColorFocusDark;
    color: $textColorLight;

    &.alert,
    &.creation,
    &.navigation,
    &.notification {
      color: $textColorLight;
    }

    &.alert {
      background-color: $alertColor;
    }

    &.creation {
      background-color: $creationColor;
    }

    &.navigation {
      background-color: $navigationColor;
    }

    &.notification {
      background-color: $notificationColor;
    }
  }
}
