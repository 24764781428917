@import 'OK/styles/util';

.acc .addChartFilterButton {
  display: block;
}

.beginWorkButton {
  margin-top: 0;

  @include forMinScreenWidth(medium) {
    width: 400px;
  }
}

.chartContainer {
  height: 250px;
  margin-bottom: $contentMarginLg * 2;
}

.chartFilter {
  align-items: center;
  border-radius: 15px;
  border: 1px solid $midtonesColorStatic;
  display: inline-flex;
  height: 30px;
  justify-content: space-between;
  line-height: 30px;
  margin: 0 $contentMarginMd $contentMarginSm 0;
  padding: $contentMarginXs $contentMarginSm;
}

.chartFilterName {
  margin: 0;
}

.chartFilterRemoveButton {
  line-height: 0;
  margin-left: $contentMarginMd;
}

.accordion,
.container {
  padding-top: $contentMarginLg;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 5px;
}

.accordion {
  padding-bottom: $contentMarginLg;
}

.filterChartSection {
  display: block;
}

.headerAccordion {
  margin-bottom: 0 !important;
  padding: 0 $contentMarginLg;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  @include forMinScreenWidth(medium) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

.headerAccordionInline {
  margin-bottom: 0 !important;
  padding: 0 $contentMarginLg;
}

.icon {
  margin-bottom: 5px;
}

@include forLightTheme {
  .accordion,
  .container {
    background-color: $contentBackgroundColor;
  }
}
@include forDarkTheme {
  .accordion,
  .container {
    background-color: $contentBackgroundColorDark;
  }
}

.newFilterContainer {
  @include forMinScreenWidth(medium) {
    max-width: 400px;
  }
}

.newFilterSearchContainer {
  position: relative;
  z-index: 1;
}

.searchInput {
  z-index: 1;
}

.sectionDescription {
  max-width: 400px;
}

.searchSuggestions {
  position: absolute;
  top: $inputHeight - $contentMarginXs;
  left: 0;
  width: 100%;
}

.listDisplayTypeButton {
  margin-bottom: $contentMarginMd;
}

.loadingMoreIcon {
  height: 60px;
  width: 60px;
}

.loadingMoreSlide {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.log {
  margin: 0;
  width: 100%;
}

.logCardInner {
  width: 100%;
}

.logSlide {
  margin: 0 $contentMarginSm;
  width: 100%;
  flex: 0 0 100%;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    > * {
      margin-right: $contentMarginSm;
    }
  }

  @include forMinScreenWidth(medium) {
    width: auto;
    flex: 0 0 auto;

    &:first-child {
      margin-left: $contentMarginSm;
    }

    &:last-child {
      > * {
        margin-right: $contentMarginSm;
      }
    }
  }
}

.logsCarousel {
  margin: 0 (-$contentMarginLg) !important;

  @include forMinScreenWidth(medium) {
    margin: 0 (-$contentMarginXxl) !important;
  }
}

.carousel {
  padding: 0 $contentMarginSm;
  :global(.os-scrollbar-horizontal) {
    padding: 0 $contentMarginXxl !important;
  }
  @include forMinScreenWidth(medium) {
    :global(.os-scrollbar-horizontal) {
      padding: 0 $contentMarginXl !important;
    }
  }
}

.header {
  margin-left: 0;
  font-size: 24px;
  font-weight: bold;
  @include forMinScreenWidth(medium) {
  }
}

.sectionTitle {
  margin-bottom: $contentMarginLg;
}

.selectedDateRange {
  margin-right: auto;

  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginLg;
  }
}

.selectedDateRangeContainer {
  align-items: center;
  display: flex;

  > * {
    margin-bottom: 0;
  }
}

.toggleButton {
  margin-bottom: $contentMarginSm !important;

  @include forMinScreenWidth(medium) {
    margin-bottom: 0 !important;
  }
}

.swipeSidewaysMessage {
  margin-top: 6px;
  margin-bottom: 0;
}
