@import 'OK/styles/util';

.breakdownColumn {
  &:first-child {
    margin-bottom: $contentMarginLg;
  }

  @include forMinScreenWidth(medium) {
    flex: 1 1 50%;

    &:first-child {
      margin-bottom: 0;
      margin-right: $contentMarginLg * 2;
    }
  }
}

.breakdownContent {
  display: flex;
  flex-direction: column;

  @include forMinScreenWidth(medium) {
    flex-direction: row;
  }
}

.breakdownLotResults {
  margin-bottom: $contentMarginMd;
}

.breakdownMenuContainer {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: $contentMarginLg;
}

.breakdownSampleResults {
  margin-bottom: $contentMarginLg;
}

.breakdownSection {
  padding-bottom: $contentMarginXxl;
  padding-top: $contentMarginLg;
}

.breakdownTab {
  font-weight: normal !important;
}

.breakdownTabs {
  margin: 0 -6px;
}

.section {
  padding-bottom: $contentMarginXxl;
  padding-top: $contentMarginXl;
}

.separator {
  margin: $contentMarginLg 0;
}

.testsCarousel {
  padding-bottom: $contentMarginMd;
}

.testsCarouselContainer {
  margin-left: -$contentMarginLg;
  margin-right: -$contentMarginLg;

  @include overlayScrollbarsDefaultScrollbarPadding();

  @include forMinScreenWidth(medium) {
    margin-left: 0;
    margin-right: 0;
  }
}

.testResultCard,
.testResultCardInner {
  height: 100%;
}

.testSlide {
  margin-left: $contentMarginSm;
  margin-right: $contentMarginSm;

  &:first-child {
    margin-left: $contentMarginLg;
  }

  &:last-child {
    > * {
      margin-right: $contentMarginLg;
    }
  }

  @include forMinScreenWidth(medium) {
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
      > * {
        margin-right: 0;
      }
    }
  }
}

@include forLightTheme() {
  .breakdownMenuContainer {
    border-bottom-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .breakdownMenuContainer {
    border-bottom-color: $midtonesColorStaticDark;
  }
}
