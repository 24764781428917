@import 'OK/styles/util';

.column1 {
  flex: 1 1 100%;

  @include forMinScreenWidth(medium) {
    flex: 1 1 33%;
  }
}

.column2 {
  align-items: flex-start;
  display: flex;
  flex: 1 1 45%;
  flex-direction: column;

  @include forMinScreenWidth(medium) {
    flex: 1 1 1%;
  }
}

.column3 {
  align-items: flex-start;
  display: flex;
  flex: 1 1 55%;
  flex-direction: column;

  @include forMinScreenWidth(medium) {
    flex: 1 1 33%;
  }

  p {
    font-size: $fontSizeSm;
  }
}

.container {
  font-size: $fontSizeSm;
  line-height: $lineHeightSm;
  padding-bottom: $mainMenuTabsHeight;

  @include forMinScreenWidth(medium) {
    padding-bottom: 0;
  }
}

.contentContainer {
  padding: $contentMarginXl $contentMarginXxl $contentMarginHuge !important;

  a,
  h6 {
    margin-bottom: $contentMarginSm;
  }

  h6:not(:first-child) {
    margin-top: $contentMarginSm;
  }
}

.copyright {
  margin-top: $contentMarginMd;

  p {
    margin: 0;
  }
}

.legal {
  font-size: $fontSizeXs;
  line-height: $lineHeightXs;
  margin-bottom: $contentMarginMd;
  margin-top: $contentMarginMd;
  max-width: 575px;

  a {
    margin: 0;
  }

  .list {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;

      &:not(:last-child) {
        margin-bottom: $contentMarginSm;
      }

      > :last-child {
        margin-bottom: 0;
      }
    }

    sup {
      margin-right: $contentMarginMd;
      position: relative;
      top: 1px;
    }
  }
}

.link {
  height: auto !important;
  line-height: inherit !important;

  &:last-child {
    margin-bottom: 0;
  }
}

.logo {
  height: 40px;
}

.mainContent {
  margin-bottom: $contentMarginLg;
}

.row {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;

  @include forMinScreenWidth(medium) {
    // flex-direction: row;
  }
}

.serviceStatusIndicator {
  background-color: $creationColor;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-left: $contentMarginSm;
  width: 10px;
}

.socialIcons {
  margin: 0 (-$contentMarginXs) $contentMarginMd;

  .link {
    display: inline-block;
    height: 44px;
    margin: 0 $contentMarginXs;
    padding: 2px;
  }

  img {
    height: 40px;
  }

  @include forMinScreenWidth(medium) {
    margin-bottom: 0;
  }
}

// Light theme

@include forLightTheme() {
  .column2,
  .column3 {
    a,
    a:hover,
    a:active,
    .link,
    .link:hover,
    .link:active {
      color: $textColor;
    }
  }

  .container {
    background-color: $midtonesColorStatic;
  }
}

// Dark theme

@include forDarkTheme() {
  .column2,
  .column3 {
    a,
    a:hover,
    a:active,
    .link,
    .link:hover,
    .link:active {
      color: $textColorLight;
    }
  }

  .container {
    background-color: $midtonesColorStaticDark;
  }
}
