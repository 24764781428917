@import 'OK/styles/util';

.card {
  margin-bottom: -$contentMarginXxl;
  min-width: $cardWidthMobile;
}

.companyCountry {
  margin-left: $contentMarginXs;
}

.companyLogo {
  background-color: #ddd;
  border-radius: $cardBorderRadius;
  float: right;
  height: 50px;
  width: 50px;
}

.companyName {
  margin-bottom: $contentMarginSm;
}

.invite {
  border-top: 1px solid $midtonesColorStatic;
  padding: $contentMarginSm 0 $contentMarginMd;

  &:last-of-type {
    border-bottom: 1px solid $midtonesColorStatic;
    margin-bottom: $contentMarginMd;
  }
}

.respondButton {
  margin-bottom: 0;

  &:first-of-type {
    margin-right: $contentMarginLg;
  }
}

.spinner {
  margin-bottom: $contentMarginMd;
}
