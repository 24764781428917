@import 'OK/styles/util';

:global(.theme_light) {
  // Button style
  .button {
    background-color: $buttonBackgroundColor;
    color: $buttonTextColor;

    &:hover {
      background-color: $buttonBackgroundColorHover;
    }

    &:active {
      background-color: $buttonBackgroundColorActive;
    }

    &.alert {
      background-color: $alertColor;

      &:hover {
        background-color: $alertColorHover;
      }

      &:active {
        background-color: $alertColorActive;
      }
    }

    &.creation {
      background-color: $creationColor;

      &:hover {
        background-color: $creationColorHover;
      }

      &:active {
        background-color: $creationColorActive;
      }
    }

    &.navigation {
      background-color: $navigationColor;

      &:hover {
        background-color: $navigationColorHover;
      }

      &:active {
        background-color: $navigationColorActive;
      }
    }

    &.notification {
      background-color: $notificationColor;

      &:hover {
        background-color: $notificationColorHover;
      }

      &:active {
        background-color: $notificationColorActive;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColor;
      border: 1px solid $buttonSecondaryBackgroundColor;
      color: $buttonTextColorAlternate;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHover;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActive;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabled !important;
      color: $buttonTextColorAlternate !important;
    }
  }

  // Link style
  .link {
    color: $navigationColor;

    &:hover {
      color: $navigationColorHover;
    }

    &:active {
      color: $navigationColorActive;
    }

    &.alert {
      color: $alertColor;

      &:hover {
        color: $alertColorHover;
      }

      &:active {
        color: $alertColorActive;
      }
    }

    &.creation {
      color: $creationColor;

      &:hover {
        color: $creationColorHover;
      }

      &:active {
        color: $creationColorActive;
      }
    }

    &.notification {
      color: $notificationColor;

      &:hover {
        color: $notificationColorHover;
      }

      &:active {
        color: $notificationColorActive;
      }
    }

    &.secondary {
      color: $brandColor;
    }
  }

  // Toggle
  .toggle {
    background-color: $contentBackgroundColor;
    border-color: $brandColor;

    .toggleActiveLayer {
      background-color: $brandColor;
    }
  }

  // Inverted style
  .button.inverted {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
    color: $buttonTextColorAlternate;

    &:hover {
      background-color: $contentBackgroundColorCard;
    }

    &:active {
      background-color: $contentBackgroundColorFocus;
    }

    // Tints

    &.alert {
      color: $alertColor;

      &:hover {
        color: $alertColorCardHover;
      }

      &:active {
        color: $alertColorCardActive;
      }
    }

    &.creation {
      color: $creationColor;

      &:hover {
        color: $creationColorCardHover;
      }

      &:active {
        color: $creationColorCardActive;
      }
    }

    &.navigation {
      color: $navigationColor;

      &:hover {
        color: $navigationColorCardHover;
      }

      &:active {
        color: $navigationColorCardActive;
      }
    }

    &.notification {
      color: $notificationColor;

      &:hover {
        color: $notificationColorCardHover;
      }

      &:active {
        color: $notificationColorCardActive;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabled !important;
      color: $buttonTextColorAlternate !important;
    }
  }
}

:global(.theme_dark) {
  // Button style
  .button {
    background-color: $buttonBackgroundColorDark;
    color: $buttonTextColorAlternate;

    &:hover {
      background-color: $buttonBackgroundColorHoverDark;
    }

    &:active {
      background-color: $buttonBackgroundColorActiveDark;
    }

    &.alert {
      background-color: $alertColor;
      color: $buttonTextColor;

      &:hover {
        background-color: $alertColorHoverDark;
      }

      &:active {
        background-color: $alertColorActiveDark;
      }
    }

    &.creation {
      background-color: $creationColor;
      color: $buttonTextColor;

      &:hover {
        background-color: $creationColorHoverDark;
      }

      &:active {
        background-color: $creationColorActiveDark;
      }
    }

    &.navigation {
      background-color: $navigationColor;
      color: $buttonTextColor;

      &:hover {
        background-color: $navigationColorHoverDark;
      }

      &:active {
        background-color: $navigationColorActiveDark;
      }
    }

    &.notification {
      background-color: $notificationColor;
      color: $buttonTextColor;

      &:hover {
        background-color: $notificationColorHoverDark;
      }

      &:active {
        background-color: $notificationColorActiveDark;
      }
    }

    &.secondary {
      background-color: $buttonSecondaryBackgroundColorDark;
      border: 1px solid $buttonSecondaryBackgroundColorDark;
      color: $buttonTextColor;

      &:hover {
        background-color: $buttonSecondaryBackgroundColorHoverDark;
      }

      &:active {
        background-color: $buttonSecondaryBackgroundColorActiveDark;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabledDark !important;
      color: $buttonTextColor !important;
    }
  }

  // Link style
  .link {
    color: $navigationColor;

    &:hover {
      color: $navigationColorHoverDark;
    }

    &:active {
      color: $navigationColorActiveDark;
    }

    &.alert {
      color: $alertColor;

      &:hover {
        color: $alertColorHoverDark;
      }

      &:active {
        color: $alertColorActiveDark;
      }
    }

    &.creation {
      color: $creationColor;

      &:hover {
        color: $creationColorHoverDark;
      }

      &:active {
        color: $creationColorActiveDark;
      }
    }

    &.notification {
      color: $notificationColor;

      &:hover {
        color: $notificationColorHoverDark;
      }

      &:active {
        color: $notificationColorActiveDark;
      }
    }

    &.secondary {
      color: $brandColorAlternate;
    }
  }

  // Toggle
  .toggle {
    background-color: $contentBackgroundColorDark;
    border-color: $brandColorAlternate;

    .toggleActiveLayer {
      background-color: $brandColorAlternate;
    }
  }

  // Inverted style
  .button.inverted {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
    color: $buttonTextColor;

    &:hover {
      background-color: $contentBackgroundColorCardDark;
    }

    &:active {
      background-color: $contentBackgroundColorFocusDark;
    }

    // Tints

    &.alert {
      color: $alertColor;

      &:hover {
        color: $alertColorCardHoverDark;
      }

      &:active {
        color: $alertColorCardActiveDark;
      }
    }

    &.creation {
      color: $creationColor;

      &:hover {
        color: $creationColorCardHoverDark;
      }

      &:active {
        color: $creationColorCardActiveDark;
      }
    }

    &.navigation {
      color: $navigationColor;

      &:hover {
        color: $navigationColorCardHoverDark;
      }

      &:active {
        color: $navigationColorCardActiveDark;
      }
    }

    &.notification {
      color: $notificationColor;

      &:hover {
        color: $notificationColorCardHoverDark;
      }

      &:active {
        color: $notificationColorCardActiveDark;
      }
    }

    &:disabled {
      background-color: $buttonBackgroundColorDisabledDark !important;
      color: $buttonTextColor !important;
    }
  }
}
