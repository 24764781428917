@import 'OK/styles/util';

.actionButton {
  margin-top: $contentMarginMd;
}

.actionButtonUpper {
  margin-top: -5px;
}

.actionCard {
  margin-top: $contentMarginLg;
}

.card {
  background: none !important;
  vertical-align: top;
}

.header {
  display: flex;
  justify-content: space-between;
}

.membershipsMessage {
  margin-top: $contentMarginSm;
}

.kristofer {
  float: right;
  margin-left: $contentMarginMd;
  border-radius: 50%;
}

.kristoferContainer {
  p {
    margin: 0;
  }
}

.welcomeMessage {
  margin-bottom: $contentMarginMd;
}

.searchContainer {
  position: relative;
}

.searchInput {
}

.searchResults {
  position: absolute;
  z-index: 10;
  width: 100%;
}

.signature {
  margin-bottom: $contentMarginXs;
}
