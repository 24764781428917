@import 'OK/styles/util';

.content {
  width: 335px;
}

.loadingSpinner {
  display: block;
  margin: 0 auto;
}

.menu {
  display: flex;
}

.profilePhotoContainer {
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  height: 66px;
  overflow: hidden;
  position: relative;
  width: 66px;
}

.QRCode {
  margin-bottom: $contentMarginLg;
  width: 100%;
}

.scanInstructions {
  text-align: center;
}

.shareButton {
  margin-bottom: 0;
}

.shareButtonContainer {
  margin: 0 auto 0 $contentMarginMd;
  position: relative;
}

.shareToast {
  left: -62px;
  position: absolute;
  text-align: center;
  width: 185px;
  z-index: 1;
}

.title {
  margin-bottom: $contentMarginXs;
}

.titleSection {
  display: flex;
  justify-content: space-between;
}

@include forLightTheme() {
  .profilePhotoContainer {
    border-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .profilePhotoContainer {
    border-color: $midtonesColorStaticDark;
  }
}
