// doiuse-disable calc

@use 'sass:color';
@import 'OK/styles/util';

.avatarButton {
  width: 100%;
  margin-bottom: $contentMarginSm;
  @include forMinScreenWidth(medium) {
    width: 247px;
  }
}

.avatarButtonContainer {
  display: grid;
  padding-left: $contentMarginMd;
  padding-right: $contentMarginMd;

  :last-child {
    margin-bottom: 0;
  }
}

.cardButton {
  @include forMinScreenWidth(medium) {
    width: 247px;
  }
}

.contactCard {
  position: relative;
}

.contactSectionInner {
  margin-top: -130px;
  padding-bottom: $contentMarginXxl;
}

.contactSectionInner,
.settingsSectionInner {
  @include forMinScreenWidth(medium) {
    display: flex;
    padding-bottom: $contentMarginXxl;
  }
}

.copyButton {
  border-radius: 2px;
  font-size: $fontSizeXs;
  font-weight: bold;
  line-height: $lineHeightXs;
  padding: 0 $contentMarginSm;
}

.documentationSection {
  padding-top: 0;
  padding: $contentMarginXl 0;

  @include forLightTheme() {
    background-color: $contentBackgroundColor;
  }

  @include forDarkTheme() {
    background-color: $contentBackgroundColorDark;
  }
}

.edit {
  border: 1px solid $navigationColor !important;
}

.fineprint {
  @include forMinScreenWidth(medium) {
    width: 400px;
  }
}

.header {
  margin-left: 0;
  font-size: 24px;
  font-weight: bold;
}

.headerAccordion {
  margin-bottom: 0 !important;
  padding: 0 $contentMarginLg;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  @include forMinScreenWidth(medium) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}
.icon {
  margin-bottom: 5px;
}

.label {
  margin-bottom: $contentMarginSm;
}

.membershipsError {
  margin: -25px 0 25px 0;
}

.accordion,
.membershipsSection {
  padding-top: $contentMarginLg;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 5px;
}

.accordion {
  padding-bottom: $contentMarginLg;
}

.nameInput {
  margin-bottom: $contentMarginMd;

  @include forMinScreenWidth(medium) {
    width: 247px;
  }
}

.okidInput {
  margin-bottom: $contentMarginSm;
  border-radius: $inputBorderRadius;

  @include forMinScreenWidth(medium) {
    width: 247px;
  }
}

.okidInput,
.staticInput {
  border-radius: $inputBorderRadius;
  height: $inputHeight;
  line-height: $inputHeight;
  padding: 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.organisationCard {
  height: 100%;
  width: 100%;
}

.organisationCarousel {
  padding: 0 $contentMarginSm;
}

.organisationCarouselContainer {
  margin: 0 (-$contentMarginLg) !important;

  @include forMinScreenWidth(medium) {
    margin: 0 (-$contentMarginXxl) !important;
  }
}

.organisationSearchInput {
  margin-bottom: $contentMarginLg;

  @include forMinScreenWidth(medium) {
    width: 400px;
  }
}

.organisationSearchModes {
  margin-bottom: $contentMarginMd;
}

.organisationSlide {
  margin: 0 $contentMarginSm;
  width: 100%;
  flex: 0 0 100%;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    > * {
      margin-right: $contentMarginSm;
    }
  }

  @include forMinScreenWidth(medium) {
    width: auto;
    flex: 0 0 $cardWidth4ColumnDesktop;

    &:first-child {
      margin-left: $contentMarginSm;
    }

    &:last-child {
      > * {
        margin-right: $contentMarginSm;
      }
    }
  }
}

.profilePhoto {
  border-radius: 194px;
  border-style: solid;
  border-width: 1px;
  height: 0;
  margin-bottom: $contentMarginMd;
  padding-top: calc(100% - 2px);
  position: relative;
  width: 100%;

  @include forMinScreenWidth(medium) {
    padding-top: $cardWidth3ColumnDesktop - 2px;
    width: $cardWidth3ColumnDesktop;
  }
}

.profileImage {
  border-radius: 194px;
}

.profilePhotoContainer {
  margin-bottom: $contentMarginLg;

  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginMd;
  }
}

.spacer {
  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginMd;
    width: $cardWidth3ColumnDesktop;
  }
}

.removeButton {
  margin-right: auto;
}

.result {
  cursor: pointer;
  margin: 0 $contentMarginMd $contentMarginMd 0;
}

.resultCard {
  border: 2px solid transparent;
  height: 100%;
  overflow: visible;
  width: 100%;

  &.active {
    background-color: white !important;
  }
}

.resultMedia {
  opacity: 0.5;

  &.active {
    opacity: 1;
  }
}

.results {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$contentMarginMd;
}

.sectionDescription {
  @include forMinScreenWidth(medium) {
    max-width: 400px;
  }
}

.contactCard,
.settingsCard {
  border-width: 1px;
  border-style: solid;

  :last-child {
    margin-bottom: 0;
  }

  @include forMinScreenWidth(medium) {
    flex: 1 1;
  }
}

.settingsHeaderButton {
  margin: 0 0 0 $contentMarginMd;
}

.settingsHeaderLabel {
  margin: 0 auto 0 0;
}

.settingsHeaderRow {
  align-items: center;
  display: flex;
  height: $buttonHeight;
}

.settingsSectionInner {
  padding-bottom: 150px;
}

@include forLightTheme() {
  .accordion,
  .membershipsSection,
  .settingsSection {
    background-color: $contentBackgroundColor;
  }

  .okidInput,
  .staticInput {
    background-color: $midtonesColorStatic;
  }

  .profilePhoto {
    border-color: $midtonesColorStatic;
  }

  .resultCard {
    background-color: color.change($contentBackgroundColorCard, $alpha: 0.5) !important;

    &.active {
      background-color: $contentBackgroundColor !important;
      border-color: $navigationColorCard;
    }
  }

  .contactCard,
  .settingsCard {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .accordion,
  .membershipsSection,
  .settingsSection {
    background-color: $contentBackgroundColorDark;
  }

  .okidInput,
  .staticInput {
    background-color: $midtonesColorStaticDark;
  }

  .profilePhoto {
    border-color: $midtonesColorStaticDark;
  }

  .resultCard {
    background-color: color.change($contentBackgroundColorCardDark, $alpha: 0.5) !important;

    &.active {
      background-color: $contentBackgroundColorDark !important;
      border-color: $navigationColorCardDark;
    }
  }

  .contactCard,
  .settingsCard {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
  }
}

.toggleButton {
  margin-bottom: $contentMarginSm !important;

  @include forMinScreenWidth(medium) {
    margin-bottom: 0 !important;
  }
}

.swipeSidewaysMessage {
  margin-top: 6px;
  margin-bottom: $contentMarginLg;
}
