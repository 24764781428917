@import 'OK/styles/util';

.grade {
  margin: 0 $contentMarginMd 0 0 !important;
}

.organisationName {
  margin-bottom: $contentMarginXs;
}

.organisationSummaryLink {
  display: block;
  margin: 0;
}

.organisationTag {
  margin-left: $contentMarginXs;
}
