@import 'OK/styles/util';

.alert {
  color: $alertColor;
}

.creation {
  color: $creationColor;
}

.navigation {
  color: $navigationColor;
}

.notification {
  color: $notificationColor;
}
