@import 'OK/styles/util';

.selectLabel {
  margin: 0;
  overflow: hidden;
}

.selectLabelIcon {
  margin-right: $contentMarginXs;
}
