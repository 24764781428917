@import 'OK/styles/util';

.backButton {

  @include increaseClickableArea(10px);
  font-weight: normal;
  height: 24px;
  margin: 0 auto 0 0;
}

.closeButton {

  @include increaseClickableArea(10px);
  height: 24px;
  margin: 0 0 0 $contentMarginMd;
  padding: 0;
  position: relative;
  top: -2px;
  width: 24px;

  img {
    height: 16px;
    width: 16px;
  }
}

.container {
  align-items: center;
  border-top-left-radius: $cardBorderRadius;
  border-top-right-radius: $cardBorderRadius;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding: 15px $contentMarginMd;
  position: fixed;
  top: 0;
  width: 100%;

  p {
    margin: 0;
  }
}

@include forLightTheme() {
  .container {
    background-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .container {
    background-color: $midtonesColorStaticDark;
  }
}
