@import 'OK/styles/util';

.alert {
  border-radius: $cardBorderRadius;
  padding: $contentMarginMd $contentMarginMd $contentMarginLg;
  width: 275px;
}

.container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: $contentMarginXxl;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 300;

  :last-child {
    margin-bottom: 0;
  }
}

.title {
  margin-bottom: $contentMarginSm;
}

@include forLightTheme() {
  .alert {
    background-color: $contentBackgroundColor;
  }

  .container {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@include forDarkTheme() {
  .alert {
    background-color: $contentBackgroundColorDark;
  }

  .container {
    background-color: rgba(255, 255, 255, 0.5);
  }
}
