@import 'OK/styles/util';

button.container {
  align-items: center;
  display: flex !important;
  height: $minClickableHeight;
  justify-content: space-between;
  margin: 0 $contentMarginMd;
  padding: 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid $midtonesColorStatic;
  }

  p {
    margin: 0;
  }

  // Tints

  &.alert {
    color: $alertColor;
  }

  &.navigation {
    color: $navigationColor;
  }
}
