@import 'OK/styles/util';

.card {
  cursor: pointer;
  margin-bottom: $contentMarginMd;

  &:last-child {
    margin-bottom: 0;
  }
}

.container {
  padding-left: $contentMarginSm;
  padding-right: $contentMarginSm;
  width: 335px;
}

.description {
  margin-bottom: $contentMarginLg;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;

  > * {
    margin: 0;
  }
}
