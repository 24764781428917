@import 'OK/styles/util';

.buttonContainer {
  pointer-events: none;
}
.closeButton {
  margin: 0 0 0 auto;
}

.closeButtonBottom {
  pointer-events: all;
}

.container {
  width: 335px;
}

.containerContent {
  height: 275px;
}

.errorMessage {
  margin: -15px 0 15px 0;
}

.fieldName {
  align-items: center;
  display: flex;
  margin-bottom: $contentMarginSm;
  justify-content: space-between;

  > * {
    height: $lineHeightMd;
    margin-bottom: 0;
  }
}

.fieldValue {
  margin-bottom: $contentMarginMd;
}

.fileName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header {
  align-items: center;
  display: flex;
  margin-bottom: $contentMarginMd;
}

.loadingIcon {
  display: block;
  margin: 0 auto;
}

.madeByOrganisationCard {
  margin-left: -$contentMarginMd;
}

.marketLabel {
  z-index: 10;
}

.normalFontWeight {
  font-weight: normal;
}

.refId {
  margin-left: 15px;
  position: relative;
  top: 1px;
}

.searchContainer {
  position: relative;
  z-index: 1;
}

.searchInput {
  z-index: 1;
}

.searchSuggestions {
  position: absolute;
  top: $inputHeight - $contentMarginXs;
  left: 0;
  right: 0;
  max-height: 215px;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.tag {
  margin-bottom: $contentMarginSm;
  margin-right: $contentMarginSm;
}

.tags {
  margin-bottom: $contentMarginSm;
}

.title {
  margin: 0;
}
