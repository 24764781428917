@import 'OK/styles/util';

$textMaxWidthDesktop: 400px;
$buttonMaxWidth: 185px;

.copyTooltip {
  left: 8px;
}

.description {
  max-width: $textMaxWidthDesktop;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;

  > * {
    margin-bottom: 0;
  }
}

.invalidateMessage {
  margin-bottom: $contentMarginSm;
}

.linkActionButton {
  border-radius: 15px;
  font-size: $fontSizeXs;
  height: 30px;
  line-height: 30px;
  margin-right: $contentMarginSm;
  padding: 0 15px;
  border: 1px solid $navigationColor !important;
  color: $navigationColor !important;

  @include increaseClickableArea(7px, 0px);
}

.linkActionContainer {
  margin-top: $contentMarginMd;
}

.linkActionTooltip {
  padding: 0 6px;
  position: absolute;
  top: -13px;
  left: 90px !important;
  z-index: 1;
}

.linkSelect {
  max-width: $buttonMaxWidth;
}

.popup {
  max-width: 315px;
}

.publishOptionCard {
  margin-bottom: $contentMarginMd !important;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: $contentMarginSm;
  }
}

.searchContainer {
  margin-bottom: $contentMarginMd;
  max-width: $textMaxWidthDesktop;
  position: relative;
  z-index: 1;
}

.searchInput {
  z-index: 1;
}

.searchSuggestionImage {
  height: 100%;
  width: 100%;
}

.searchSuggestions {
  position: absolute;
  top: $inputHeight - $contentMarginXs;
  left: 0;
  right: 0;
}

.sectionContainer {
  max-width: 275px;
}

.selectedLink {
  border-radius: $inputBorderRadius;
  height: $inputHeight;
  line-height: $inputHeight;
  overflow: hidden;
  margin-bottom: $contentMarginSm;
  padding: 0 $inputPadding;
  white-space: nowrap;
}

.searchModeButtons {
  display: block;
}

.shareContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sharedAccessCard {
  &:not(:last-child) {
    margin-bottom: $contentMarginMd;
  }
}

.sharedFinePrint {
  margin: 0;
  max-width: $textMaxWidthDesktop;
}

@include forLightTheme() {
  .selectedLink {
    background-color: $midtonesColorStatic;
  }
}
