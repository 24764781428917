@import 'OK/styles/util';

.actionButton {
  margin-top: $contentMarginMd;
  margin-bottom: $contentMarginMd;
}

.container {
  padding: $contentMarginLg $contentMarginLg $contentMarginLg;
}

.email {
  margin-bottom: $contentMarginSm;
}

.fineprint {
  margin: $contentMarginMd 0 0 0;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;

  > * {
    margin-bottom: 0;
  }
}

.inlineLink {
  display: inline;
  margin: 0;
}

.registerAgainButton {
  margin: 0;
}

.registerContainer {
  padding: $contentMarginLg $contentMarginLg $contentMarginXl;
}

.welcomeMessage {
  margin-bottom: $contentMarginSm;
}

@include forLightTheme() {
  .container {
    background-color: $contentBackgroundColor;
  }

  .registerContainer {
    background-color: $contentBackgroundColorCard;
  }
}

@include forDarkTheme() {
  .container {
    background-color: $contentBackgroundColorDark;
  }

  .registerContainer {
    background-color: $contentBackgroundColorCardDark;
  }
}
