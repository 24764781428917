@import 'OK/styles/util';

.content {
  display: flex;
  flex-direction: column;
}

.tag {
  margin-right: $contentMarginXs;
}

.tagRow {
  margin-bottom: 0;
}

.title {
  height: 61px;
  margin-bottom: auto;
}
