@import 'OK/styles/util';

.chip {
  border-radius: $cardBorderRadius;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  overflow: hidden;
  padding: 4px $contentMarginSm;
  position: relative;
}

.unselectedBorder {
  background-color: $navigationColor;
  bottom: 0;
  display: inline-block;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
}
