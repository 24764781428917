@import 'OK/styles/util';

.testSeverityIcon {
  align-items: center;
  border-radius: 2px;
  display: inline-flex;
  height: 20px;
  justify-content: space-around;
  width: 20px;
}

@include forLightTheme() {
  .testSeverityIcon {
    background-color: $brandColor;
    color: $textColorLight;
  }
}

@include forDarkTheme() {
  .testSeverityIcon {
    background-color: $brandColorAlternate;
    color: $textColor;
  }
}
