@import 'OK/styles/util';

.contentContainer {
  position: absolute;
}

.popup {
  position: relative;
  transition-property: height;
  transition-timing-function: ease;
}

/* Transition classes */

.transitionContainer {
  opacity: 0;

  &.enter {
    opacity: 1;
    transition-property: opacity;
    transition-timing-function: ease-out;
  }

  &.exit {
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: ease-in;
  }
}
