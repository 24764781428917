@import 'OK/styles/util';

.manageButton {
  margin-bottom: $contentMarginSm;

  &:last-child {
    margin-bottom: 0;
  }
}

.myOrganisationTagIcon {
  top: -1px;
}

.ownerOrganisationGrade {
  flex: 0 0 49px !important;
  margin-left: $contentMarginSm;
  width: 49px !important;
}

.ownerOrganisationGradeIcon {
  margin-right: 8px;
}

.ownerOrganisationGrades {
  display: flex;
  justify-content: flex-end;
}

.ownerOrganisationName {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ownerStatsRow {
  display: flex;
  justify-content: space-between;

  @include forMinScreenWidth(medium) {
    display: block;
  }
}

.quantityFlex {
  display: flex;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;

  .quantityLabel {
    margin-bottom: 0;
  }
}

.quantityInput {
  margin-bottom: $contentMarginMd;
}

.quantityLabel {
  margin-bottom: $contentMarginXs;
}

.quantityValue {
  margin-bottom: 0;
}
